<div class="innerHeader Systems">
  <div class="innerHeadings">
    <h1>
      Systems engineering
    </h1>
  </div>
  </div>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/system_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Systems engineering
                </h2>
        </div>
        <p>
          JTSi builds and maintains constantly changing Infrastructure
improvement plans making sure our clients are abreast with latest
technologies

JTSi works key stakeholder to ensure system requirements are
identified, sufficiently defined and managed throughout the system
lifecycle the ensure traceability, testability and successful
implementation on-time and within budget.
        </p>
        </div>
  </div>

  <section class="grayBg commonPadding">
    <div class="containers">
  <div class="topsection reverse Tpadd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Systems requirements analysis include
                </h2>
        </div>

        <ul class="bulletpoints">
          <li>	Market surveys</li>
          <li>	Cost Benefit Analysis</li>
          <li>	Best-Value Alternative Analysis</li>
          <li>	Requirements Traceability Matrix</li>
          <li>	Business process definition (e.g. business workflow)</li>
          <li>	Process descriptions and business rules (e.g. SIPOCS)</li>

      </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/system_02.jpg" alt="">
      </div>
  </div>

  <div class="topsection  Tpadd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/system_03.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  IT Strategy and enterprise architecture
                </h2>
        </div>
<p>
 <strong>JTSi helps customers </strong> to innovate and sustain IT strategies and
architectures:
</p>
        <ul class="bulletpoints">
          <li>	Align business and IT functions</li>
<li>	Eliminate redundancy</li>
<li>	Leverage IT investments and innovative technologies</li>
<li>	Promote vendor neutral solutions based on open standards</li>
<li>	Develop re-usable services resulting in the accelerated delivery of e-government solutions</li>

      </ul>
        </div>

  </div>

  <div class="topsection reverse Tpadd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Infrastructure modernization
                </h2>
        </div>
<p>JTSi Infrastructure modernization services include</p>
        <ul class="bulletpoints">
          <li>	Technology surveys and assessments</li>
          <li>	Performance analysis and Improvement</li>
          <li>	Innovative technology, and process evaluation, integration and implementation</li>

      </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/system_04.jpg" alt="">
      </div>
  </div>

  <div class="topsection  Tpadd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/system_05.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Systems design, integration, and testing
                </h2>
        </div>
<p>
  There is constant need to integrate systems with one another. Our systems integration approach goes hand-in-hand with our operations and maintenance approach.
</p>
        <ul class="bulletpoints">
          <li>	Concept of Operations (CONOPS) and cost modeling</li>
          <li>	Proof of concept and simulation implementations</li>
          <li>	System and Information Assurance (IA) engineering tools and technologies</li>
          <li>	System and network performance measurement and management tools</li>
          <li>	Configuration management</li>
          <li>	Information Technology Infrastructure Library (ITIL®) best-practices</li>
          <li>	System, regression, and security and compliance testing</li>

      </ul>
        </div>

  </div>

  <div class="topsection reverse padd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Systems implementation and training
                </h2>
        </div>
<p>
  Successful system implementation is dependent on planning, testing and training.
</p>
        <ul class="bulletpoints">
          <li>	Implementation plan and schedule</li>
<li>	Contingency plans</li>
<li>	Communication plans</li>
<li>	System installation and configuration manuals</li>
<li>	System administrator training and manual development</li>
<li>	System reference manual (operations guide) development</li>
<li>	Help Desk issue resolution / decision trees</li>

      </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/system_06.jpg" alt="">
      </div>
  </div>


  </div>
  </section>





