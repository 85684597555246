

  <div id="homeSlider" class="carousel slide carousel-fade homeSlides" data-bs-ride="carousel">
    <ol class="carousel-indicators">
      <li data-bs-target="#homeSlider" data-bs-slide-to="0" class="active"></li>
      <li data-bs-target="#homeSlider" data-bs-slide-to="1"></li>
      <li data-bs-target="#homeSlider" data-bs-slide-to="2"></li>
      <li data-bs-target="#homeSlider" data-bs-slide-to="3"></li>
      <li data-bs-target="#homeSlider" data-bs-slide-to="4"></li>
      <li data-bs-target="#homeSlider" data-bs-slide-to="5"></li>

    </ol>
    <div class="carousel-inner">
<!-- <img src="../../assets/img/newyeartitle.gif" class="newyeartitle" alt=""> -->
      <div class="carousel-item active slide_02" >
        <img class="positioned" src="../../assets/img/DOD.png" alt="">
        <div class="carousel-caption">
          <p>
            Johnson Technology Systems, Inc. (DBA: JTSi), wins multi-year Full & Open United States General Services Administration (GSA) - US Army Cloud Migration Contract under GSA-MAS SIN 518210C
          </p>
          <a [routerLink]="['/news']">Learn More</a>
        </div>
      </div>

      <div class="carousel-item slide_05" >
        <div class="carousel-caption">
          <h1>War fighter
            <span> Support Services</span>
          </h1>
          <a [routerLink]="['/war']">Learn More</a>
        </div>
      </div>

      <div class="carousel-item slide_01" >
        <div class="carousel-caption">
          <h1>Professional
            <span>IT Services</span>
          </h1>
          <a [routerLink]="['/capabilities/enterprise-it']">Learn More</a>
        </div>
      </div>

      <div class="carousel-item slide_04" >
        <div class="carousel-caption">
          <h1>SAP Services
            <!-- <span>IT Services</span> -->
          </h1>
          <a  [routerLink]="['/sap']">Learn More</a>
        </div>
      </div>

      <div class="carousel-item slide_03" >
        <div class="carousel-caption">
          <!-- <h1>Professional
            <span>IT Services</span>
          </h1>
          <p>
            Government agencies are challenged to
navigate an ever-changing, complex IT
environment
          </p> -->
          <a  [routerLink]="['/about-us/quick-glance-at-jtsi']">Learn More</a>
        </div>

      </div>

      <div class="carousel-item slide_06" >
        <div class="carousel-caption">
          <h2>AZURE | AWS | GOOGLE |SAP NS2
          </h2>
          <h4>Cloud Hosting & Cloud Migration Services</h4>
          <a [routerLink]="['/sap/cloud-migration-and-csp']">Learn More</a>
        </div>
      </div>

    </div>
    <a class="carousel-control-prev" href="#homeSlider" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </a>
    <a class="carousel-control-next" href="#homeSlider" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </a>
  </div>
<div class="welocme_badge">
 <h2>Welcome</h2>
</div>
    <section class=" commonPadding">
      <div class="containers">
<div class="HomeAbout">
<div class="pictureSide">
  <div class="picture">
    <img src="../../assets/img/about.jpg" alt="">
  </div>
</div>
<div class="textSide">
  <h2>About Us</h2>
  <p>
    Established in 2003, JTSi is an IT & Engineering Services provider with years of
demonstrated experience in the <strong>Information Technology and Engineering services field.</strong>
 JTSi has a proven track record for successfully delivering
mission critical Professional Services to the Public & Private Sector industries.
We deliver quality services at equitable rates and focus on constant
improvement in all areas of our operation, with a strong commitment to
meeting our customer’s needs.
  </p>
    <a class="btn btn-lg btn-primary" [routerLink]="['/corporate-overview']">Learn More &#8594; </a>
</div>
</div>
</div>
    </section>

    <section class="grayBg commonPadding">
      <div class="containers">
<div class="HomeAbout">

<div class="textSide">
  <h2>Information Technology and

    Engineering services </h2>
    <hr>
<p>
  In today’s disruptive economy, Johnson Technology Systems, Inc feels that identifying, retaining, and enhancing skilled personnel with good industry and business process experience is especially challenging. JTSi has forged innovative alliances with Universities and Colleges, various Veteran’s administration organizations, and SAP NS2 to acquire cost-effective talent. We have had great success identifying, training, and hiring promising veterans to serve the DOD customers. Our DOD experiences and engagements include the US Army Shared Services, US Army Logistics Modernization Program (LMP), US DOD Office of Business Transformation, US Army General Funds Enterprise Business Systems (GFEBS), US Army Global Combat Support System (GCSS), US Customs Border Patrol (CBP), and US Department of Agriculture (USDA). JTSi has also been involved with the US Army SAP implementation from its inception.

</p>
<a class="btn btn-lg btn-primary" [routerLink]="['/about-us/vision']">Learn More &#8594; </a>
</div>
<div class="pictureSide">
  <div class="picture">
    <img src="../../assets/img/information_technology.jpg" alt="">
  </div>
</div>

</div>
</div>
    </section>
    <app-capabilities></app-capabilities>
<app-sapservice></app-sapservice>

<section  class="commonPadding">
  <div class="containers">
    <div class="texttitle_two">
      <h2>Center Of Excellence</h2>
  </div>
  <div class="HomeAbout">
    <div class="pictureSide">
      <div class="picture">
        <img src="../../assets/img/SAP_EWM.jpg" alt="">
      </div>
    </div>
    <div class="textSide">
      <h3>Capability Highlights</h3>
      <hr>
      <ul class="bulletpoints">
        <li>	SAP EWM implementation per the client processes and regulatory compliance, owing to the nuances of customer’s business processes and unique regulatory compliance requirements.  Customize where standard SAP EWM is not a direct fit.</li>
<li>	Expertise to Integrate SAP’s EH&S (Environmental Health and Safety module) with SAP EWM S/4 HANA.</li>
<li>	Optimizing Warehouse Monitor to meet business needs, EWM monitor would be one stop shop for all reports and monitoring.</li>
<li>	Implementation expertise includes designing labels using Windows Word processor Integration Server and GLM (Global Label Management), Managing barcode compliance to Army regulations through GLM.</li>
<li>	Expertise in Inventory goods movement (GR, GI, TP, cycle count) in connected and disconnected mode. JTSi has extensive experience working with mobile technology both on Android and Windows.</li>
<li>	Army authorized X.509 security wrapper around our mobile apps to make it DoD security compliant and Army enterprise grade.</li>
      </ul>
    </div>
    </div>

  </div>
  </section>

<div class="clientele grayBg">
  <div class="texttitle_two">
    <h2>Our Partners</h2>
    <h4>Delivering Better Solutions That Drive Superior Services & Business Results</h4>
</div>
  <div class="containers">
<p>
  We map out quick solutions to help your business make big strides. We work with diverse industry segments including Pharmaceutical and Healthcare, Aerospace and Defense, Oil and Banking, Federal Government, Retail, and Industrial markets. We are committed to the success of every client which is demonstrated by our exceptional client-retention rate. We constantly deliver excellence through quality.
</p>
<div class="logolists">
  <img src="../../assets/img/Partners/accenture.jpg" alt="">
  <img src="../../assets/img/Partners/air.jpg" alt="">
  <img src="../../assets/img/Partners/baesystem.jpg" alt="">
  <img src="../../assets/img/Partners/c5data.jpg" alt="">
  <img src="../../assets/img/Partners/caci.jpg" alt="">
  <img src="../../assets/img/Partners/devcom.jpg" alt="">
  <img src="../../assets/img/Partners/dod.jpg" alt="">
  <img src="../../assets/img/Partners/dot.jpg" alt="">
  <img src="../../assets/img/Partners/ibm.jpg" alt="">
  <img src="../../assets/img/Partners/itar.jpg" alt="">
  <img src="../../assets/img/Partners/microsoft.jpg" alt="">
  <img src="../../assets/img/Partners/Sap.jpg" alt="">
  <img src="../../assets/img/Partners/saphana.jpg" alt="">
  <img src="../../assets/img/Partners/software.jpg" alt="">
  <img src="../../assets/img/Partners/usc.jpg" alt="">
  <img src="../../assets/img/Partners/verify.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_01.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_02.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_03.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_04.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_05.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_06.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_07.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_08.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_09.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_10.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_11.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_12.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_13.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_14.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_15.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_16.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_17.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_18.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_19.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_20.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_21.jpg" alt="">
  <img src="../../assets/img/Partners/leverx.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_23.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_24.jpg" alt="">
  <img src="../../assets/img/Partners/partnr_25.jpg" alt="">
</div>
</div>
</div>


 


