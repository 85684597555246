<div class="innerHeader surveyshield">
  <div class="innerHeadings">
    <h1>
    SurveyShield
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/surveyshield_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  SurveyShield
                </h2>
        </div>
        <p>
          SurveyShield is a very comprehensive, secure, and easy online survey application available in both Web and Mobile modes. This application enables you to create and manage quick and easy professional online surveys. When using JTSi SurveyShield Mobile App, data is captured in a timely and accurate manner, compared to traditional methods of capturing data using hardcopy forms/paperwork. The survey data captured on mobile devices (iOS, Android, and MS Windows platforms), is then transferred to servers for further analysis and screening. SurveyShield is the first known Application Service Provider (ASP) service that provides the Freedom Data Server (FDS) option. FDS capability provides you with a choice of storing survey responses within your own hosted data server or within ours. FDS guarantees maximum security for your sensitive survey responses.
        </p>
        </div>
  </div>
</div>
</section>
  <section class="grayBg commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          Highlights
        </h2>
      </div>
<div class="four-cols lessTwo">
<div class="colBox">
  <div class="colIcon">
  <svg>
    <use xlink:href="../../assets/img/sprite.svg#shield01"></use>
  </svg>
</div>
<h4>
  Features
</h4>
<ul class="bulletpoints">
  <li>	Survey deployment through Internal email and lists plus </li>
<li>	Personal email and web page URL inserts</li>
<li>	Real time Results</li>
<li>	Advanced online HTML reporting console</li>
<li>	Data export to Excel, CSV, or SPSS file formats</li>
<li>	Custom Reporting</li>
<li>	Bulk User registration</li>
<li>	Freedom Data Server for the highest level of security</li>
<li>	Data Ownership</li>
<li>	21 question types and 4 matrix question formats</li>
<li>	Paging, linking and auto fill from prior answers</li>
<li>	Custom Logo & branding feature</li>
<li>	24/7 email and phone support</li>

</ul>
</div>
<div class="colBox">
  <div class="colIcon">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#shield02"></use>
    </svg>
  </div>
  <h4>
    Benefits
  </h4>
  <ul class="bulletpoints">
    <li>	Easily modifiable look and feel</li>
<li>	Easy to prepare and use design templates</li>
<li>	Reusable Question Libraries</li>
<li>	Powerful page condition logic</li>
<li>	Easy to use administrative options</li>
<li>	Survey tips and tutorials</li>
<li>	Six security Levels</li>
<li>	Three levels of survey groups</li>
<li>	Survey owner</li>
<li>	Group owner</li>
<li>	Respondents</li>

  </ul>
  </div>

</div>
</div>
  </section>

