<div class="innerHeader news">
  <div class="innerHeadings">
    <h1>
     News
    </h1>
  </div>
  </div>

  <section class="commonPadding Tpadd0">
    <!-- <div class="grayBg" style="padding:4rem 0;">
    <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/holiday.jpg" alt="">
        <div class="thumbdetails">
          <h3 class="subtitles_two">
            Happy Holidays and Happy New Year 2023
  </h3>
  <p>
   <strong>Wishing you happy holidays and prosperous New Year</strong>
  </p>
<p>
  Warmest thoughts and best wishes to you from all of us at JTSi. Thank you for choosing us as your partner and we look forward to serving you another year. May the coming year be full of grand adventures and opportunities.
</p>
        </div>
      </div>
      <div class="holidayvideo">
        <video  controls controlsList="nodownload" autoplay muted poster="../../../assets/img/Cover-Image_JTSi-USA.jpg">
          <source src="../../../assets/img/HappyHoliday_usa.mp4" type="video/mp4">
         </video>
      </div>
      </div>

    </div> -->
      <div class="containers">
        <div class="smallthubs_01">
          <img  src="../../../assets/img/DOD_news.jpg" alt="">
          <div class="thumbdetails pb-1" >
            <h3 class="subtitles_two" style="text-transform: none !important;">
              Johnson Technology Systems, Inc. (DBA: JTSi), wins multi-year Full & Open United States General Services Administration (GSA) - US Army Cloud Migration Contract under GSA-MAS SIN 518210C
              </h3>
<p>
  Johnson Technology Systems, Inc. (JTSi), a full-service IT and engineering services organization is proud to announce the award of its prestigious US ARMY AEP Cloud Migration contract under the GSA MAS Contract competed off GSA's consolidated Cloud and Cloud Related Professional Services SIN 518210C.
</p>
<p >
  This fully competed multiyear task-order award will migrate the Government hosted JPEO/SAP & Non-SAP ERP Central Component (ECC) computer environment from the Government’s Network Enterprise Center (NEC) (the On-Premises) to a Microsoft Azure based commercial secure cloud based computer and services environment through the Enterprise Cloud Management Office (ECMO) using cArmy enterprise common services, data services, and all US-DoD Secure Cloud Computing Architecture (SCCA) components and will prioritize use of Software as a Service (SaaS) and Platform as a Service (PaaS). Systems under this contract will transition in accordance with ECMO guidance. These computer and services environments support the highly complex PEO missions that include developing, procuring, and supplying ammunition to the Warfighter to provide a fully integrated enterprise environment that manages information as a tangible critical resource. Under this contract JTSi will validate both the on-premises & remote systems and provide sizing specifications for the cloud end state, create a step by step “playbook” for creating necessary cloud architecture to support the migration of data and processes, and begin the lift and shift of the systems complying with all application programming interface (API) information within the Enterprise Data Services Catalog (EDSC).
 </p>
          </div>
        </div>

        </div>

    <div class="grayBg">
      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/benet.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi wins VaultPRO to EDGE Windchill migration Task order on ARDEC IT Services
    </h3>
<p>
  Benét Laboratories, part of the US Army CCDC-AC, has awarded JTSi this migration work and also includes Training Super Users.
</p>
          </div>
        </div>
        </div>
      </div>


        <div class="containers">
          <div class="smallthubs_01">
            <img class="img-fluid" src="../../../assets/img/think_cloud.jpg" alt="">
            <div class="thumbdetails">
              <h3 class="subtitles_two">
                GSA 518210C award
      </h3>
  <p>
    JTSi has achieved the Cloud and Cloud-Related IT Professional Services Special Item Number (SIN) 518210C (consolidated solicitation) which allows federal, state, local, and tribal agencies to purchase, Commercial cloud IT professional services (labor categories) that support activities associated with assessing cloud solutions, refactoring workloads for cloud solutions, migrating legacy or other systems to cloud solutions, providing management or governance of cloud solutions, cloud DevOps/DevSecOps, developing cloud native applications, or other cloud-oriented activities.
  </p>
            </div>
          </div>
          </div>

          <div class="grayBg">
            <div class="containers">
              <div class="smallthubs_01">
                <img class="img-fluid" src="../../../assets/img/devcom_news.jpg" alt="">
                <div class="thumbdetails">
                  <h3 class="subtitles_two">
                    (DEVCOM) SAP Support services Contract Award
          </h3>
      <p>
        JTSi has been awarded Prime contractor on (DEVCOM) SAP Platform support services Contract.
      </p>
                </div>
              </div>
              </div>
            </div>



              <div class="containers">
                <div class="smallthubs_01">
                  <img class="img-fluid" src="../../../assets/img/devcom_news2.jpg" alt="">
                  <div class="thumbdetails">
                    <h3 class="subtitles_two">
                      DEVCOM Armaments Center (AC) Knowledge Management Office(KMO) support services task-order
            </h3>
        <p>
          JTSi has been awarded DEVCOM Armaments Center (AC) Knowledge Management Office(KMO) support services task-order award as Prime contractor.
        </p>
                  </div>
                </div>
                </div>


                <div class="grayBg">
                  <div class="containers">
                    <div class="smallthubs_01">
                      <img class="img-fluid" src="../../../assets/img/dla_cloud.jpg" alt="">
                      <div class="thumbdetails">
                        <h3 class="subtitles_two">
                          DLA Cloud Migration award
                </h3>
            <p>
              JTSi is part of the team responsible for Migration of DLA’s SAP and non-SAP systems to the Cloud
            </p>
                      </div>
                    </div>
                    </div>
                  </div>


                    <div class="containers">
                      <div class="smallthubs_01">
                        <img class="img-fluid" src="../../../assets/img/news_office.jpg" alt="">
                        <div class="thumbdetails">
                          <h3 class="subtitles_two">
                            New office at Wharton, NJ
                  </h3>
              <p>
                JTSi opens new office at 370 Richard Mine Road, Wharton, NJ
              </p>
                        </div>
                      </div>
                      </div>


                      <div class="grayBg">
                        <div class="containers">
                          <div class="smallthubs_01">
                            <img class="img-fluid" src="../../../assets/img/microsoft_news.jpg" alt="">
                            <div class="thumbdetails">
                              <h3 class="subtitles_two">
                                JTSi becomes Microsoft AOS-G Partner
                      </h3>
                  <p>
                    The Microsoft Agreement for Online Services - Government program, better known as the AOS-G program, enables government and commercial organizations the ability to purchase Microsoft Government Cloud Community High (GCC High) licenses from an authorized Microsoft partner.
                  </p>
                            </div>
                          </div>
                          </div>
                        </div>


                          <div class="containers">
                            <div class="smallthubs_01">
                              <img class="img-fluid" src="../../../assets/img/sapewm_news.jpg" alt="">
                              <div class="thumbdetails">
                                <h3 class="subtitles_two">
                                  EWM Solution award from US Army
                        </h3>
                 <p>
                  JTSi has been tasked to create a EWM Solution around Army business processes and unique regulatory compliance requirements. Tasks also include Barcode designs and compliance through GLM, Inventory Good movement in connected and disconnected mode, mobile apps which are DOD Security compliant and Army Enterprise grade.
                 </p>
                              </div>
                            </div>
                            </div>


                            <div class="grayBg">
                              <div class="containers">
                                <div class="smallthubs_01">
                                  <img class="img-fluid" src="../../../assets/img/lmp.jpg" alt="">
                                  <div class="thumbdetails">
                                    <h3 class="subtitles_two">
                                      LMP PMO Cloud Migration Award
                            </h3>
                     <p>
                      JTSi is part of LMP PMO Cloud migration efforts to migrate their data to the CLOUD using AZURE and AWS platforms.
                     </p>
                                  </div>
                                </div>
                                </div>
                              </div>



                                <div class="containers">
                                  <div class="smallthubs_01">
                                    <img class="img-fluid" src="../../../assets/img/news_03.jpg" alt="">
                                    <div class="thumbdetails">
                                      <h3 class="subtitles_two">
                                        Picatinny Arsenal
                              </h3>
                              <p>
                                Johnson Technology Systems Inc (JTSi) has been awarded Department of Defense (DOD) Ordinance Technology Consortium (DOTC) OTA Award Management Portal Application Development Services contract as a Prime Contractor. JTSi will support design, development and deployment of an OTA Award Management Portal for the Armament Center DOTC Office. JTSi is a WOSB having 17 years plus of Engineering and IT services experience with HQ in Reston, VA and offices in Picatinny Arsenal, NJ.
                              </p>
                                    </div>
                                  </div>
                                  </div>


                                  <div class="grayBg">
                                    <div class="containers">
                                      <div class="smallthubs_01">
                                        <img class="img-fluid" src="../../../assets/img/usda.jpg" alt="">
                                        <div class="thumbdetails">
                                          <h3 class="subtitles_two">
                                            USDA Farmers to Families Food Box Program
                                  </h3>
                         <p>
                          JTSi is part of USDA WBSCM Program generating highly effective real-time Geography based reports to help USDA deliver food boxes quickly and efficiently to needy communities during COVID-19
                         </p>
                                        </div>
                                      </div>
                                      </div>
                                    </div>



                                      <div class="containers">
                                        <div class="smallthubs_01">
                                          <img class="img-fluid" src="../../../assets/img/news_05.jpg" alt="">
                                          <div class="thumbdetails">
                                            <h3 class="subtitles_two">
                                              CCDC Corporate IT Contract Award
                                    </h3>
                                    <p>
                                      Johnson Technology Systems Inc (JTSi) has been awarded CCDC Corporate IT contract as a Prime contractor. Services shall be provided to support CCDC Armaments Center mission to plan, direct, and execute engineering and system assurance programs for all CCDC Armaments Center supported systems/programs and external customers. JTSi is a WOSB having 17 years plus of Engineering and IT services experience with HQ in Reston, VA and offices in Picatinny Arsenal, NJ.
                                    </p>
                                          </div>
                                        </div>
                                        </div>


                                        <div class="grayBg">
                                          <div class="containers">
                                            <div class="smallthubs_01">
                                              <img class="img-fluid" src="../../../assets/img/news_04.jpg" alt="">
                                              <div class="thumbdetails">
                                                <h3 class="subtitles_two">
                                                  Picatinny Arsenal – M&PTD Contract
                                        </h3>
                                        <p>
                                          Johnson Technology Systems Inc (JTSi) has been awarded Manufacturing and Prototype Technology Division (M&PTD) contract as Prime contractor. Services shall be provided to support AE&Ms mission to engineer, analyze, fabricate, assemble and deliver prototype and testing quantities of hardware for all Combat Capability Development Command Armaments Center (CCDC AC) supported systems/programs and external customers. JTSi is a WOSB having 17 years plus of Engineering and IT services experience with HQ in Reston, VA and offices in Picatinny Arsenal, NJ.
                                        </p>
                                              </div>
                                            </div>
                                            </div>
                                          </div>



                                            <div class="containers">
                                              <div class="smallthubs_01">
                                                <img class="img-fluid" src="../../../assets/img/news_06.jpg" alt="">
                                                <div class="thumbdetails">
                                                  <h3 class="subtitles_two">
                                                    ALERT! Contract Award
                                          </h3>
                                          <p>
                                            Johnson Technology Systems Inc (JTSi) has been awarded Alert! Program Support contract as a Prime contractor. JTSi is providing systems engineering support for the sustainment of the Army Alert! Program in areas involving the necessary design and development skills to support Wide Area Alert Notification (WAAN). JTSi is a WOSB having 17 years plus of Engineering and IT services experience with HQ in Reston, VA and offices in Picatinny Arsenal, NJ.
                                          </p>
                                         </div>
                                              </div>
                                              </div>

                                              <div class="grayBg">
                                                <div class="containers">
                                                  <div class="smallthubs_01">
                                                    <img class="img-fluid" src="../../../assets/img/news_07.jpg" alt="">
                                                    <div class="thumbdetails">
                                                      <h3 class="subtitles_two">
                                                        Process Integration  Contract Award – Army PLM Systems
                                              </h3>
                                              <p>
                                                Johnson Technology Systems Inc (JTSi) has been awarded Process Integration contract as a GSA Prime Contractor. JTSi is responsible for integrating IPS process engine to support workflow enablement for the US Army PLM systems, enabling users to easily create, manage and maintain workflows. Responsibilities also include integrating Windchill directly to SAP PLM improving data accuracy and to also deliver 3D content to the engineering and acquisition communities.
                                              </p>
                                                    </div>
                                                  </div>
                                                  </div>
                                                </div>


                                                  <div class="containers">
                                                    <div class="smallthubs_01">
                                                      <img class="img-fluid" src="../../../assets/img/news_02.jpg" alt="">
                                                      <div class="thumbdetails">
                                                        <h3 class="subtitles_two">
                                                          eNOVA contract award
                                                </h3>
                                                <p>
                                                  Johnson Technology Systems Inc (JTSi) has been awarded eNOVA contract to maintain and sustain the eNOVA system as Prime Contractor.
                                                </p>
                                                      </div>
                                                    </div>
                                                    </div>


                                                    <div class="grayBg">
                                                      <div class="containers">
                                                        <div class="smallthubs_01">
                                                          <img class="img-fluid" src="../../../assets/img/Michigan.jpg" alt="">
                                                          <div class="thumbdetails">
                                                            <h3 class="subtitles_two">
                                                              State of Michigan Cloud Migration
                                                    </h3>
                                                 <p>
                                                  JTSi is part of the team awarded by State of Michigan for Cloud Migration of SAP ECC to Microsoft Azure Cloud.
                                                 </p>
                                                          </div>
                                                        </div>
                                                        </div>
                                                      </div>


                                                        <div class="containers">
                                                          <div class="smallthubs_01">
                                                            <img class="img-fluid" src="../../../assets/img/news_09.jpg" alt="">
                                                            <div class="thumbdetails">
                                                              <h3 class="subtitles_two">
                                                                NS2 NOW
                                                      </h3>
                                                      <p>
                                                        JTSi was sponsor on October, 2019 NS2 NOW Summit.  The Keynote was addressed by Keynote Address: General James Mattis, USMC, (Ret.) 26th U.S. Secretary of Defense (Jan. 2017 – Dec. 2018) and moderated by Michael Vickers, Chairman, NS2 Advisory Board and Former Undersecretary of Defense for Intelligence, USD(I), 2011-2015.
                                                      </p>
                                                            </div>
                                                          </div>
                                                          </div>


                                                          <div class="grayBg">
                                                            <div class="containers">
                                                              <div class="smallthubs_01">
                                                                <img class="img-fluid" src="../../../assets/img/news_01.jpg" alt="">
                                                                <div class="thumbdetails">
                                                                  <h3 class="subtitles_two">
                                                                    CVS Task Order Award
                                                          </h3>
                                                          <p>
                                                            Johnson Technology Systems Inc (JTSi) has been awarded the Customer Vendor Solution (CVS) 3.1 and 3.2 Task Order as Prime contractor on Army ERP SB IDIQ.
                                                           </p>
                                                                </div>
                                                              </div>
                                                              </div>
                                                            </div>



                                                              <div class="containers">
                                                                <div class="smallthubs_01">
                                                                  <img class="img-fluid" src="../../../assets/img/army_news.jpg" alt="">
                                                                  <div class="thumbdetails">
                                                                    <h3 class="subtitles_two">
                                                                      JTSi wins US Army ERP Services Contract
                                                            </h3>
                                                           <p>
                                                            JTSi has been awarded the prestigious ERP Services Contract by the U.S. Department of the Army– IDIQ contract with 5 year ordering periods.
                                                            </p><p>
                                                            JTSi is honored to have been selected to serve as a prime contractor to the United States Army/PEO-EIS/AESIP leadership in support of one of the largest and highly complex ERP implementations in the world stated Joe MeenamKunnel, JTSi – Executive Vice President.
                                                           </p>
                                                                  </div>
                                                                </div>
                                                                </div>


                                                                <div class="grayBg">
                                                                  <div class="containers">
                                                                    <div class="smallthubs_01">
                                                                      <img class="img-fluid" src="../../../assets/img/navsup.jpg" alt="">
                                                                      <div class="thumbdetails">
                                                                        <h3 class="subtitles_two">
                                                                          NAVSUP HANA Migration
                                                                </h3>
                                                              <p>
                                                                JTSi is part of the team responsible for ECC DB migration to HANA and performance optimization of HANA DB to make use of HANA capabilities.
                                                              </p>
                                                                      </div>
                                                                    </div>
                                                                    </div>
                                                                  </div>


                                                                    <div class="containers">
                                                                      <div class="smallthubs_01">
                                                                        <img class="img-fluid" src="../../../assets/img/news_08.jpg" alt="">
                                                                        <div class="thumbdetails">
                                                                          <h3 class="subtitles_two">
                                                                            “Hats-off” with NS2 Serves Graduates
                                                                  </h3>
                                                             <p>
                                                              SAP NS2 held their Annual SAP NS2 Solutions Summit - Human Critical: Empowering People to Drive U.S. National Security. JTSi was a sponsor for this event.
                                                             </p>
                                                                        </div>
                                                                      </div>
                                                                      </div>

                                                                      <!-- <div class="grayBg">
                                                                        <div class="containers">
                                                                          <div class="smallthubs_01">
                                                                            <img class="img-fluid" src="../../../assets/img/Darryl.jpg" alt="">
                                                                            <div class="thumbdetails">
                                                                              <h3 class="subtitles_two">
                                                                                Darryl Vegh joins JTSi
                                                                      </h3>
                                                                    <p>
                                                                      Retired Director, US Army SSC joins JTSi as Senior ERP Technical Architect
                                                                    </p>
                                                                            </div>
                                                                          </div>
                                                                          </div>
                                                                        </div> -->

                                                                        <div class="grayBg">
                                                                          <div class="containers">
                                                                            <div class="smallthubs_01">
                                                                              <img class="img-fluid" src="../../../assets/img/sapedge.jpg" alt="">
                                                                              <div class="thumbdetails">
                                                                                <h3 class="subtitles_two">
                                                                                  JTSi becomes SAP PartnerEdge- Build partner
                                                                        </h3>
                                                                <p>
                                                                  Through this comprehensive software partner program, JTSi can help customers accelerate innovation and realise the promise of the Intelligent Enterprise.
                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                            </div>
</div>

                                                                            <!-- <div class="grayBg">
                                                                              <div class="containers">
                                                                                <div class="smallthubs_01">
                                                                                  <img class="img-fluid" src="../../../assets/img/Albinson.jpg" alt="">
                                                                                  <div class="thumbdetails">
                                                                                    <h3 class="subtitles_two">
                                                                                      George Albinson joins JTSi
                                                                            </h3>
                                                                          <p>
                                                                            Retired Chief Information Officer, US Army, Mr. George Albinson joins JTSi as Senior VP for Enterprise Services.
                                                                          </p>
                                                                                  </div>
                                                                                </div>
                                                                                </div>
                                                                              </div> -->

  </section>



  <!-- <div class="containers">
    <div class="topsection BpaddSmall">
      <div class="picture">
        <img class="img-fluid" src="../../../assets/img/news_08.jpg" alt="">
    </div>
      <div class="texts">
          <div class="heading redBg">
                  <h2>
                    Annual SAP NS2 Solutions Summit
                  </h2>
          </div>
  <p>
    SAP NS2 held their Annual SAP NS2 Solutions Summit - Human Critical: Empowering People to Drive U.S. National Security.  JTSi was a sponsor for this event. The Agenda included a promotion of the SAP Analytics/BW/HANA training program run by SAP NS2 Serves to help Veterans transition successfully and productively from the Military back into the Civilian workforce. JTSi has demonstrated a strong commitment to and support for this program.  JTSi attended the Open House for the 22 upcoming graduates from the NS2 Serves Class, to meet and interview them for possible positions with JTSi’s Customers and Programs.  These are very motivated, dedicated and disciplined people with a passion for the subjects they have learnt in the Class, and a strong desire to work hard and use the full range of their experiences to excel in any role. Below are quotes from some of the emails we received from them since the Open House:
  </p>
  <p>
    “I am excited about working with JTSi”
  </p>
          </div>

    </div>
    <p>
      “I am excited about working with JTSi”
  “I am very enthusiastic about the possibility of joining the JTSi team and would greatly appreciate a follow-up as you move forward with the hiring process….. I am confident that my skills and experiences are a great match”
  </p>
  <p>
  “This program and the organizations that support it are changing lives in a significant way. Out of all the companies that came to the open house, JTSi is the one that interested me the most”
  </p>
  <p>
  “Thank you for enlightening me concerning opportunities within JTSi. One thing I've learned as a transitioning veteran is to work for a company whose mission you admire and the JTSi mission resonates with me. I've proven myself in the past by being selected as the Junior Sailor of The Year out of 150 sailors and also in a leadership position as the Junior Enlisted Advisory Board Chairman. I've also proved that I'm able to learn new skills in going from a Medic to earning an undergraduate degree in Business Management and now Information Technology with my SAP BW 7.4 certification. Finally, I know I will be a great fit in a role within JTSi that offers the opportunity to sharpen my technical and analytical skills while being a team member who is committed to excellence”
  </p>
  <p>
  “To be honest I was greatly impressed with your emphasis on JTSi's "Code of Ethics" and the strong commitment to customer satisfaction visible from the top down within the organization. Thank you for your time and I sincerely look forward to learning more about your upcoming openings”
    </p>
    </div> -->
