<section class="commonPadding" id="capabilities">
    <div class="texttitle_two">
        <h2>Capabilities</h2>
    </div>
  <div class="containers homeGrid">

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../../assets/img/capabilities/Digital-Transformation.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Digital Transformation
        </h4>
        <a [routerLink]="['/capabilities/digital-transformation']" class="btn btn-md btn-primary" >Detailed View</a>
      </div>
    </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Cybersecurity.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cyber Security & IA
      </h4>
      <a [routerLink]="['/capabilities/cyber-security-and-ia']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Operations.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Operations & Maintenance (O&M)
      </h4>
      <a [routerLink]="['/capabilities/operations-and-maintenance']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Enterprise_Knowledge.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Enterprise Knowledge
  Repository (EKR)
      </h4>
      <a [routerLink]="['/capabilities/ekr']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Application_Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Data & Application
  Migration
      </h4>
      <a [routerLink]="['/capabilities/data-and-application-migration']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Application_Modernization.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Application Modernization
      </h4>
      <a [routerLink]="['/capabilities/application-modernization']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Managed_IT_Services.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Managed IT Services
      </h4>
      <a [routerLink]="['/capabilities/managed-it-services']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Software_Factory.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Software Factory & COE
      </h4>
      <a [routerLink]="['/capabilities/software-factory-and-coe']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>
  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../../assets/img/capabilities/Sap_Cloud_Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cloud Migration & CSP

      </h4>

      <a [routerLink]="['/sap/cloud-migration-and-csp']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>


  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Migration_Strategy.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Migration Strategy
  Development
      </h4>
      <a [routerLink]="['/capabilities/migration-strategy-development']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Enterprise_Architecture.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Enterprise Architecture
        (EA)
      </h4>
      <a [routerLink]="['/capabilities/enterprise-architecture']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/hybrid_cloud.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Private/Public/Hybrid
  Cloud Services
      </h4>
      <a [routerLink]="['/capabilities/private-public-hybrid-cloud-services']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Data-Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Data Migration / ETL
      </h4>

      <a [routerLink]="['/capabilities/data-migration-etl']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Predictive_Analytics.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Predictive Analytics
      </h4>

      <a [routerLink]="['/capabilities/predictive-analytics']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Business_Intelligence.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Business
  Intelligence/Data
  Visualization
      </h4>

      <a [routerLink]="['/capabilities/business-intelligence-data-visualization']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Machine_Learning.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Machine Learning/Deep
  Learning
      </h4>

      <a [routerLink]="['/capabilities/machine-learning-deep-learning']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Systems-Engineering.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Systems Engineering
      </h4>

      <a [routerLink]="['/capabilities/systems-engineering']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Product_Development.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Product Development
      </h4>

      <a [routerLink]="['/capabilities/product-development']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Product_Life_Cycle.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
       Product Life Cycle Management (PLM)

      </h4>

      <a [routerLink]="['/capabilities/product-life-cycle-management']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../../assets/img/capabilities/Sap_Cloud_Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cloud Migration & CSP

      </h4>

      <a [routerLink]="['/sap/cloud-migration-and-csp']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>




  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Prototyping.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Prototyping & 3D
Modelling
      </h4>
  <a [routerLink]="['/Support-Services/Prototyping-3DModelling']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->


  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/ptc.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        PTC - Windchill & IPS
Integration
      </h4>
  <a [routerLink]="['/Support-Services/PTC-Windchill']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->


  </div>
  </section>
