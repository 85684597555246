<div class="innerHeader ManagedIT">
  <div class="innerHeadings">
    <h1>
      Managed IT Services
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/mangedIT_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Managed IT services by JTSi
                </h2>
        </div>
       <p>
      <strong>  Managed IT services by JTSi backed with 17 years of Enterprise experience </strong>span the <strong>all-round support for cloud, on-premises and hybrid infrastructures </strong>and include consulting and migration services.
       </p>
        </div>
  </div>
</div>
</section>
  <section class="grayBg commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          Our Managed IT Services
        </h2>
      </div>
<div class="borderd-grids threesome">
<div class="gridbox grayBg">
<h3 class="subtitles_one">
  Managed Application Services
</h3>
<ul class="bulletpoints">
  <li>	Application monitoring </li>
<li>	performance management</li>
<li>	Application troubleshooting</li>
<li>	Application enhancement</li>
<li>	Application optimization</li>
<li>	Change management and training </li>
<li>	services</li>
<li>	Application security</li>
<li>	Help Desk</li>

</ul>
</div>
<div class="gridbox grayBg">
  <h3 class="subtitles_one">
    Managed QA Services
  </h3>
  <ul class="bulletpoints">
    <li>	functional</li>
    <li>	integration</li>
    <li>	compatibility</li>
    <li>	performance</li>
    <li>	usability</li>
    <li>	security managed testing services</li>


  </ul>
  </div>
  <div class="gridbox grayBg">
    <h3 class="subtitles_one">
      Managed Security Services
    </h3>
    <ul class="bulletpoints">
      <li>Security strategy design</li>
      <li>IT infrastructure configuration</li>
      <li>Security Policies</li>
      <li>Managed detection and response to security threats</li>
    </ul>
    </div>
    <div class="gridbox grayBg">
      <h3 class="subtitles_one">
        Infrastructure Managed Services
      </h3>
      <ul class="bulletpoints">
        <li>	AWS Managed Services</li>
<li>	Azure Managed Services</li>

      </ul>
      </div>
      <div class="gridbox grayBg">
        <h3 class="subtitles_one">
          Help Desk Services
        </h3>
        <ul class="bulletpoints">
          <li>L1 User Support</li>
<li>L2 Technical Support</li>
<li>L3 Software Engineering</li>
        </ul>
        </div>
        <div class="gridbox grayBg">
          <h3 class="subtitles_one">
            Managed Software Development
          </h3>
          <ul class="bulletpoints">
            <li>Dedicated Development Teams</li>
<li>Staff Augmentation</li>

          </ul>
          </div>
</div>
</div>
  </section>

  <section class=" commonPadding">
    <div class="containers">
      <h3 class="subtitles_one">
        Managed IT Support
      </h3>
      <ul class="bulletpoints colThree">
        <li>Networks</li>
<li>Including databases</li>
<li>Cybersecurity solutions</li>
<li>IT environment administration</li>
<li>On premises data centers</li>
<li>Data warehouses</li>
<li>Websites</li>
<li>IT issues troubleshooting</li>
<li>Cloud services (IaaS, PaaS, SaaS)</li>
<li>Big data storages</li>
<li>Desktops</li>
<li>IT infrastructure growth</li>
<li>Data storages</li>
<li>Applications</li>
<li>IT infrastructure consulting</li>

      </ul>
      </div>
      </section>
