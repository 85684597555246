<div class="innerHeader Migration">
  <div class="innerHeadings">
    <h1>
      Data & Application
     <span> Migration </span>
    </h1>
  </div>
  </div>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/migration_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Data & Application Migration
                </h2>
        </div>
        <p>
          The foundation for any migration project is an assessment of an enterprises application portfolio, including any legacy systems or applications, as well as an understanding of the ecosystem surrounding the applications, including the configurations of the physical and virtual servers, network topology, current support and data dependencies and security and compliance.
        </p>
        </div>
  </div>

  <section class="grayBg">
    <div class="containers">
  <div class="topsection reverse padd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Involve the business as soon as possible
                </h2>
        </div>
    <p>
      It's critical to ensure that all the relevant business units take part in planning the migration and are aware of how it might affect them and existing business processes. Clear communication is key.
    </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/migration_02.jpg" alt="">
      </div>
  </div>

  </div>
  </section>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/migration_03.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Map out a migration strategy
                </h2>
        </div>
        <p>
          Identify the business reasons and use cases for migrating to the cloud. Decide whether to migrate in phases or maybe start with the least business critical workloads to gain experience and then move to more mission critical applications.
        </p>
        </div>
  </div>

  <section class="grayBg">
    <div class="containers">
  <div class="topsection reverse padd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Develop a cloud governance framework
                </h2>
        </div>
    <p>
      This should consist of clear, policy-based rules to help an organization prepare for a secure cloud adoption, including structures, roles, responsibilities, plans, objectives and a decision framework
    </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/migration_04.jpg" alt="">
      </div>
  </div>

  </div>
  </section>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/migration_05.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Train staff as soon as possible
                </h2>
        </div>
        <p>
          Set up a series of training sessions to get employees across different teams and business units up to speed on cloud concepts.
        </p>
        </div>
  </div>

  <section class="grayBg">
    <div class="containers">
  <div class="topsection reverse padd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Manage software licensing properly
                </h2>
        </div>
    <p>
      Document all enterprise applications, closely studying their licensing as it relates to cloud computing. If necessary, check with vendors to determine if existing licenses can be updated so the applications can be used in the cloud.
    </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/migration_06.jpg" alt="">
      </div>
  </div>

  </div>
  </section>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/migration_07.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Automate if possible
                </h2>
        </div>
        <p>
          To improve the overall efficiency of the migration and minimize disruption to the business, organizations should automate repeated patterns where possible. This will speed up the migration process as well as lower risk and save money. The phased cloud migration approach and automation can work together, i.e., identify repeated patterns over time that can be automated in future migration phases.
        </p>
        </div>
  </div>

  <section class="grayBg">
    <div class="containers">
  <div class="topsection reverse padd0">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Test, test and re-test
                </h2>
        </div>
    <p>
      An enterprise that doesn't have a proper test plan in place won't have a successful migration. Starting talking about testing on the first day of the assessment.
    </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/migration_08.jpg" alt="">
      </div>
  </div>

  </div>
  </section>

  <section class="commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>Expertise in migrating database,<br> server and applications</h2>
      </div>
  <div class="four-cols">
    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig001"></use>
          </svg>
        </div>
          <h4>
            Software Migration Services
          </h4>
          <p>
            We use thorough, risk averse migration techniques for manual and automated data extraction, cleansing, loading, verification, testing, and implementation.
          </p>
    </div>
    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig002"></use>
          </svg>
        </div>
          <h4>
            Website Migration Services
          </h4>
          <p>
            We have vast experience migrating websites to and from different web hosting platforms (Bluehost, HostGator, GoDaddy) and (WordPress, Wix, SquareSpace.).
          </p>
    </div>
    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig003"></use>
          </svg>
        </div>
          <h4>
            Cloud Migration Services
          </h4>
          <p>
            We perform reliable cloud to cloud and on premise server to cloud migrations using Azure, AWS, IBM Cloud, Oracle, Google Cloud Platform, Alibaba, and more.
          </p>
    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig004"></use>
          </svg>
        </div>
          <h4>
            Database Migration Services
          </h4>
          <p>
            We migrate multiple data types and business logic schemas, using Extract-Transform Load (ETL) best practices and robust version control protocols.
          </p>
    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig005"></use>
          </svg>
        </div>
          <h4>
            Server Migration Services
          </h4>
          <p>
            We implement server migration solutions for BizTalk Server, Apache Web Server, Internet Information Services (IIS), .NET, Java & PHP frameworks, MoM, RPC, ORB, and more
          </p>
    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig006"></use>
          </svg>
        </div>
          <h4>
            Enterprise Platform Migration Services
          </h4>
          <p>
            We have a deep understanding of data adoption patterns, processes, and platforms to help your business manage your newly migrated cloud applications at scale.
          </p>
    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig007"></use>
          </svg>
        </div>
          <h4>
            Storage Migration Services
          </h4>
         <p>
          We perform storage migration services to offer faster performance and cost effective scalability by allowing users to access their data without changing paths or links.
         </p>
    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
            <use xlink:href="../../../assets/img/sprite.svg#appmig008"></use>
          </svg>
        </div>
          <h4>
            Application Migration Services
          </h4>
          <p>
            Migrating an application can be complicated because there are bound to be differences between the original and target environments. We perform rapid and reliable upgrades for legacy enterprise platforms, migrating all data and business logic to new ERP, CRM, CMS, or other platforms.
          </p>
    </div>

</div>

</div>
</section>

