import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wargrid',
  templateUrl: './wargrid.component.html',
  styleUrls: ['./wargrid.component.scss']
})
export class WargridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
