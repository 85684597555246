import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-systems-engineering',
  templateUrl: './systems-engineering.component.html',
  styleUrls: ['./systems-engineering.component.scss']
})
export class SystemsEngineeringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
