<div class="innerHeader Partnerships">
  <div class="innerHeadings">
    <h1>
      Partnerships/Relationships
    </h1>
  </div>
  </div>
  <div class="clientele">
    <div class="texttitle_two">
      <h2>Our Partners</h2>
      <h4>Delivering Better Solutions That Drive Superior Services & Business Results</h4>
  </div>
    <div class="containers">
  <p>
    We map out quick solutions to help your business make big strides. We work with diverse industry segments including Pharmaceutical and Healthcare, Aerospace and Defense, Oil and, Banking, Federal Government, Retail, and Industrial markets. We are committed to the success of every client which is demonstrated by our exceptional client-retention rate. We constantly deliver excellence through quality.
  </p>
  <div class="logolists">
    <img src="../../assets/img/Partners/accenture.jpg" alt="">
    <img src="../../assets/img/Partners/air.jpg" alt="">
    <img src="../../assets/img/Partners/baesystem.jpg" alt="">
    <img src="../../assets/img/Partners/c5data.jpg" alt="">
    <img src="../../assets/img/Partners/caci.jpg" alt="">
    <img src="../../assets/img/Partners/devcom.jpg" alt="">
    <img src="../../assets/img/Partners/dod.jpg" alt="">
    <img src="../../assets/img/Partners/dot.jpg" alt="">
    <img src="../../assets/img/Partners/ibm.jpg" alt="">
    <img src="../../assets/img/Partners/itar.jpg" alt="">
    <img src="../../assets/img/Partners/microsoft.jpg" alt="">
    <img src="../../assets/img/Partners/Sap.jpg" alt="">
    <img src="../../assets/img/Partners/saphana.jpg" alt="">
    <img src="../../assets/img/Partners/software.jpg" alt="">
    <img src="../../assets/img/Partners/usc.jpg" alt="">
    <img src="../../assets/img/Partners/verify.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_01.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_02.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_03.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_04.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_05.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_06.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_07.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_08.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_09.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_10.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_11.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_12.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_13.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_14.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_15.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_16.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_17.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_18.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_19.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_20.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_21.jpg" alt="">
    <img src="../../assets/img/Partners/leverx.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_23.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_24.jpg" alt="">
    <img src="../../assets/img/Partners/partnr_25.jpg" alt="">
  </div>
  </div>
  </div>

  <section class="grayBg">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Partnerships_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Forge a Mentor-Protégé Relationship with JTSi
                </h2>
        </div>
       <p>
        As in any profession, continuing education is essential to growth. At Johnson Technology Services, Inc, we view our Mentor-Protégé programs in such a light. With the right mentor-protégé relationship, we hope not only to learn from other companies, but to create the foundation for healthy and rewarding long-term relationships. As a business whose excellence has been rewarded with multiple federal contracts, JTSi seeks to continue its education by finding the right mentors.
       </p>
        </div>
  </div>
</div>
</section>
  <section class="commonPadding ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Why JTSi is a good protégé candidate?
                </h2>
        </div>
<ul class="bulletpoints">
  <li>	We are committed to excellence.</li>
<li>	We have a proven track record as a prime contractor on federal contracts. (A past performance list is available upon request).</li>
<li>	Our business plan is already in place to grow the company and venture into new directions.</li>
<li>	We are a Woman-Owned Small Business.</li>
<li>	We have a local presence in New Jersey, the Washington D.C. metro area, and Florida.</li>
<li>	We have a sound financial capability for continuous improvement and growth.</li>
<li>	Our established partnerships/master agreements include such industry leaders as SAP and Microsoft.</li>

</ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/Partnerships_02.jpg" alt="">
      </div>
  </div>
  </div>
  </section>

