<div class="hero">
  <h1>
    GSA MAS/SIN 518210C
  </h1>
 <h4>
  Searching for a <strong>Qualified Teammate</strong> for your upcoming Cloud Service opportunity?
 </h4>
 <ul>
  <li>
    <svg>
      <use xlink:href="../../../assets/gsa/img/sprite.svg#call"></use>
    </svg>
    +1 (703) 949-9100
</li>
<li>
  <svg>
    <use xlink:href="../../../assets/gsa/img/sprite.svg#email"></use>
  </svg>
  info@jtsusa.com
</li>
 </ul>
  </div>
  <section class="sections">
<div class="containers">
<div class="splitclmns">
<picture class="blue">
  <figure>
<img src="../../assets/gsa/img/Vec1.png" alt="">
</figure>
</picture>
<article>
<h2>
  Are you a consultant or a contractor looking for a sincere teammate with outstanding CPARS for an upcoming Cloud Services opportunity under GSA MAS with 518210C?
</h2>
<p>
  You are at the right pace. JTSi is known for its reputation within the Government and the Regulated Industries for over 20 years. We have executed over US$100M worth of IT Services especially within the Cloud and the allied digital transformation arena. <a routerLink="cloudprofessionalservicerates">More..</a>
</p>
</article>
</div>
</div>
</section>
<section class="sections homered">
  <div class="containers">
    <div class="equalclmns vcenter">
    <picture>
      <img src="../../assets/gsa/img/P1.png" alt="">
    </picture>
    <article>
      <h2>
        Schedule an Appointment
      </h2>
      <p>
        Fill contact form below with date and time for no obligation cloud consulting assessment and starting your journey into AWS.
      </p>
      <a class="bigbtn" [routerLink]="['/gsa518210c/contact']">
        Schedule an Appointment
      </a>
    </article>
    </div>
  </div>
</section>
<section class="sections">
  <div class="containers">
  <div class="splitclmns">
  <article>
  <h2>
    Has great Business Development team and eco-system, but don’t have contract vehicles and qualifying past performance?
  </h2>
  <p>
    Yes, you are at the right place. JTSi can be your sincere teammate. At JTSi our motto is, “We do what we say”. We are founded on 20 years of reputation amongst our partners, customers and employees. Do not let your opportunities slip for not having the past performance and or the contract vehicles.
  </p>
  </article>
  <picture class="beige">
    <figure>
  <img src="../../assets/gsa/img/Vec2.png" alt="">
  </figure>
  </picture>
  </div>
  </div>
  </section>
  <section class="sections midtop">
    <div class="containers">
    <div class="splitclmns">
    <picture class="violet">
      <figure>
    <img src="../../assets/gsa/img/Vec3.png" alt="">
    </figure>
    </picture>
    <article>
<h2>
  Has everything but a winning proposal team!!
</h2>
 <p>
  Yes, you are at the right place. When it comes to Software Development, SAP Services, Cyber Security, Vendor Agnostics Cloud Services (System Migration, Application migration, ATOS, APIs etc.), Digital Transformation expertise, JTSi could be your dream partner. JTSi maintains robust proposal team supported by Technical Experts/SMEs, Cloud Architects and Price to win expert Cost Proposal experts with prior experience with DCAA and Contracting Commands.
 </p>
    </article>
    </div>

    </div>
    </section>
    <section class="sections midtop">
      <div class="containers">
      <div class="splitclmns">
      <article>
      <h2>
        JTSi wins Major multi-year Full & Open DOD/Army Cloud Services & Migration Contract under GSA MAS SIN 518210C?
      </h2>
 <p>
  JTSi migrates the Government hosted JPEO/SAP ERP Central Component (ECC) computer environment including its non-sap systems from the Government’s Network Enterprise Center (NEC) (the On-Premises) to a Microsoft Azure based secure cloud based computer and services environment through the Enterprise Cloud Management Office (ECMO) using cArmy enterprise common services, data services, and all US-DoD Secure Cloud Computing Architecture (SCCA) components and will prioritize use of Software as a Service (SaaS) and Platform as a Service (PaaS). Legacy systems under this contract will transition in accordance with ECMO guidance. These computer and services environments support the highly complex world-wide JPEO/A and A mission of developing, procuring, and supplying ammunition to the Warfighter to provide a fully integrated enterprise environment that manages information as a tangible critical resource. We will validate both the on-premise & remote systems and provide sizing specifications for the cloud end state,
 </p>
      </article>
      <picture class="dblue">
        <figure>
      <img src="../../assets/gsa/img/Vec2.png" alt="">
      </figure>
      </picture>
      </div>
      <p>
        create a step by step “playbook” for creating necessary cloud architecture to support the migration of data and processes, and begin the lift and shift of the systems complying with all application programming interface (API) information within the Enterprise Data Services Catalog (EDSC). The effort is in progress and it demonstrates JTSi’s outstanding capability on Cloud Services and allied technologies.
      </p>
      </div>
      </section>
      <section class="sections homecontact">
        <div class="containers">
        <div class="equalclmns vcenter">
          <article>
            <h2>
              Contact us
            </h2>
            <p>
              Should you need help with an opportunity you currently track, <a routerLink="contact"> please click here to send us an email</a> or complete the following form
            </p>
            <img src="../../assets/gsa/img/P2.png" alt="">
          </article>
          <article>
            <div class="homeform">
              <form>
                <div class="formgroup">
                <div class="formfields">
                  <label for="">Full name</label>
                  <input class="forminputs" type="text" name="" id="">
                </div>
                <div class="formfields">
                  <label for="">Last name</label>
                  <input class="forminputs" type="text" name="" id="">
                </div>
              </div>
              <div class="formfields">
                <label for="">Company name</label>
                <input class="forminputs" type="text" name="" id="">
              </div>
              <div class="formfields">
                <label for="">Email</label>
                <input class="forminputs" type="email" name="" id="">
              </div>
              <div class="formfields">
                <label for="">Phone number</label>
                <input class="forminputs" type="number" name="" id="">
              </div>
              <div class="formfields">
                <label for="">Message</label>
                <textarea class="forminputs" name="" id="" cols="20" rows="5"></textarea>
              </div>
              <button type="submit">Submit</button>
                </form>
            </div>
          </article>
        </div>
        </div>
        </section>
        <section class="sections homerates">
          <div class="containers">
            <div class="sectitles">
              <h2>
                Rates
              </h2>
            </div>
            <div class="rategrid">
              <div class="clmn product">
                <article>
                <h3>
                  47QTCA23D000J
                </h3>
                <ul>
                  <li>SIN 518210C</li>
                  <li>Cloud Senior DevSecOps</li>
                  <li>Consultant</li>
                </ul>
                <img src="../../assets/gsa/img/GSA-Schedule-Logo-white.png" alt="">
              </article>
              <h4>
                <a [routerLink]="['/gsa518210c/gsaservicedetails']"> SIN 518210C Cloud Senior DevSecOps Consultant</a>
              </h4>
               <p>
                $123.64
               </p>

              </div>
              <div class="clmn product">
                <article>
                <h3>
                  47QTCA23D000J
                </h3>
                <ul>
                  <li>SIN 518210C</li>
                  <li>Cloud Senior DevSecOps</li>
                  <li>Consultant</li>
                </ul>
                <img src="../../assets/gsa/img/GSA-Schedule-Logo-white.png" alt="">
              </article>
              <h4>
                <a [routerLink]="['/gsa518210c/gsaservicedetails']"> SIN 518210C Cloud Senior DevSecOps Consultant</a>
              </h4>
               <p>
                $123.64
               </p>

              </div>
              <div class="clmn product">
                <article>
                <h3>
                  47QTCA23D000J
                </h3>
                <ul>
                  <li>SIN 518210C</li>
                  <li>Cloud Senior DevSecOps</li>
                  <li>Consultant</li>
                </ul>
                <img src="../../assets/gsa/img/GSA-Schedule-Logo-white.png" alt="">
              </article>
              <h4>
                <a [routerLink]="['/gsa518210c/gsaservicedetails']"> SIN 518210C Cloud Senior DevSecOps Consultant</a>
              </h4>
               <p>
                $123.64
               </p>

              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="containers">
<div class="awardgrid">
  <div class="clmn">
    <h2>
      Experience and Agile
      <br>
      Expertise
    </h2>
    <p>
      You can trust
    </p>
  </div>
  <div class="clmn counts">
    <span>
      20
    </span>
    <p>
      Years of Unwavering Reputation
    </p>
  </div>
  <div class="clmn counts">
    <span>
      180+
    </span>
    <p>
      Contracts Awarded
    </p>
  </div>
</div>
          </div>
        </section>
<div class="grayribbon">
  <div class="containers">
    <h3>
      JTSi is your one stop Shop with 20 years of unwavering reputation <a [routerLink]="['/vision']" target="_blank" rel="noopener noreferrer">www.jtsusa.com/vision</a>
    </h3>
  </div>
</div>
