<div class="innerHeader leverx_ptg">
  <div class="innerHeadings">
    <h1>
      Payment Terms Governance
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Manage Payment Terms Across All Integrated Systems
      </h2>
      <h4>Payment Terms Governance represents a set of four indivisible applications designed to establish correct payment terms for purchasing documents</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#flexygray"></use>
            </svg>
           </span>
           Request Payment Terms
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
            <use xlink:href="../../assets/img/sprite.svg#inu"></use>
           </svg>
          </span>
          Payment Terms Rules
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>

              <use xlink:href="../../assets/img/sprite.svg#monitorgray"></use>
            </svg>
           </span>
           Monitor Payment Terms
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#usablegray"></use>
            </svg>
           </span>
           Manage Payment Terms Mapping
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/ptg_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ptg_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ptg_04.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ptg_01.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            5 reasons why you should consider PTG as a tool for simplifying payment terms management
          </h2>
          <h4>The major objective of Payment Terms Governance is to enable a right choice of payment terms for all documents created across any SAP system</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="4" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Eliminate any uncoordinated activities from purchasers.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/iqdis_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Avoid random payment terms selection and forget about chaotic actions.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Maintain payment terms in a centralized way.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/dms_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Track all purchasing documents without efforts after connecting Payment Terms Governance to your SAP systems.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ptg_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Define which payment terms work best for specific purchasing documents with the help of Payment Terms Governance.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_25.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        One application group provides multitasking functionality to its users
      </h2>
      <h4>Each of the four applications within the Payment Terms Governance group performs a set of functions, thus ensuring smooth payment terms management</h4>
    </div>
    <div class="borderd_four_col">
<div class="borderd_col">
  <img src="../../../assets/img/launch_32.png" class="img-fluid" alt="...">
<h4>Request Payment Terms</h4>
<ul class="ticklist">
  <li>The first step is creating central payment terms in the app</li>
  <li>A responsible person well aware of all organizational procedures makes a request for payment terms</li>
  <li>Payment terms are related to the procurement strategy</li>
  </ul>
</div>
<div class="borderd_col blue">
  <img src="../../../assets/img/ptg_32.png" class="img-fluid" alt="...">
<h4>Payment Terms Rules</h4>
<ul class="ticklist">
  <li>Set up payment terms regulations</li>
  <li>Specify criteria using purchasing attributes (purchasing organization, purchasing group, country, etc.)</li>
  <li>Restrict payment terms usage if necessary</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/ptg_33.png" class="img-fluid" alt="...">
  <h4>Monitor Payment Terms</h4>
  <ul class="ticklist">
    <li>Check purchasing document details against payment terms rules</li>
    <li>Choose a correct payment term for each document</li>
    <li>Consider ‘proposed’ payment terms as alternatives in case a payment term gains an ‘at risk’ status</li>
    </ul>
  </div>

  <div class="borderd_col">
    <img src="../../../assets/img/req_manage.png" class="img-fluid" alt="...">
    <h4>Manage Payment Terms Mapping</h4>
    <ul class="ticklist">
      <li>Map payment terms across the SAP systems</li>
      <li>Link all SAP systems together</li>
      </ul>
    </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Payment Terms Governance is about clear distribution of roles
          </h2>
          <h4>To enable better transparency of the process, Payment Terms Governance introduces three roles with certain areas of responsibility — Strategic Purchaser, Administrator, and Operational Purchaser</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_03"></use>
                </svg>
               </span>
               Strategic Purchasers
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_02"></use>
                </svg>
               </span>
               Administrators
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               Operational Purchasers
            </button>

          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Strategic Purchasers</h3>
              <ul class="ticklist">
                <li>Request new payment terms</li>
<li>Create rules of payment terms usage</li>
<li>Inform operational purchasers about the irrelevant usage of payment terms in purchasing documents</li>
<li>Update purchasing documents if there is a need</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3>Administrators</h3>
              <ul class="ticklist">
                <li>Create payment terms</li>
                <li>Map payment terms across all SAP systems within a company</li>
                </ul>
            </div>

            <div class="carousel-item">
              <h3>Operational Purchasers</h3>
              <ul class="ticklist">
                <li>Make different types of purchasing documents</li>
                <li>Use proper payment terms in the purchasing documents they produce</li>
                <li>Follow the rules already set in the application</li>
                <li>Verify the accuracy of purchasing documents</li>
                </ul>
            </div>
          </div>

        </div>
        </div>
      </div>
        </section>
