import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iqpwc',
  templateUrl: './iqpwc.component.html',
  styleUrls: ['./iqpwc.component.scss']
})
export class IqpwcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
