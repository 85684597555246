import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-knowledge',
  templateUrl: './enterprise-knowledge.component.html',
  styleUrls: ['./enterprise-knowledge.component.scss']
})
export class EnterpriseKnowledgeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
