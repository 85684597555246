<div class="innerHeader Prototyping">
  <div class="innerHeadings">
    <h1>
     Prototyping & 3D Modelling
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Prototyping_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Conceptualization
                </h2>
        </div>
       <p>
        JTSi offers product design conceptualization services to during the early design phase of a new product development, or during an upgrade of an existing product. Our product design process begins with a study of your concept, focusing on your goals for the product, your budget, and your production schedule. We then conduct a detailed market analysis and draft 2D concept renderings of your design. Using this information, we can create detailed 3D models of your product and, when required, a 3D printed conceptual prototype.
       </p>
        </div>
  </div>
</div>
</section>

<section class="grayBg">
  <div class="containers">
  <div class="topsection padd0 reverse">

    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Prototyping
                </h2>
        </div>
        <p>
          Our prototyping process is vital to our success, and it is one of our key competencies. Following your approval of the concept design, we will select preliminary manufacturing technologies and materials to develop your proof of concept. Utilizing design for manufacturing principles, we optimize your product and its manufacturing process to reduce costs and increase efficiency. During this phase, we will incorporate semi-functional prototypes for testing and refining your design. Throughout the entire process, our expert designers will help you make the best decisions and ensure your product gets to production with a design that design that is a perfect fit.
        </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/Prototyping_02.jpg" alt="">
      </div>
  </div>
</div>
</section>

<section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Prototyping_03.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Our Product Design Services Can Help You
                </h2>
        </div>
  <ul class="bulletpoints">
    <li>Get your product to market faster</li>
<li>Incorporate prototypes and tooling quickly for maximum testing and refinement</li>
<li>Utilize design for manufacturing to optimize your production</li>
<li>Choose the right technologies and materials for your project</li>
<li>Reduce complexity in your supply chain</li>
<li>Easily transition into manufacturing</li>
  </ul>
        </div>
  </div>
</div>
</section>

