<div class="breadcrumbs">
  <div class="containers">
  <ul>
  <li><a routerLink="/gsa518210c">Home</a></li>
  <li>Cloud Professional Service Rates</li>
</ul>
</div>
</div>
<div class="hero inner">
  <h1>
    Cloud Professional Services Rates
  </h1>
  </div>
  <section class="sections">
    <div class="containers">
      <div class="overflox">
      <div class="paginatorsearch">
      <mat-paginator [length]="100"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page">
</mat-paginator>
<div class="searchbox">
  <label for="">Search:</label>
  <input class="forminputs" type="text" name="" id="">
</div>
</div>
      <table mat-table [dataSource]="dataSource">

        <!-- Image Column -->
        <ng-container matColumnDef="proimage">
          <th mat-header-cell *matHeaderCellDef> Image </th>
          <td mat-cell *matCellDef="let element"> <img src="../../../assets/gsa/img/{{element.image}}" alt=""> </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Summary Column -->
        <ng-container matColumnDef="summary">
          <th mat-header-cell *matHeaderCellDef> Summary </th>
          <td mat-cell *matCellDef="let element"> <strong>{{element.title}}</strong> {{element.summary}} </td>
        </ng-container>

        <!-- price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Price </th>
          <td mat-cell *matCellDef="let element"> ${{element.price}} </td>
        </ng-container>

               <!-- price Column -->
               <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef> Quantity </th>
                <td mat-cell *matCellDef="let element">
                  <select class="forminputs" name="" id="">
                  <option value="1">1</option>
                </select> </td>
              </ng-container>

                       <!-- price Column -->
                       <ng-container matColumnDef="buy">
                        <th mat-header-cell *matHeaderCellDef> Buy </th>
                        <td mat-cell *matCellDef="let element">
                          <button class="cartbtn">
                          {{element.buy}}
                        </button>
                      </td>
                      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    </div>
  </section>

