
<div class="innerHeader KeyDifferentiators">
  <div class="innerHeadings">
    <h1>
      Key Differentiators
    </h1>
  </div>
  </div>
  <section class="commonPadding">
<app-thumbnails></app-thumbnails>
</section>

