<div class="innerHeader leverx_bmax">
  <div class="innerHeadings">
    <h1>
      BMAX
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Solve the age-old problem of manually creating Materials and BOMs in SAP
      </h2>
      <h4>
        The rules engine in BMAX automates the creation and extension of Material Master and BOMs in your operations. Once you define rules per governance, BMAX executes them with a single click
      </h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#inu"></use>
            </svg>
           </span>
           Rules Engine-Driven
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#datag"></use>
           </svg>
          </span>
          Enables Data Governance
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#exeFlow"></use>
            </svg>
           </span>
           Workflow Enabled
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#monitorgray"></use>
            </svg>
           </span>
           Intuitive User Interface
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/bmax_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bmax_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bmax_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/bmax_04.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How BMAX Helps
          </h2>
          <h4>
            LeverX BMAX ensures that data is set up completely and accurately the first time
          </h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 0"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="4" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="5" aria-label="Slide 5"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="6" aria-label="Slide 6"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="7" aria-label="Slide 7"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Increase speed of new product launches. Reduce the process of Material creation and extension from days to minutes.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    BMAX enables the mass creation of Materials from spreadsheets. This also supports data migration processes.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Data confidence yields better decisions. Eliminate human error at the outset of data creation.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/bmax_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Use the built-in basic workflow or leverage LeverX IPS to further streamline the process.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/iqdis_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Increased new product speed to market
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_27.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Data creation enabled by governance
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_26.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Right first time – reduced rework
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Data confidence – better decisions
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/velox_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Users That Would Benefit from BMAX
      </h2>
      <h4>Below is a sample list of users that would benefit from using BMAX</h4>
    </div>
    <div class="borderd_four_col lesstwo">
<div class="borderd_col blue">
  <img src="../../../assets/img/launch_31.png" class="img-fluid" alt="...">
<h4>Purchasing Operations</h4>
<ul class="ticklist">
  <li>Sales</li>
<li>Finance</li>
<li>Logistics</li>
<li>Warehouse Management</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/launch_32.png" class="img-fluid" alt="...">
  <h4>Data Operations</h4>
  <ul class="ticklist">
    <li>Engineering/R&amp;D</li>
<li>Quality and Operations</li>
<li>Master Data Governance</li>
<li>Scheduling </li>
<li>Planning</li>
    </ul>
  </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>BMAX offers the following key features and capabilities:</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               Lean Process Enablement
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               BMAX-R
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               BMAX-M
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="3" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               BMAX-B
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="4" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               BMAX-I
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="5" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               Auditability
            </button>

          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Lean Process Enablement</h3>
              <p>BMAX lets you automate data creation in a decentralized fashion, supporting the entry of the right data by the right people at the right time. Centralized data entry departments are a thing of the past.</p>

            </div>
            <div class="carousel-item">
              <h3>BMAX-R</h3>
              <p>The Rules Engine in BMAX automates the extension of Master Data and BOMs into your production and distribution operations. Build your company’s data governance policies right into the material data creation Rules Engine.</p>
            </div>

            <div class="carousel-item">
              <h3>BMAX-M</h3>
              <p>Easy to use, configurable screens make it easy for Users to enter their data using guided, roles-based input screens and fields. A built-in workflow process keeps the process moving.</p>
            </div>

            <div class="carousel-item">
              <h3>BMAX-B</h3>
              <p>Simplifies the extension of existing BOMs to one or more Plant entities, keeping the BOM structure and all data intact.</p>
            </div>

            <div class="carousel-item">
              <h3>BMAX-I</h3>
              <p>Provides the ability to upload mass amounts of Materials into SAP from Excel spreadsheets. This is especially helpful when migrating materials to a new plant, or when re-populating data for large numbers of materials if you change specific rules over time.</p>
            </div>

            <div class="carousel-item">
              <h3>Auditability</h3>
              <p>With each Material or BOM extension, you are provided a visual report showing the rules applied and the data created.</p>
            </div>

          </div>

        </div>
        </div>
      </div>
        </section>
