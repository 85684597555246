
<!-- <div class="breadcrumbs">
  <div class="containers">
  <ul>
  <li><a [routerLink]="['/gsa518210c']">Home</a></li>
  <li><a r[routerLink]="['/gsa518210c/rates']">Cloud Professional Service Rates</a></li>
  <li>SIN 518210C Cloud Senior DevSecOps Consultant</li>
</ul>
</div>
</div> -->
<section class="sections">
  <div class="containers">
    <div class="splitclmns topalign">
      <div class="clmn product">
        <article>
        <h3>
          47QTCA23D000J
        </h3>
        <ul>
          <li>SIN 518210C</li>
          <li>Cloud Senior DevSecOps</li>
          <li>Consultant</li>
        </ul>
        <img src="../../../assets/gsa/img/GSA-Schedule-Logo-white.png" alt="">
      </article>

      </div>
      <div class="clmn">
        <h2>
          SIN 518210C Cloud Senior DevSecOps Consultant
        </h2>
        <h4>
          $143.62
        </h4>
        <p>
         <strong>Cloud Senior DevSecOps Consultant on GSA Multiple Award Schedule (MAS) 47QTCA23D000J Small Disadvantaged Business SDVOSB IDIQ</strong>  is is knowledgeable of all available cloud and on premises technologies and can move rapidly from ideation to continuous iteration enabling the rapid delivery of capabilities. Assists and directs other cloud teams, ensuring that all technologies work effectively together to improve IT solution cloud performance, and processes change requests. Responsible for limiting redundancy in applications and technology designs. Has advanced level knowledge of cloud and Dev/Ops domains.
        </p>
        <div class="additem">
          <div class="inputgroup">
          <select class="forminputs" name="" id="">
            <option value="">
              1
            </option>
          </select>
          <button class="cartbtn">
            Add to cart
          </button>
        </div>
      </div>
        <button class="fulbtn">
          Click to Pay
        </button>
        <ul class="prodductnotes">
          <li><strong>SKU:</strong>  518210CCLDDEVSECOPSENGR</li>
          <li><strong>Categories: </strong> GSA Multiple Award Schedule 47QTCA23D000J, Washington DC</li>
          <li><strong>Tags: </strong> GSA Multiple Award Schedule, SDVOSB, Small Disadvantaged Business</li>
        </ul>

      </div>
    </div>
  </div>
</section>
