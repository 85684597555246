import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonfunctionsService {

  private openModalSource = new Subject<void>();
  openModal$ = this.openModalSource.asObservable();
 
  openModal() {
    this.openModalSource.next();
  }
  
  constructor(private http:HttpClient) { }
  validateEmail(email) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,::\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegExp.test(String(email).toLowerCase());
  }

  validatePhoneAndFax(number, countryCode) {
    let phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    switch (countryCode) {
      case 'us':
        {
          phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
          break;
        }
      case 'india':
        {
          phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[6789]\d{9}|(\d[ -]?){10}\d$/
          break;
        }
      case 'uk':
        {
          phoneRegExp = /^(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}$/
          break;
        }
    }
    let val=phoneRegExp.test(String(number));
    if (val == true) {
      let cleaned = ('' + number).replace(/\D/g, '');
      if (cleaned != null && cleaned.startsWith('91') || cleaned.startsWith('44')) {
        cleaned = cleaned.substring(2);
      }
      switch (countryCode) {
        case 'us':
          {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!match) {
              return false;
            }
          }
        case 'india':
          {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!match) {
              return false;
            }
          }
        case 'uk':
          {
            let match = cleaned.match(/^(\d{4})(\d{6})$/);
            if (!match) {
              return false;
            }
          }
      }
    }
    else {
      return false;
    }
    return true; 
  }
  validateWebsite(website){
    let websiteRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]$/
    return websiteRegExp.test(String(website).toLowerCase());
  }
  validatePhoneNumber(phone){
    let phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegExp.test(String(phone).toLowerCase());
  }

  sendEmail(fromAddr, subject, toaddress, emailbody, isFileAttach, cc, bcc, empId, files) {
    const emailApi = `${environment.boApiServer}${'SendEmail/sendGrid'}`;
    const fileDirectory = 'wwwroot/';
    const formData = new FormData();
    formData.append("fromEmail", fromAddr);
    formData.append('subject', (subject));
    formData.append('ToEmail', (toaddress));
    formData.append('emailBody', emailbody);
    formData.append('isFileAttach', isFileAttach);
    formData.append('ccEmail', cc);
    formData.append('bccEmail', bcc);
    formData.append('empId', empId);
    formData.append('fileDirectory', fileDirectory);
    formData.append('files', files);
     
    return this.http.post(emailApi, formData);
  }

  



}