<div class="innerHeader defence">
  <div class="innerHeadings">
    <h1>
      Defense Armament Engineering <br> Services
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/defence_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Defense Armament Engineering Services
                </h2>
        </div>
        <p>
          As a CMG member, JTSi has legal framework and contractual means to collaborate with government customers to provide innovative solutions; and as qualified defense Small business contractor we are on the mission of accelerating the deployment of technologies to the Warfighter.
        </p>
        </div>
  </div>
</div>
</section>

<section class="grayBg">
  <div class="containers">
  <div class="topsection padd0 reverse">

    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Engineering
                </h2>
        </div>
       <ul class="bulletpoints">
        <li>	Design</li>
        <li>	Strategic Suppliers</li>
        <li>	Technical Innovation</li>
        <li>	Armament Subject Matter Experts</li>
        <li>	Design for Manufacturing</li>

       </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/defence_02.jpg" alt="">
      </div>
  </div>
</div>
</section>

<section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/defence_03.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Project Management
                </h2>
        </div>
  <ul class="bulletpoints">
    <li>	Armament Subject Matter Experts</li>
    <li>	Integrated Master Scheduling</li>
    <li>	Product Lifecycle Support</li>
    <li>	Asset Management</li>
    <li>	Earned Value Management</li>
    <li>	Web Based Collaborative Program Integration</li>
    <li>	Financial Management</li>
    <li>	Property Management</li>
    <li>	Delivery Assurance</li>
    <li>	Project Management Utility Tools</li>
  </ul>
        </div>
  </div>
</div>
</section>

<section class="grayBg">
  <div class="containers">
  <div class="topsection padd0 reverse">

    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Qualified Military Supplier
                </h2>
        </div>
        <ul class="bulletpoints">
          <li>	Arms, Ammunition & Explosives (AA&E)</li>
<li>	Complex Engineered Parts</li>

        </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/defence_04.jpg" alt="">
      </div>
  </div>
</div>
</section>
