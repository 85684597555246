import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ptc-windchill',
  templateUrl: './ptc-windchill.component.html',
  styleUrls: ['./ptc-windchill.component.scss']
})
export class PTCWindchillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
