import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prototyping',
  templateUrl: './prototyping.component.html',
  styleUrls: ['./prototyping.component.scss']
})
export class PrototypingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
