// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 const API_SERVER="https://webapi.jtsusa.com/api/";
 //const BO_API_SERVER="https://localhost:44324/api/";

 //const API_SERVER="http://jtsiwebapi.gstiusa.com/api/";
 const BO_API_SERVER="https://ogapi.jtsiindia.com/api/";
 const APP_URL = 'https://jtsusa.com/';
 const BOAPP_URL = 'http://boqa.gstiusa.com/';

 export const environment = {
   production: false,
   apiServer:`${API_SERVER}`,
   boApiServer:`${BO_API_SERVER}`,
   appUrl: APP_URL,
   boappUrl: BOAPP_URL
 };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
