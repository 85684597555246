import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keydifferent',
  templateUrl: './keydifferent.component.html',
  styleUrls: ['./keydifferent.component.scss']
})
export class KeydifferentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
