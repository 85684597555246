<section class="commonPadding grayBg" id="sapservices">
    <div class="texttitle_two">
        <h2>SAP Services</h2>
    </div>
  <div class="containers homeGrid">
    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Cloud_Migration.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Cloud Migration & CSP
        </h4>
    <a [routerLink]="['/sap/cloud-migration-and-csp']" class="btn btn-md btn-primary">Detailed View</a>
      </div>

    </div>  <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Enterprise_Architecture.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Enterprise Architecture
    (EA)
        </h4>
    <a [routerLink]="['/capabilities/enterprise-architecture']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Mobility.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Mobility & IOT
        </h4>

    <a [routerLink]="['/sap/mobility-and-iot']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Application_Migration.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Application Migration &
          Maintenance
        </h4>

    <a [routerLink]="['/sap/application-migration-and-maintenance']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>

    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Successfactors.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          Successfactors Support
        </h4>
    <a [routerLink]="['/sap/successfactors-support']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>


    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/S4Support.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          S4/Hana Migration &
  Support
        </h4>
    <a [routerLink]="['/sap/s4hana-migration-and-support']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>


    <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SapServices/Hana_Migration.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          SAP Inventory Management (S4/EWM)
        </h4>
    <a [routerLink]="['/products-solutions/sap-inventory-management-s4-ewm']" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div>



  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/Migration_Strategy.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cloud Migration Strategy
  Development
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailesd View</a>
    </div>
  </div>
   -->


  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/MSP_Services.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Private/Public/Hybrid
  CSP / MSP Services
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/asap.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Activate/DevOps/ASAP
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->



  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/Cross_Platform.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cross Platform Migrations
  (SAP/MS/ORCL)
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->


  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/Modernization_Efforts.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        ABAP to Fiori/Java/UI5 –
  Development &
  Modernization Efforts
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SapServices/Barcode.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        RFID/Barcode (DOD)
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->


  </div>
  </section>
