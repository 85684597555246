import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c5-isr',
  templateUrl: './c5-isr.component.html',
  styleUrls: ['./c5-isr.component.scss']
})
export class C5ISRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
