<div class="gsafooter">
  <div class="footop">
    <div class="containers">
      <div class="splitclmns">
        <div class="footbox">
          <h3>
            Contact Details
          </h3>
<address>
<h4>
  Washington DC Metro
</h4>
<p>
11490 Commerce Park Dr.Suite 410
<br>
Reston, Virginia 20191
<br>
Phone: +1 (703) 949-9100
<br>
Email: info@jtsusa.com
<br>
</p>
</address>
<address>
  <h4>
    New Jersey
  </h4>
  <p>
370 Richard Mine Road
<br>
Wharton, New Jersey 07855
<br>
+1 (973) 328-8000
<br>
Email: info@jtsusa.com
  </p>
  </address>
</div>
<div class="footbox">
  <div class="mapouter"><div class="gmap_canvas"><iframe id="gmap_canvas" src="https://maps.google.com/maps?q=11490 Commerce Park Dr., 4th Floor, Rston, Virginia 20191&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><style>.gmap_canvas {overflow:hidden;background:none!important;}</style></div></div>
</div>
</div>
</div>
</div>
<div class="containers">
<div class="copyrights">
    <p>
      &#169; 2023 JTS USA, All Rights Reserved.
    </p>
    <ul class="footmedias">
      <li><a href="#">
        <svg>
          <use xlink:href="../../../assets/gsa/img/sprite.svg#facebook" />
        </svg>
      </a></li>
      <li><a href="#">
        <svg>
          <use xlink:href="../../../assets/gsa/img/sprite.svg#linkedin" />
        </svg>
      </a></li>
      <li><a href="#">
        <svg>
          <use xlink:href="../../../assets/gsa/img/sprite.svg#twitter" />
        </svg>
      </a></li>
      <li><a href="#">
        <svg>
          <use xlink:href="../../../assets/gsa/img/sprite.svg#instagram" />
        </svg>
      </a></li>
    </ul>
  </div>
</div>
</div>
