import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Contact } from './contact.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

 
  constructor(private http:HttpClient) { }
  formData:Contact=new Contact();
    
  postContactDetail(){
        return this.http.post(`${environment.apiServer}ContactDetail`,this.formData)
  }
}