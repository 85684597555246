<section class="commonPadding grayBg" id="products">
  <div class="texttitle_two">
      <h2>
        Products/Solutions
      </h2>
  </div>
<div class="containers homeGrid">

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/mro_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      MRO ERP
    </h4>
<a [routerLink]="['/products-solutions/mro-erp']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/sapinvetory_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      SAP Inventory Management (S4/EWM)
    </h4>
<a [routerLink]="['/products-solutions/sap-inventory-management-s4-ewm']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/surveyshield_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      SurveyShield (Web Services)
    </h4>
<a [routerLink]="['/products-solutions/surveyshield']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/ips_thumb.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Integrated Process Solution (IPS)
    </h4>
<a [routerLink]="['/products-solutions/integrated-process-solution']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<!-- <div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/velox_thumb01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      VeloX Software Suite
    </h4>
<a [routerLink]="['/products-solutions/VeloX-Software']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/IQ_DMS_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      IQ DMS
    </h4>
<a [routerLink]="['/products-solutions/IQ-DMS']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/product_work_center_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      IQ Product Work Center
    </h4>
<a [routerLink]="['/products-solutions/IQ-Product-Work-Center']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/IQ_launch_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      IQ Launch
    </h4>
<a [routerLink]="['/products-solutions/IQ-Launch']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/IQ_discussion_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      IQ Discussion
    </h4>
<a [routerLink]="['/products-solutions/IQ-Discussion']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/payment-_terms_governance_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Payment Terms Governance
    </h4>
<a [routerLink]="['/products-solutions/payment-terms-governance']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/BMAX_thumb01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      BOM / Material Master Extension & Allocation Tool (BMAX)
    </h4>
<a [routerLink]="['/products-solutions/bom-material-allocation-extension']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div> -->
</div>
</section>
