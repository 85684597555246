<div class="innerHeader vision">
  <div class="innerHeadings">
    <!-- <h1>
      Vision | Mission | Core Value
    </h1> -->
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
 <div class="missnonvission">
<img src="../../../assets/img/vision_01.jpg" alt="">
<div class="darkgrid">
<div class="darkgrid-box">
<h3>
  <span>
  <svg>
    <use xlink:href="../../assets/img/sprite.svg#visions"></use>
  </svg>
</span>
  Vision
</h3>
<p>
  JTSi - an innovative leader, providing information
technology and engineering services for the 21st century.
</p>
</div>
<div class="darkgrid-box">
  <h3>
    <span>
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#missions"></use>
    </svg>
  </span>
    Mission
  </h3>
  <p>
    To provide our customers innovative, cost-effective,
and timely solutions by preserving core-values.
  </p>
  </div>
</div>
 </div>
</div>
</section>


  <section class="mb6">
  <div class="corebox">
 <div class="boxtitle_two">
   <h2>
     Core Values
   </h2>
 </div>
 <div class="hilightstxt mb3">
  We embrace the highest moral and ethical standards in all aspects of our operations.
We will:
 </div>

 <div class="corelist">
  <img src="../../../assets/img/integrity.jpg" alt="">
  <div class="coretxt">
    <h3>Integrity</h3>
    <p>
      Be honest and transparent, maintaining strong moral principles in our
dealings with everyone
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/loyalty.jpg" alt="">
  <div class="coretxt">
    <h3>Loyalty</h3>
    <p>
      Deliver beyond expectations by faithfully adhering to all our
      commitments/obligations
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/honesty.jpg" alt="">
  <div class="coretxt">
    <h3>Honesty</h3>
    <p>
      Maintain openness, fairness and sincerity at all levels of our relationships
      and communications
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/trust.jpg" alt="">
  <div class="coretxt">
    <h3>Trust</h3>
    <p>
      Deliver solutions to our Customer that yield consistently dependable
high-quality business solution
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/accountability.jpg" alt="">
  <div class="coretxt">
    <h3>Accountability</h3>
    <p>
      Take ownership of the task and follow up until expectations are
met/exceeded to our Customer’s satisfaction
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/Partnership.jpg" alt="">
  <div class="coretxt">
    <h3>Partnerships</h3>
    <p>
      Foster partnership between employees and customers while maintaining
corporate commitments
    </p>
  </div>
 </div>

 <div class="corelist ">
  <img src="../../../assets/img/Teamwork.jpg" alt="">
  <div class="coretxt">
    <h3>Teamwork</h3>
    <p>
      Work collaboratively to achieve all goals through cooperative and
coordinated efforts at all levels
    </p>
  </div>
 </div>

  </div>
  </section>



