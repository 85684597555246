<div class="innerHeader factory">
  <div class="innerHeadings">
    <h1>
      Software Factory & COE
    </h1>
  </div>
  </div>

  <div class="topsection containers Bpadd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Factory_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JSTi Software AG COE
                </h2>
        </div>
        <p>
          JTSi provides unique expertise in supporting ARIS. Our team brings over
          12 years of experience in building, configuring, and managing ARIS
          applications on premise and in the cloud specifically in the DoD
          environment. Even though ARIS is a very popular EA tool, it is still a
          niche application and it is rare to find the skillsets required to support
          ARIS for large and complex environments such as Army and Navy that
          have very specific requirements for supportability and integration.

        </p>
        <p>
          We are a certified solutions partner with the ARIS vendor, Software AG,
and we' ve leveraged this partnership to build a JTSi COE (Center of Excellence) on the AWS cloud for ARIS. We are currently incubating
various POCs for practical use cases with our internal JTSi experts. We also have reach back capabilities with Software AG for expert help
to solve issues and tap into their expert pool and knowledge base as well. We will ensure this partnership will translate into cutting edge
solutions for the Army, as and when needed.

        </p>
        </div>
  </div>
  <div class="containers bottomFive">
    <p>
      JTSi supports the ARIS application with resources who have over 10 years of ARIS platform experience and certifications such as DoDAF
certification from the FEAC Institute. with the utilization of vendor compliant objects, connection types, and standard model viewpoints as defined by industry standards.

    </p>
    <p>
      JTSi also assists in the conversion of architecture from other architecture development tools by employing techniques, i.e. configuration of
Visio documents, ARIS to EKR ARIS, non ARIS to EKR ARIS.

    </p>
    <p>
      JTSi stands ready to address the portability of data to other tools and application that arise in the future, we also have the ability to write
custom API scripts to build interfaces between existing and new applications. Automating regularly scheduled data updates that occur
throughout the Army over the entire fiscal year could save a tremendous amount of money and time. Additionally, script testing and
development is performed within the ARIS product which can tailor the many hundred standard reports that the vendor feels covers a
plethora of architecture usages and statistics or create Army specific reports.
    </p>
      </div>
  <section class=" grayBg">
    <div class="containers">

      <div class="topsection reverse Tpadd0">
        <div class="texts">
            <div class="heading redBg">
                    <h2>
                      JTSi SAP COE
                    </h2>
            </div>
           <p>
            As a Center of Excellence, JTSi's SAP experts design and optimize new processes, manage complex implementation projects, provide user support, and keep the extensive landscape of SAP systems up and running 24/7. In addition, the COE significantly contributes to the success of the enterprise by optimizing the use of all SAP products in a cost-effective manner. Among its responsibilities are operations support, applications management and enhancements, infrastructure management and change management.
           </p>
           <h4 class="subtitles_three">
            Key functions and benefits of our SAP COE:
           </h4>
           <ul class="bulletpoints">
            <li>	A unique platform for creating global SAP solutions </li>
          <li>	Improvements in User Productivity </li>
          <li>	A standard vehicle for deployment of SAP best practices </li>
          <li>	Reduced Cost of SAP Operations (TCO) </li>
          <li>	A central point of contact for all SAP related matters </li>
          <li>	Improved retention of key SAP personnel</li>
          <li>	Development of strong competencies in all SAP areas </li>
          <li>	Improved Service Levels</li>
          <li>	Enhance the value of the SAP Solution More standardization and better integration</li>

          </ul>
            </div>
            <div class="picture">
              <img class="img-fluid" src="../../../assets/img/Factory_02.jpg" alt="">
          </div>
      </div>


      </div>
      </section>



