import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commonpages',
  templateUrl: './commonpages.component.html',
  styleUrls: ['./commonpages.component.scss']
})
export class CommonpagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
