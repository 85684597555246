import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser'


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private titleService: Title,
    private meta :Meta,
    
  ) {

    this.titleService.setTitle('JTS USA');
    //this.meta.addTag({name:'test meta name', content:'test meta content 01'});
    //this.meta.addTag({name:'keywords', content:'hello hello, again hello'});

   }

  //displayModal="none"
  ngOnInit(): void {

  //  const modal=document.getElementById('eventModal');
  



  //   window.addEventListener('load',()=>{

  //     if(modal){
  //       this.displayModal='block';
  //     }

  //   })

  //   window.addEventListener('click',(event)=>{
  //     if(event.target===modal){
  //       this.displayModal='none';

  //     }
  //   })

  //   document.getElementById("opened").click();
    }


   
   

  

}
