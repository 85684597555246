export class Talktous {
    ctD_ID:number=0;
    ctD_Name:string='';
    ctD_Email:string='';
    ctD_Phone:string='';
    ctD_Message:string='';
    ctD_Website:string='';
    ctD_FromPage:string='';
    countryCode:string='';
    CountryISO:string='';
    ctD_Ext:string='';
    CTD_BrochURL:string='';
}
