<div class="innerHeader Modernization">
  <div class="innerHeadings">
    <h1>
      Application Modernization
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="boxtitle_two">
    <h2>
      Cloud migration and cloud-native
<span>
application development

</span>
       </h2>
  </div>
  <div class="topsection Tpadd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Modernization_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Cloud migration services
                </h2>
        </div>
        <ul class="bulletpoints">
          <li>	Cloud advisory services </li>
<li>	Migrate workloads from on-premises to the cloud </li>
<li>	Application containerization services </li>
<li>	Application re-platform/remediation </li>
<li>	PaaS adoption </li>
<li>	Database migration services</li>

        </ul>
        </div>
  </div>
</div>
</section>
  <section class="grayBg">
    <div class="topsection containers padd0 reverse">
      <div class="texts">
          <div class="heading redBg">
                  <h2>
                    Cloud-native application development services
                  </h2>
          </div>
          <ul class="bulletpoints">
        <li>	Monolith to microservices transformation</li>
<li>	Serverless solutions</li>
<li>	Hybrid cloud solutions</li>
<li>	Cloud integration services</li>
<li>	DevSecOps transformation</li>
<li>	Modern cloud-native database adoption</li>

      </ul>
          </div>
          <div class="picture">
            <img class="img-fluid" src="../../../assets/img/Modernization_02.jpg" alt="">
        </div>
    </div>

      </section>


<section class="commonPadding">
  <div class="containers">
    <div class="boxtitle_two">
      <h2>
        JTSi application modernization
  <span>
  services include
  </span>
         </h2>
    </div>
    <div class="four-cols">

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern001"></use>
            </svg>
          </div>
            <h4>
              Portfolio Assessment &

              Planning
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern002"></use>
            </svg>
          </div>
            <h4>
              Application

Re-Architecture
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern003"></use>
            </svg>
          </div>
            <h4>
              Application

Re-Engineering
            </h4>

      </div>
      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern004"></use>
            </svg>
          </div>
            <h4>
              Application

              Migration & Re-Hosting
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern005"></use>
            </svg>
          </div>
            <h4>
              Data Migration
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern006"></use>
            </svg>
          </div>
            <h4>
              Database Migration
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern007"></use>
            </svg>
          </div>
            <h4>
              Application Integration
            </h4>

      </div>


  </div>
  </div>
</section>

<section class="commonPadding grayBg">
  <div class="containers">
    <div class="four-cols lessOne">

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern008"></use>
            </svg>
          </div>
            <h4>
              Agile DevOps

            </h4>

            <p>
              Agile & DevOps services help clients
achieve resilience and agility during
these tumultuous times. Our
Agile-DevOps practices along with top
tech collaboration tools ensures
seamless remote operations.
            </p>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern009"></use>
            </svg>
          </div>
            <h4>
              NextGen Technology Services
            </h4>
<p>
  Along with modernizing existing
applications, through low/no code
platforms we accelerate new
application development

Identify the right low/no code
platform and develop POCs tailored to
the enterprise needs to ensure higher
quality, lower cost and shorter time to
market
</p>
      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#modern010"></use>
            </svg>
          </div>
            <h4>
              Mainframe Modernization

            </h4>
<p>
  Enterprises face major set backs
running their critical workloads on
Mainframe systems, expensive
maintenance of Mainframe systems
and shortage of skilled workforce can
also lead to operational risks in future.
Please contact JTSi to discuss
Mainframe Modernization options.
</p>
      </div>

  </div>
  </div>
</section>
