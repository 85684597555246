import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ContactService } from 'src/app/shared/contact.service';
import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Contact } from 'src/app/shared/contact.model';
import{AsYouType} from "libphonenumber-js"

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {


  phoneValid=true;
  constructor(
    public service:ContactService,
    public commonfunctions:CommonfunctionsService,
    private toaster:ToastrService

  ) { }

  ngOnInit(): void {

  }
  onSubmit(frm:NgForm){
    if (frm.invalid) {
      return;
    }
    if(!this.commonfunctions.validateEmail(frm.value.email)){
      this.toaster.error("Email is not valid");
      return;
    }
    if(!this.phoneValid){
      this.toaster.error("Phone Number is not valid");
      return;
    }
  /*  if(!this.commonfunctions.validateWebsite(frm.value.website) && frm.value.website!=''&& frm.value.website!=null){
      this.toaster.error("Website is not valid");
      return;
    }*/
    frm.controls['phoneNumber'].setValue("+"+(frm.value.countryCode==""?"1":frm.value.countryCode)+frm.value.phoneNumber);
    console.log(frm.value);
    this.service.postContactDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.toaster.success("Submitted. Thank you","");
     },
      err=>{
        this.toaster.error("Failed to submit response","");
        console.log(err);
      }
    )
  }
  resetForm(frm:NgForm){
    //frm.resetForm();

    frm.controls['firstname'].reset();
    frm.controls['firstname'].setErrors(null);
    frm.controls['email'].reset();
    frm.controls['email'].setErrors(null);
    frm.controls['phoneNumber'].reset();
    frm.controls['phoneNumber'].setErrors(null);
    frm.controls['message'].reset();

    //this.service.formData=new Contact();
    //this.resetPhoneField(frm);
  }
  resetPhoneField(frm:NgForm){
    var countryList  = document.getElementsByClassName("iti__country iti__standard iti__highlight iti__active");
    if(countryList!=null && countryList.item(0)!=null){
      countryList.item(0).removeAttribute("aria-selected");
      countryList.item(0).setAttribute("class","iti__country iti__standard");
     }

    var countryListPRef  = document.getElementsByClassName("iti__country iti__preferred iti__highlight iti__active");
    if(countryListPRef!=null && countryList.item(0)!=null){
      countryListPRef.item(0).removeAttribute("aria-selected");
      countryListPRef.item(0).setAttribute("class","iti__country iti__preferred");
     }


    var countryListUS = document.getElementById("iti-item-us");
    if(countryListUS!=null){
      countryListUS.className='iti__country iti__preferred iti__highlight iti__active';
      countryListUS.setAttribute("aria-selected","true");
    }

    var countryListSelected = document.getElementsByClassName("iti__selected-flag");
    if(countryListSelected!=null){
      countryListSelected.item(0).setAttribute("title","United States: +1");
      countryListSelected.item(0).setAttribute("aria-activedescendant","iti-item-us");
      countryListSelected.item(0).firstElementChild.className ='iti__flag iti__us';
    }

    frm.controls['countryCode'].setValue("1");
    frm.controls['countryISO'].setValue("US");
  }
  hasError(event:any,frm:NgForm){
    this.phoneValid=true;
    if(!event && frm.value.phoneNumber!="")
    {
      this.phoneValid=false;
    }
  }
  onContryChange(event:any,frm:NgForm){
    frm.controls['countryCode'].setValue(event.dialCode);
    frm.controls['countryISO'].setValue(event.iso2);

  }
  formatNumber(event:any,frm:NgForm){
    var ciso = frm.value.countryISO.toString();
    ciso=ciso==""?"us":ciso;
    frm.controls['phoneNumber'].setValue(new AsYouType(ciso.toUpperCase()).input(frm.value.phoneNumber));
    //console.log(new AsYouType(ciso.toUpperCase()).input(frm.value.phoneNumber));

  }
  validateEmail(event: any, frm: NgForm ){
    if (frm.value.email!='' && !this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      document.getElementById("contactEmail").focus();
      return;
    }
  }

}
