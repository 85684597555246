

  <div class="containers">
    <div class="keyDefference" id="keyOne">
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_001"></use>
          </svg>
        </div>
        <h3>
          DCAA Approved
        </h3>
          <p>
            JTSi accounting systems are DCAA approved, we have systems and procedures in place to comply with the DCAA audit.
          </p>
      </div>
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_002"></use>
          </svg>
        </div>
        <h3>
          CRADA
        </h3>
         <p>
          JTSI has CRADAs with U.S. Army. CRADAs provides JTSi an easy way to collaborate with the U.S. Army Combat Capabilities Development Command (DEVCOM) Army Research Laboratory.
         </p>
      </div>
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_003"></use>
          </svg>
        </div>
        <h3>
          ISO 9001:2015*
        </h3>
        <p>
          This standard demonstrates our ability to consistently provide products and services that meet customer and regulatory requirements.
        </p>
      </div>
      </div>

      <div class="keyDefference" id="keyTwo">
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_004"></use>
          </svg>
        </div>
        <h3>
          SAP Partner
        </h3>
          <p>
            JTSi is SAP Partneredge firm, JTSi has been involved with SAP since early 2000 and over the years have been successfully implementing and sustaining SAP applications for the Federal government as SAP Partner firm.
          </p>
      </div>
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_005"></use>
          </svg>
        </div>
        <h3>
          ITAR Compliant
        </h3>
          <p>
            JTSI is ITAR Compliant , we are registered with the State Department’s Directorate of Defense Trade Controls (DDTC), educated and trained in ITAR regulations.
          </p>
      </div>
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_006"></use>
          </svg>
        </div>
        <h3>
          FSO
        </h3>
        <p>
          JTSi has inhouse Facility Security Officer ensuring a secure environment for employees, vendors, and company visitors.
        </p>
      </div>
</div>

<div class="keyDefference" id="keyThree">
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_012"></use>
          </svg>
        </div>
        <h3>
          DOTC Member
        </h3>
          <p>
            JTSi is a member of  Department of Defense Ordnance Technology Consortium (DOTC).  JTSi conducts design, development and demonstrate prototypes leading to technology innovations in the field of armaments in cooperation with DOTC.
          </p>
      </div>

      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_008"></use>
          </svg>
        </div>
        <h3>
          GSA MAS 518210c
        </h3>
          <p>
            JTSi is a GSA MAS (Multiple Award Schedule) contractor. Buying IT from GSA’s Multiple Award Schedule is easy, because the work is done for you. MAS IT has streamlined procurement options for a full scope of IT supplies and services.
          </p>
      </div>
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_010"></use>
          </svg>
        </div>
        <h3>
          CMMI DEV | ML3 APPRAISED
        </h3>
         <p>
          This certification is an assurance for our customers to receive best-in-class quality, from the first project briefing to the project's delivery. All of our software development processes are well defined and followed. <strong style="color: #333;">Exp.Aug 26, 2025</strong>
         </p>
      </div>
</div>

<div class="keyDefference" id="KeyFour">
      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_009"></use>
          </svg>
        </div>
        <h3>
          C5 OTA Member
        </h3>
          <p>
            JTSi is a member of the C5 consortium. JTSi works alongside C5 to accelerate the development and deployment of new capabilities to the warfighter through the use of Other Transaction Agreements (OTAs).
          </p>
      </div>

      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_011"></use>
          </svg>
        </div>
        <h3>
          Microsoft Azure Cloud Hosting Partner
        </h3>
          <p>
            JTSi is a Microsoft Azure Cloud Hosting Partner for DOD.  Under this partnership JTSi is authorized to offer SAP Cloud offerings under FEDRAMP environment.
          </p>
      </div>

      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_013"></use>
          </svg>
        </div>
        <h3>
          NIST SP800-171 compliant
        </h3>
          <p>
            JTSi is in full compliance with NIST SP800 – 171 cybersecurity standards with focus on the protection of Controlled Unclassified Information (CUI) and seeks to ensure that such sensitive government information located on our networks is both secure and protected.
          </p>
      </div>
      </div>

      <div class="keyDefference" id="keyFive">

      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_014"></use>
          </svg>
        </div>
        <h3>
          ISO/IEC 20000-1:2018
        </h3>
          <p>
            JTSi is ISO/IEC 20000 – 1:2018 a service management system (SMS) standard, JTSi conforms to requirements to plan, establish, implement, operate, monitor, review, maintain and improve a SMS.
          </p>
      </div>

      <div class="keybox">
        <div class="keyicon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#key_015"></use>
          </svg>
        </div>
        <h3>
          ISO/IEC 27001:2013
        </h3>
        <p>
          JTSi meets the ISO/IEC 27001:2013 standards and adheres to the requirements for establishing, implementing, maintaining, and continually improving our organization’s information security policies and procedures.
        </p>
      </div>

    </div>
  </div>

