<section class="commonPadding" id="warsupport">
    <div class="texttitle_two">
        <h2>War Fighter Support Services
        </h2>
    </div>
  <div class="containers homeGrid">

    <!-- <div class="homeGrid-box">
      <div class="pictures">
        <img src="../../assets/img/SupportServices/US_Army_Cooperative.jpg" alt="">
      </div>
      <div class="overlays">
        <h4>
          US Army Cooperative R&D Agreement
        </h4>
    <a href="#" class="btn btn-md btn-primary">Detailed View</a>
      </div>
    </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/ITAR_Arms.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        ITAR Arms/Ammo & Explosives
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Systems_Engineering.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Systems Engineering
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailesd View</a>
    </div>
  </div> -->

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/US_Army_Cooperative.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        C5ISR
      </h4>
  <a [routerLink]="['/war/c5isr-solution']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Hardware_Integration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Hardware Integration & Embedded Software
      </h4>
  <a [routerLink]="['/war/hardware-integration-and-embedded-software']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Embedded_Software.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Embedded Software
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Support_Services.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Support Services
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Technical_Writing .jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Technical Writing
      </h4>

  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Systems_Development.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Systems Development
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Management_Support.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Project/Program
Management Support
(PMO)
      </h4>

  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <!-- <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Engineering_Analysis.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Engineering and Analysis
      </h4>
  <a href="#" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div> -->

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Prototyping.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Prototyping & 3D
Modelling
      </h4>
  <a [routerLink]="['/war/prototyping-and-3d-modelling']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Defense_Armament.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Defense Armament
Engineering Services
      </h4>
  <a [routerLink]="['/war/defense-armament-engineering-services']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/SupportServices/Management_Support.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        PTC - Windchill & IPS Integration
      </h4>
  <a [routerLink]="['/war/ptc-windchill-and-ips-integration']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>


  </div>
  </section>
