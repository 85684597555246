<div class="innerHeader leverx_iqdms">
  <div class="innerHeadings">
    <h1>
      IQ DMS
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        SAP DMS Made Simple
      </h2>
      <h4>IQ DMS provides an intuitive interface that enables users to easily manage documents from any device. It extends SAP DMS capabilities, including bookmarks, subscriptions, and guided search.</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#inu"></use>
            </svg>
           </span>
           Intuitive User Interface
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#access_doc"></use>
           </svg>
          </span>
          Access Documents from Any Device
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#sd_search"></use>
            </svg>
           </span>
           Simplified Document Search
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#bookmsub"></use>
            </svg>
           </span>
           Bookmarks and Subscription
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/dms_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/dms_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/velox_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/dms_03.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How IQ DMS Helps You Improve User Productivity
          </h2>
          <h4>IQ DMS improves the experience and productivity of SAP DMS users, saves their valuable time, and provides the following benefits:</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Allows users to access documents from any device and location.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/dms_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Simple, intuitive, and easy to use. Limited training required.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/dms_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Eliminates requirements for Java applets, certificates, and whitelists.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/dms_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Users That Would Benefit from IQ DMS
      </h2>
      <h4>Below is a sample list of users that would benefit from using IQ DMS</h4>
    </div>
    <div class="borderd_four_col lesstwo">
<div class="borderd_col blue">
  <img src="../../../assets/img/dms_31.png" class="img-fluid" alt="...">

<ul class="ticklist">
  <li>Consumers of Documents</li>
<li>Roles that need to retrieve documents to perform specific job functions </li>
<li>Users that need to keep track of document changes</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/dms_32.png" class="img-fluid" alt="...">

  <ul class="ticklist">
    <li>Casual users that log into SAP infrequently</li>
<li>Mobile device users</li>
<li>Individuals that need to collect documents for regulatory compliance reporting</li>
    </ul>
  </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>IQ DMS offers the following key features and capabilities that expand on standard SAP DMS</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#diamond"></use>
                </svg>
               </span>
               Usability
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#diamond"></use>
                </svg>
               </span>
               Document management
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2"  aria-label="Slide 3">
             <span>
              <svg>
                <use xlink:href="../../assets/img/sprite.svg#diamond"></use>
              </svg>
             </span>
             Access to documents
            </button>


          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Usability</h3>
              <ul class="ticklist">
                <li>Simple and quick display access to documents stored in SAP.</li>
<li>UI is self-explanatory; users are up and running in minutes.</li>
<li>Allows for the SAP Easy DMS/ECTR folder or attribute-based navigation and browsing.</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3> Document management</h3>
              <ul class="ticklist">
                <li>Simplified Search and/or Advanced Search.</li>
<li>Users only see the documents they have access to based on authorizations.</li>
<li>Access original files directly from the search results for faster viewing.</li>
<li>No local software install required.</li>
<li>Works on any device: Mobile, Tablet, Windows, MacOS, Linux, etc.</li>
                </ul>
            </div>
            <div class="carousel-item">
              <h3>ccess to documents</h3>
              <ul class="ticklist">
                <li>Simplified Search and/or Advanced Search.</li>
                <li>Users only see the documents they have access to based on authorizations.</li>
                <li>Access original files directly from the search results for faster viewing.</li>
                <li>No local software install required.</li>
                <li>Works on any device: Mobile, Tablet, Windows, MacOS, Linux, etc.</li>
                </ul>
            </div>

          </div>

        </div>
        </div>
      </div>
        </section>
