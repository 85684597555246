<div class="innerHeader leverx_ips">
  <div class="innerHeadings">
    <h1>
      Integrated Process Solution
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Powerful Process Automation and Workflow Management in SAP
      </h2>
      <h4>The simplest way to build and manage workflows in SAP</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
         <span>
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#dbBolcks"></use>
          </svg>
         </span>
         Define Building Blocks
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1" aria-label="Slide 2">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#abBolcks"></use>
            </svg>
           </span>
          Assemble Building Blocks
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#exeFlow"></use>
            </svg>
           </span>
          Execute Workflow
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#measureYou"></use>
            </svg>
           </span>
           Measure Your Performance
        </button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/ips_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ips_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ips_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/ips_04.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How IPS Helps You Improve ‘Time to Complete’
          </h2>
          <h4>The IPS Workflow Add-On by JTSi for SAP Allows Business Users To Build, Modify, And Deploy A Wide Variety Of Workflows In SAP While Offering The Following Benefits:</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="4" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="5" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="6" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="7" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Easily build a workflow in a few hours, and launch in no time</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Workflows in SAP can be built without the expertise of highly specialized technical staff</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Leverage your key business platform with a single solution for managing business processes</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Continually improve your processes by measuring your performance</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Easily adapt workflows to evolving processes on-the-fly</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_25.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Compliance and regulatory requirements can be met through standard procedures</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_26.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Quickly and effectively train new users on routine processes</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_27.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Maximize productivity and efficiency</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_28.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Sample Processes Where You Can Use IPS
      </h2>
      <h4>Below is a small list of business processes where you could utilize IPS.</h4>
    </div>
    <div class="borderd_four_col">
<div class="borderd_col blue">
  <img src="../../../assets/img/doc_manage.png" class="img-fluid" alt="...">
<h4>Document Management</h4>
<ul class="ticklist">
  <li>Document Release</li>
  <li>Material Master Release and Extension</li>
  <li>Sales Order Release</li>
  <li>Manufacturing Instruction Release</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/req_manage.png" class="img-fluid" alt="...">
  <h4>Request Management</h4>
  <ul class="ticklist">
    <li>Procurement Request</li>
    <li>Tooling Change Request</li>
    <li>Shop Floor Issue Request</li>
    <li>Customer Issue Request</li>
    <li>Plant Maintenance Work Request</li>
    <li>Request for Quote</li>
    </ul>
  </div>
  <div class="borderd_col">
    <img src="../../../assets/img/man_contol.png" class="img-fluid" alt="...">
    <h4>Manufacturing Process Control</h4>
    <ul class="ticklist">
      <li>Manufacturing Change</li>
<li>Manufacturing Deviation</li>
<li>Quality Issue Management</li>
<li>Engineering Change Management</li>
<li>Equipment Master Creation and Release</li>
<li>Service Request and Approval</li>
<li>Pre-Production Process Runs</li>
      </ul>
    </div>
    <div class="borderd_col">
      <img src="../../../assets/img/adm_control.png" class="img-fluid" alt="...">
      <h4>Administrative Process Control</h4>
      <ul class="ticklist">
        <li>Authorization for Expenditure</li>
        <li>Vendor Setup and Approval</li>
        <li>HR Hire-to-Retire Processes</li>
        </ul>
      </div>
    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Capabilities
          </h2>
          <h4>IPS offers all of the features you would find in a best-in-breed workflow tool.</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
             <span>
              <svg>
                <use xlink:href="../../assets/img/sprite.svg#prokey_01"></use>
              </svg>
             </span>
             Process automation
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_02"></use>
                </svg>
               </span>
               Remote access
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_03"></use>
                </svg>
               </span>
               Smooth integration with SAP
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="3" aria-label="Slide 4">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_04"></use>
                </svg>
               </span>
               Process visualization
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="4" aria-label="Slide 5">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               Flexible adjustments
            </button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Process automation</h3>
              <ul class="ticklist">
                <li>With decision-based actions, serial, parallel, and conditional workflows are supported</li>
<li>Workflow and agent selection that are intelligent and based on key attributes</li>
<li>Provide support for digital signatures, including compliance with 21 CFR Part 11 requirements</li>

                </ul>

            </div>
            <div class="carousel-item">
              <h3>Remote access</h3>
              <ul class="ticklist">
                <li>A drag-and-drop builder lets you model complex processes</li>
<li>Workflow can be reworked and sent back to an earlier point in the process</li>
<li>As part of the workflow, SAP actions are completed in the background, meaning fewer manual tasks</li>

                </ul>
            </div>
            <div class="carousel-item">
              <h3>Smooth integration with SAP </h3>
              <ul class="ticklist">
                <li>Visual Workflows make it easy to see where you are in the process</li>
<li>Workflow items can be executed directly from emails</li>
<li>Integrate many different SAP objects into a single workflow</li>

                </ul>
            </div>
            <div class="carousel-item">
              <h3>Process visualization</h3>
              <ul class="ticklist">
                <li>Overdue tasks can be escalated via email</li>
                <li>Tracking comments and decision history is easy</li>
                <li>Email notifications can be customized easily and can be accompanied by a generated PDF report of key process information</li>
                </ul>
            </div>
            <div class="carousel-item">
              <h3>Flexible adjustments</h3>
              <ul class="ticklist">
                <li>Provide flexibility for ad hoc tasks and adjusting workflow during execution</li>
<li>Agents and tasks can easily be adjusted if participants are out of the office</li>
<li>Fully compatible with standard SAP Workflow, including the creation of standard workflow items and executing tasks from standard SAP inboxes</li>

              </ul>
            </div>
          </div>

        </div>
        </div>
      </div>
        </section>
