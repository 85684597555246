<div class="innerHeader leverx_pwc">
  <div class="innerHeadings">
    <h1>
      IQ Product Work Center
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        IQ-PWC consolidates key product data into a simplified control center, saving users' valuable time on analyzing a pending change event
      </h2>
      <h4>IQ Product Work Center aggregates 30+ different SAP transactions to present the data you need within a simple-to-navigate cockpit.</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#bookmsub"></use>
            </svg>
           </span>
           Single Data Control Center
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#sd_search"></use>
           </svg>
          </span>
          Simple Navigation Through Your Data
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#access_doc"></use>
            </svg>
           </span>
           Enable Seamless Collaboration
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#inu"></use>
            </svg>
           </span>
           Support All Decision Makers
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/pwc_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/pwc_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/pwc_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/pwc_04.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How IQ PWC Enables Better Change Management
          </h2>
          <h4>IQ Product Work Center presents all data necessary to better analyze when to implement product design changes in order to minimize the impact on business</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 4"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Supports Business Users in the areas of Development, Supply Chain, Operations, Sales, Customer Service, etc.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Ensures Decision Makers see all critical data related to product changes.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Accurately determine how changes will affect critical items including open Purchase, Work, and Sales Orders as well as current inventories.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Navigate from one “Focus Item” object to other affected objects as needed to understand a full impact of changes.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/pwc_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Business Areas That Would Benefit from IQ PWC
      </h2>
      <h4>Below is a sample list of the business areas that would benefit from using IQ PWC</h4>
    </div>
    <div class="borderd_four_col lesstwo">
<div class="borderd_col blue">
  <img src="../../../assets/img/pwc_31.png" class="img-fluid" alt="...">
<h4>Management and Manufacturing</h4>
<ul class="ticklist">
  <li>Project Teams</li>
  <li>Product Management</li>
  <li>Manufacturing Operations</li>
  <li>Supply Chain</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/pwc_32.png" class="img-fluid" alt="...">
  <h4>Organized Processes</h4>
  <ul class="ticklist">
    <li>Engineering </li>
    <li>Scheduling</li>
    <li>Finance</li>
    <li>Logistics </li>
    </ul>
  </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>IQ PWC offers the following key features and capabilities to support the product change process</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#diamond"></use>
                </svg>
               </span>
               Extended usability
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#spwf"></use>
                </svg>
               </span>
               Simplified workflow and collaboration
            </button>

          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Extended usability</h3>
              <ul class="ticklist">
                <li>SAP GUI and/or Fiori UI to enable User preference for viewing data</li>
                <li>Simple navigation through BOM- and Material-related data</li>
                <li>Drag-n-Drop data from the “Focus Item” panel into Folders as desired</li>
                <li>Quickly access recently created items</li>
                <li>Simple “Left Panel / Right Panel” viewing of “Focus Item” with all related data</li>
                <li>Visual navigation through light-weight CAD assembly neutral files</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3>Simplified workflow and collaboration</h3>
              <ul class="ticklist">
                <li>Create and share Folders to collect data for frequent use and collaboration </li>
                <li>Visual indicators to highlight Change Status of Materials and BOM’s </li>
                <li>Access workflow tasks from a simplified Inbox </li>
                <li>Integrated with IQ Discussion to extend collaboration as desired </li>
                <li>Simplified where used, including single-, multi-, and top-level</li>
                <li>Configurable “right click” function to access preferred transactions based on the object selected</li>
                </ul>
            </div>


          </div>

        </div>
        </div>
      </div>
        </section>
