import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment.prod';
import { Feedback } from './feedback.model';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }
  formData: Feedback = new Feedback();
  postFeedbackDetail() {
    return this.http.post(`${environment.apiServer}FeedbackDetail`, this.formData)
  }
}
