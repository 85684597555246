<footer>
  <div class="footerlinks">
    <div class="contacts">
      <a [routerLink]="['']"><img class="footlogo" src="../../assets/img/JTSi-Logo.svg" alt=""></a>
      <p>
        <strong> Johnson Technology Systems Inc.</strong>
        <br>
        11490 Commerce Park Dr., 4th Floor,
        <br>
        Reston, Virginia 20191
        <br>
        Washington DC Tel: <a href="tel:+17039499100">703.949.9100</a>
        <br>
        New Jersey Tel: <a href="tel:+19733288000">973.328.8000</a>
        <br>
        Support: <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
      </p>

      <h3>Social With Us <div class="social">
          <a target="_blank" href="#">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#faseBuk"></use>
            </svg>
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/johnson-technology-systems-inc/">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#Lenkden"></use>
            </svg>
          </a>
          <a target="_blank" href="#">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#Tweetter"></use>
            </svg>
          </a>
          <a target="_blank" href="#">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#insta"></use>
            </svg>
          </a>
        </div>
      </h3>


    </div>

    <div class="quicklinks">
      <h4>Quick Links</h4>
      <hr>
      <ul class="footlist">
        <li><a [routerLink]="['/partnerships-relationships']">Partnership</a></li>
        <li><a id="aPastPref" data-bs-toggle="modal" data-bs-target="#cloudBrochure"> Past Performance </a></li>
        <li><a [routerLink]="['/careers']">Careers</a></li>
        <li><a [routerLink]="['/corporate-overview']"> Corporate Overview </a></li>
        <li><a [routerLink]="['/news']"> News</a></li>
        <li><a [routerLink]="['/privacy-policy']"> Privacy Policy </a></li>
        <li><a [routerLink]="['/disclaimer']"> Disclaimer</a></li>
        <li><a href="#" data-bs-toggle="modal" data-bs-target="#lookAround">Click here to report an error</a></li>
      </ul>
    </div>
    <div class="partnersgrid">

      <div class="awardpoints">
        <ul>
          <li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	DCAA Approved</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	CRADA</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	ISO 9001:2015*</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	SAP Partner</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	ITAR Compliant</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	FSO</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyFive">	ISO 27001:2013*</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyThree">	GSA MAS</a> </li>
<!-- <li><a [routerLink]="['/key-differentiators']" fragment="keyThree">	GSA SIN 518210 Cloud</a> </li> -->
<li><a [routerLink]="['/key-differentiators']" fragment="keyFive">ISO/IEC 20000-1:2018 </a></li>
        </ul>
      </div>
     <div class="footkeylogos">
<div class="keyimgs rowone">
  <a [routerLink]="['/key-differentiators']" fragment="KeyFour">
  <img src="../../assets/img/footlogos/logo1.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="keyThree">
  <img src="../../assets/img/footlogos/logo2.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="keyOne">
  <img src="../../assets/img/footlogos/logo3.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="keyFive">
  <img src="../../assets/img/footlogos/logo4.jpg" alt="">
  </a>
</div>
<div class="keyimgs rowTwo">
  <div class="hosplit">
  <a [routerLink]="['/key-differentiators']" fragment="keyTwo">
  <img src="../../assets/img/footlogos/logo5.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="keyTwo">
  <img src="../../assets/img/footlogos/logo6.jpg" alt="">
  </a>
</div>
<div class="vesplit">
<a [routerLink]="['/key-differentiators']" fragment="keyTwo">
  <img src="../../assets/img/Footer-logo-2.png" alt="">
  </a>
</div>
</div>
<div class="keyimgs rowThree">
<a [routerLink]="['/key-differentiators']" fragment="keyThree">
  <img src="../../assets/img/footlogos/logo9.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="keyThree">
  <img src="../../assets/img/footlogos/logo7.jpg" alt="">
</a>
<a [routerLink]="['/key-differentiators']" fragment="KeyFour">
  <img src="../../assets/img/footlogos/logo8.jpg" alt="">
  </a>
</div>
     </div>
    </div>

  </div>
  <div class="copyrights">

    <p>
      &copy; <a [routerLink]="['']"> 2022 JTSi USA</a> All Rights Reserved.
    </p>
  </div>
</footer>


<button class="totopbtn">
  <svg>
    <use xlink:href="../../assets/img/sprite.svg#scrolltop"></use>
  </svg>
</button>

<div class="chatbtn" data-bs-toggle="modal" data-bs-target="#letstalk">
  <svg>
    <use xlink:href="../../assets/img/sprite.svg#chatbubble"></use>
  </svg>
</div>

<!-- Modal for let us talk-->
<div class="modal fade" id="letstalk" tabindex="-1" aria-labelledby="letstalkLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Let’s get to know</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeletstalk></button>
      </div>
      <div class="modal-body">
        <div class="ltsTalk">
          <div class="filltheForm">
            <p>There is an greater chance we might be able to help you out. Please submit the form below:</p>

            <form class="form" novalidate #talktous="ngForm" (ngSubmit)="talktous.form.valid && onSubmit(talktous)">
              <div class="formGroup">
                <label>Full Name <span>*</span></label>
                <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
                <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel"
                  [(ngModel)]="ctservice.formData.ctD_FromPage">
                <input type="text" name="firstname" #ctD_Name="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Name"
                  [ngClass]="{'is-invalid': talktous.submitted &&  ctD_Name.invalid}" required maxlength="50">
              </div>
              <div class="formGroup">
                <label>Email <span>*</span></label>
                <input type="email" id="talktousEmail" name="email" #ctD_Email="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Email"
                  (blur)="validateEmail($event,talktous,'talktousEmail')"
                  [ngClass]="{'is-invalid': talktous.submitted &&  ctD_Email.invalid}" required maxlength="100">
              </div>
              <div class="phNumbers">
                <div class="formGroup">
                  <label>Phone Number</label>
                  <input type="text" ng2TelInput name="phoneNumber" #ctD_Phone="ngModel" class="form-control"
                    [(ngModel)]="ctservice.formData.ctD_Phone"
                    [ngClass]="{'is-invalid': talktous.submitted &&  ctD_Phone.invalid}" required maxlength="20"
                    (hasError)="hasError($event,talktous)" (keyup)="formatNumber($event,talktous)"
                    [ng2TelInputOptions]="{autoPlaceholder:'false'}" (countryChange)="onContryChange($event,talktous)">
                  <input type="hidden" name="countryCode" #CountryCode="ngModel"
                    [(ngModel)]="ctservice.formData.countryCode" value="+1">
                  <input type="hidden" name="countryISO" #CountryISO="ngModel"
                    [(ngModel)]="ctservice.formData.CountryISO" value="us">
                </div>
                <div class="formGroup">
                  <label>Extn</label>
                  <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                    class="form-control" maxlength="5">
                </div>
              </div>

              <div class="formGroup">
                <label>Your Message</label>
                <textarea class="msgarea" cols="4" rows="4" name="message" #ctD_Message="ngModel"
                  [(ngModel)]="ctservice.formData.ctD_Message" maxlength="3000"
                  placeholder="Type Your Message Here"></textarea>
              </div>

              <button class="submitBrochure" type="submit">Submit Here</button>
              <!--input type="submit" value="Submit Here" class="submitBrochure" form="talktous" -->
            </form>
          </div>
          <div class="addresses">
            <div class="contacts">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#call"></use>
                </svg>
              </span>
              <a href="tel:+17039499100"> +1 (703)949-9100</a>
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#mailus"></use>
                </svg>
              </span>
              <a href="mailto:jtsiusa.com">info@jtsusa.com </a>
            </div>
            <h4> Washington DC Metro</h4>
            <ul>
              <li>
                11490 Commerce Park Dr., 4th Floor,<br> Virginia 20191
              </li>
              <li>
                Tel: <a href="tel:+17039499100">+1 (703)949-9100</a> | Fax +1 (201)624-7671
              </li>
              <li>
                E-mail: <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
              </li>
            </ul>

            <h4> New Jersey</h4>
            <ul>
              <li>
                370 Richard Mine Road, Suite#100
                <br>
                Wharton – NJ – 0785-1836

              </li>
              <li>
                Tel: <a href="tel:+19733288000">+1 (973)328-8000</a> | Fax +1 (201)624-7671
              </li>
              <li>
                E-mail: <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
              </li>
            </ul>

            <h4>Other ways to contact us</h4>
            <ul>
              <li><a data-bs-dismiss="modal" [routerLink]="['contact-us']">Find an office </a></li>
              <li><a data-bs-dismiss="modal" [routerLink]="['careers']">Search jobs and submit your resume
                </a></li>
              <li class="findUs">
                <a target="_blank" href="#">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#faseBuk"></use>
                  </svg>
                </a>
                <a target="_blank" href="#">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#Lenkden"></use>
                  </svg>
                </a>
                <a target="_blank" href="#">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#Tweetter"></use>
                  </svg>
                </a>
                <a target="_blank" href="#">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#insta"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal for feedback-->
<div class="modal fade" id="lookAround" tabindex="-1" aria-labelledby="lookAroundLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Please provide your feedback</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  #closeFeedback></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #feedback="ngForm"
          (ngSubmit)="feedback.form.valid && onFeedbackSubmit(feedback )">

          <div class="sitErrors">
            <!-- <label for="siterrors" class="form-label">Please provide your feedback</label> -->
            <textarea name="feedbacktxt" #fb_Feedback="ngModel" class="form-control" rows="5" cols="3" maxlength="150"
              [(ngModel)]="fbservice.formData.fb_Feedback"
              [ngClass]="{'is-invalid': feedback.submitted &&  fb_Feedback.invalid}" required></textarea>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit Here</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


<!-- Modal for brochures-->
<div class="modal fade" id="cloudBrochure" tabindex="-1" aria-labelledby="cloudBrochureLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Fill the form below and get Brochure</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochure></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #brochure="ngForm"
          (ngSubmit)="brochure.form.valid && onBrochureSubmit(brochure,'Brochure')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_FromPage">
              <input type="text" name="firstname" #ctD_NameBroch="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': brochure.submitted &&  ctD_NameBroch.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="brochEmail" name="email" #ctD_EmailBroch="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,brochure,'brochEmail')"
                [ngClass]="{'is-invalid': brochure.submitted &&  ctD_EmailBroch.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_PhoneBroch="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': brochure.submitted &&  ctD_PhoneBroch.invalid}" required maxlength="20"
                  (hasError)="hasError($event,brochure)" (keyup)="formatNumber($event,brochure)"
                  [ng2TelInputOptions]="{autoPlaceholder:'false'}" (countryChange)="onContryChange($event,brochure)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit and Download Brochure</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="cloudBrochureMicrosoft" tabindex="-1" aria-labelledby="cloudBrochureLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Fill the form below and get Brochure</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochureMC></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #brochureMicrosoft="ngForm"
          (ngSubmit)="brochureMicrosoft.form.valid && onBrochureSubmit(brochureMicrosoft,'Microsoft')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_FromPage">
              <input type="text" name="firstname" #ctD_NameMC="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': brochureMicrosoft.submitted &&  ctD_NameMC.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="mcEmail" name="email" #ctD_EmailMC="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,brochureMicrosoft,'mcEmail')"
                [ngClass]="{'is-invalid': brochureMicrosoft.submitted &&  ctD_EmailMC.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_PhoneMC="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': brochureMicrosoft.submitted &&  ctD_PhoneMC.invalid}" required
                  maxlength="20" (hasError)="hasError($event,brochureMicrosoft)"
                  (keyup)="formatNumber($event,brochureMicrosoft)" [ng2TelInputOptions]="{autoPlaceholder:'false'}"
                  (countryChange)="onContryChange($event,brochureMicrosoft)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit and Download Brochure</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="cloudBrochureDigital" tabindex="-1" aria-labelledby="cloudBrochureLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Fill the form below and get Brochure</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochureDG></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #brochureDigital="ngForm"
          (ngSubmit)="brochureDigital.form.valid && onBrochureSubmit(brochureDigital,'Digital')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel"
                [(ngModel)]="ctservice.formData.ctD_FromPage">
              <input type="text" name="firstname" #ctD_NameDG="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': brochureDigital.submitted &&  ctD_NameDG.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="dgEmail" name="email" #ctD_EmailDG="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,brochureDigital,'dgEmail')"
                [ngClass]="{'is-invalid': brochureDigital.submitted &&  ctD_EmailDG.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_Phone="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': brochureDigital.submitted &&  ctD_Phone.invalid}" required maxlength="20"
                  (hasError)="hasError($event,brochureDigital)" (keyup)="formatNumber($event,brochureDigital)"
                  [ng2TelInputOptions]="{autoPlaceholder:'false'}"
                  (countryChange)="onContryChange($event,brochureDigital)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit and Download Brochure</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="cloudBrochureSAP" tabindex="-1" aria-labelledby="cloudBrochureLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Fill the form below and get Brochure</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochureSAP></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #brochureSAP="ngForm"
          (ngSubmit)="brochureSAP.form.valid && onBrochureSubmit(brochureSAP,'SAP')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel"
                [(ngModel)]="ctservice.formData.ctD_FromPage">
              <input type="text" name="firstname" #ctD_NameSP="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': brochureSAP.submitted &&  ctD_NameSP.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="SAPEmail" name="email" #ctD_EmailSP="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,brochureSAP,'SAPEmail')"
                [ngClass]="{'is-invalid': brochureSAP.submitted &&  ctD_EmailSP.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_PhoneSP="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': brochureSAP.submitted &&  ctD_PhoneSP.invalid}" required maxlength="20"
                  (hasError)="hasError($event,brochureSAP)" (keyup)="formatNumber($event,brochureSAP)"
                  [ng2TelInputOptions]="{autoPlaceholder:'false'}" (countryChange)="onContryChange($event,brochureSAP)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit and Download Brochure</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="cloudBrochurePriceList" tabindex="-1" aria-labelledby="cloudBrochureLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Fill the form below and get PriceList</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochurePrice></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #brochurePriceList="ngForm"
          (ngSubmit)="brochurePriceList.form.valid && onBrochureSubmit(brochurePriceList,'PriceList')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel"
                [(ngModel)]="ctservice.formData.ctD_FromPage">
              <input type="text" name="firstname" #ctD_NamePL="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': brochurePriceList.submitted &&  ctD_NamePL.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="PriceEmail" name="email" #ctD_EmailPL="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,brochurePriceList,'PriceEmail')"
                [ngClass]="{'is-invalid': brochurePriceList.submitted &&  ctD_EmailPL.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_PhonePL="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': brochurePriceList.submitted &&  ctD_PhonePL.invalid}" required maxlength="20"
                  (hasError)="hasError($event,brochurePriceList)" (keyup)="formatNumber($event,brochurePriceList)"
                  [ng2TelInputOptions]="{autoPlaceholder:'false'}" (countryChange)="onContryChange($event,brochurePriceList)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Submit and Download Price List</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal for Demo-->
<div class="modal fade" id="demoRequest" tabindex="-1" aria-labelledby="demoRequestLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Demo Request</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closeBrochureDemo></button>
      </div>
      <div class="modal-body">
        <form class="form" novalidate #demoReq="ngForm"
          (ngSubmit)="demoReq.form.valid && onBrochureSubmit(demoReq,'Demo')">

          <div class="sitErrors">
            <div class="formGroup">
              <label>Full Name <span>*</span></label>

              <input type="hidden" name="lead_ID" value="ctservice.formData.ctD_ID">
              <input type="hidden" id="frompage" name="frompage" #ctD_FromPage="ngModel"
                [(ngModel)]="ctservice.formData.ctD_FromPage">

              <input type="text" name="firstname" #ctD_NameDM="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Name"
                [ngClass]="{'is-invalid': demoReq.submitted &&  ctD_NameDM.invalid}" required maxlength="50">
            </div>
            <div class="formGroup">
              <label>Email <span>*</span></label>
              <input type="email" id="demoEmail" name="email" #ctD_EmailDM="ngModel" class="form-control"
                [(ngModel)]="ctservice.formData.ctD_Email"
                (blur)="validateEmail($event,demoReq,'demoEmail')"
                [ngClass]="{'is-invalid': demoReq.submitted &&  ctD_EmailDM.invalid}" required maxlength="100">
            </div>
            <div class="phNumbers">
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #ctD_PhoneDM="ngModel" class="form-control"
                  [(ngModel)]="ctservice.formData.ctD_Phone"
                  [ngClass]="{'is-invalid': demoReq.submitted &&  ctD_PhoneDM.invalid}" required maxlength="20"
                  (hasError)="hasError($event,demoReq)" (keyup)="formatNumber($event,demoReq)"
                  [ng2TelInputOptions]="{autoPlaceholder:'false'}" (countryChange)="onContryChange($event,demoReq)">
                <input type="hidden" name="countryCode" #CountryCode="ngModel"
                  [(ngModel)]="ctservice.formData.countryCode" value="+1">
                <input type="hidden" name="countryISO" #CountryISO="ngModel" [(ngModel)]="ctservice.formData.CountryISO"
                  value="us">

              </div>
              <div class="formGroup">
                <label>Extn</label>
                <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="ctservice.formData.ctD_Ext"
                  class="form-control" maxlength="5">
              </div>
            </div>

            <div class="formGroup">
              <label>Message (Date and Time)</label>
              <textarea class="msgarea" cols="4" rows="4" name="message" #ctD_Message="ngModel"
                [(ngModel)]="ctservice.formData.ctD_Message" maxlength="3000"
                placeholder="Type Your Message Here"></textarea>
            </div>

            <div class="d-grid mt-3">
              <button class="btn btn-primary btn-lg" type="submit">Request a Demo</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
