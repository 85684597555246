<div class="gsaheader">
  <div class="containers">
  <div class="logo">
    <a routerLink="">
    <img src="../../assets/img/JTSi-Logo.svg" alt="">
  </a>
    <ul>
      <li>People </li>
      <li>Process </li>
       <li>Technology</li>
     </ul>
  </div>
  <nav class="menu">
    <ul>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a [routerLink]="['/gsa518210c']">
        Home
      </a>
    </li>
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a [routerLink]="['/gsa518210c/rates']">
      Cloud Professional Service rates
      </a>
    </li>
    </ul>
  </nav>
  <div class="sublogo">
    <img src="../../../assets/gsa/img/GSA-Logo.jpg" alt="">
  </div>
</div>
</div>

