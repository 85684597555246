//const API_SERVER="http://boapi.gstiusa.com/api/"
//const API_SERVER="http://localhost:51846/api/";
const API_SERVER="https://webapi.jtsusa.com/api/";

const BO_API_SERVER="https://ogapi.jtsiindia.com/api/";//"http://boqaapi.gstiusa.com/api/";
const APP_URL = 'https://jtsusa.com/';
const BOAPP_URL = 'http://boqa.gstiusa.com/';
export const environment = {
  production: true,
  apiServer:`${API_SERVER}`,
  boApiServer:`${BO_API_SERVER}`,
  appUrl: APP_URL,
  boappUrl: BOAPP_URL
};
