<div class="innerHeader C5ISR">
  <div class="innerHeadings">
    <h1>
      C5ISR Solution
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/C5ISR_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Engineering, development and integration
                </h2>
        </div>
        <p>
          Using scalable, open, and modular service-based architectures, we integrate archived and real-time data to deliver accurate and timely actionable intelligence throughout the Operational Chain of Command. This ensure situational awareness for our warfighters.
        </p>
        </div>
  </div>
</div>
</section>

<section class="grayBg">
  <div class="containers">
  <div class="topsection padd0 reverse">

    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Mission planning and execution
                </h2>
        </div>
       <p>
        The C5ISR Solution utilizes the critical data using leading edge tools for planning and execution of successful missions. This is accomplished at the political, strategic, operational, and tactical levels of conflict, whether it be cyber or physical.
       </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/C5ISR_02.jpg" alt="">
      </div>
  </div>
</div>
</section>

<section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/C5ISR_03.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Tactical edge
                </h2>
        </div>
  <p>
    JTSi supports the last tactical mile with systems for warfighters with disconnected, intermittent or limited connectivity to the Command unit. JTSi’s SAP EWM Solutions were built for the Army by the Army labs.
  </p>
        </div>
  </div>
</div>
</section>

  <section class="grayBg commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          JTSi C5ISR solution areas
        </h2>
      </div>
<div class="four-cols extraTwo">
<div class="colBox">
  <div class="colIcon">
  <svg>
    <use xlink:href="../../assets/img/sprite.svg#c5isr001"></use>
  </svg>
</div>
<h4>
  Command and Control
</h4>

</div>

<div class="colBox">
  <div class="colIcon">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#c5isr002"></use>
    </svg>
  </div>
  <h4>
    Communications
  </h4>

  </div>

  <div class="colBox">
    <div class="colIcon">
      <svg>
        <use xlink:href="../../assets/img/sprite.svg#c5isr003"></use>
      </svg>
    </div>
    <h4>
      Computers
    </h4>

    </div>

    <div class="colBox">
      <div class="colIcon">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#c5isr004"></use>
        </svg>
      </div>
      <h4>
        Cyber Defense
      </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#c5isr005"></use>
          </svg>
        </div>
        <h4>
          Intelligence
        </h4>

        </div>

        <div class="colBox">
          <div class="colIcon">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#c5isr006"></use>
            </svg>
          </div>
          <h4>
            Surveillance and Reconnaissance
          </h4>

          </div>

</div>
</div>
  </section>

