<div class="innerHeader mobility">
  <div class="innerHeadings">
    <h1>
      Mobility & IOT
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/mobility_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  SAP IOT Service
                </h2>
        </div>
        <p>
          As more companies implement Internet of Things (IoT) devices integrated with their SAP solutions, manufacturing efficiency increases. Automation and supply chain management using IoT and SAP are highly effective methods. Integrating SAP with IoT solutions improves logistics, production, communication, and every aspect of the production cycle.
        </p><p>
Together, they create an environment where there is end-to-end transparency and enhanced innovation. Integrating your IoT solution with SAP can be a challenge, but you can choose a trusted partner like JTSi that can help you every step of the way to provide secure functionality before, during, and after the implementation.
        </p>
        </div>
  </div>
  <div class="borderedpic commonPadding">
    <img class="img-fluid" src="../../../assets/img/mobility_02.jpg" alt="">
  </div>
  <h3 class="subtitles_one">
    SAP Leonardo IoT brings intelligence to the enterprise by offering four paths to innovation:
  </h3>
  <ul class="bulletpoints">
    <li>	Embedding device and telemetry data into SAP LoB applications (e.g. S/4 HANA, C/4 HANA, Digital Supply Chain) for an IoT enabled Intelligent Enterprise Suite</li>
<li>	Extending existing SAP business processes by gaining information and insights from previously unconnected devices (e.g. machines, products, assets)</li>
<li>	Evolving core business processes stable while creating new IoT enabled business models, in the context of SAP business systems</li>
<li>	Extending the business processes to the Edge, orchestrated from the cloud-SAP Leonardo IoT helps running business transactions correlated with device data, close to the source of IoT data, at the Edge</li>

  </ul>
  <p>
   <strong>SAP Leonardo IoT integrates with Azure and AWS IoT services</strong>  providing customers with the ability to contextualize and enrich their IoT data with SAP business data.
  </p>
</div>
</section>

  <section class="commonPadding grayBg">
    <div class="containers">

      <h3 class="subtitles_one">
        JTSi’s has capabilities to partner with you in any stage of your mobility journey
    </h3>
<div class="borderd-grids">
<div class="gridbox grayBg">
<h3 class="subtitles_one">
  Discovery Stage
</h3>
<ul class="bulletpoints">
  <li>	Business Process Analysis</li>
<li>	Identify and develop Use cases</li>
<li>	Understand areas of importance</li>
</ul>
</div>
<div class="gridbox grayBg">
  <h3 class="subtitles_one">
    Strategy/Roadmap Stage
  </h3>
  <ul class="bulletpoints">
    <li>	Propose Solutions</li>
    <li>	Budgets</li>
    <li>	Define Roadmaps    </li>
  </ul>
  </div>
  <div class="gridbox grayBg">
    <h3 class="subtitles_one">
      Implementation Stage
    </h3>
    <ul class="bulletpoints">
      <li>Rapid Deployment Mobile Infrastructure & Software Set up</li>
      <li>Agile Development Develop new SAP apps and/or customize existing SAP apps</li>
    </ul>
    </div>
    <div class="gridbox grayBg">
      <h3 class="subtitles_one">
        Maintenance Stage
      </h3>
      <ul class="bulletpoints">
        <li>Managed Hosting /Application Support</li>
      </ul>
      </div>

</div>
</div>
  </section>


