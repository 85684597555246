import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsibility',
  templateUrl: './responsibility.component.html',
  styleUrls: ['./responsibility.component.scss']
})
export class ResponsibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
