<div class="innerHeader quickglance">
  <div class="innerHeadings">
    <h1>
      Quick Glance
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/glance_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Highlights of our achievements over the years
                </h2>
        </div>
        <ul class="bulletpoints">
          <li>	Supporting Large Scale Complex Army ERP Programs since 2003 with high staff retention record</li>
          <li>	CRADA with the United States Army (CCDC-AC) & in-house FSO service</li>
          <li>	Highly experienced workforce with Security Clearance - PMI, ITIL & Black-belt certified Managers</li>

          <li>	Management Team include reputable leaders from the Government and the Industry</li>
          <li>	Clients include: Government & Commercial | Achieved 97% at D&B/Open-Rating Survey</li>
          <li>	Good standing with State, Federal, SBA, D&B and outstanding CPARS</li>
          <li>	ISO-9001 and CMMI-L3 Certified. Partnerships with University Innovation Centers</li>

        </ul>
        </div>
  </div>

</div>
</section>


<section class="commonPadding">
  <div class="containers">
    <div class="boxtitle_two">
      <h2>
        Detailed Performance Ratings
      </h2>
    </div>

<div class="borderd-grids threesome">
<div class="gridbox whiteBg">
<h3 class="subtitles_one">
Reliability
</h3>
<p>
How reliably do you think this company follows through on its commitments?
</p>
<h2 class="red_color">96%</h2>
</div>
<div class="gridbox whiteBg">
<h3 class="subtitles_one">
  Cost
</h3>
<p>
How closely did your final total costs correspond to your expectations at the beginning of the transaction?
</p>
<h2 class="red_color">94%</h2>
</div>
<div class="gridbox whiteBg">
  <h3 class="subtitles_one">
    Quality
  </h3>
<p>
How satisfied do you feel about the quality of the product/service provided by this company?
</p>
<h2 class="red_color">96%</h2>
  </div>
  <div class="gridbox whiteBg">
    <h3 class="subtitles_one">
      Business Relations
    </h3>
    <p>
      How easy do you think this company is to do business with?
    </p>
    <h2 class="red_color">97%</h2>
    </div>
    <div class="gridbox whiteBg">
      <h3 class="subtitles_one">
        Personnel
      </h3>
      <p>
        How satisfied do you feel about the attitude, courtesy, and professionalism of this company’s staff?
      </p>
      <h2 class="red_color">96%</h2>
      </div>
      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Customer Support
        </h3>
        <p>
          How satisfied do you feel about the attitude, courtesy, and professionalism of this company’s staff?
        </p>
        <h2 class="red_color">97%</h2>
        </div>
</div>
</div>
</section>


<section class="commonPadding grayBg">
  <div class="containers">
<div class="borderd-grids threesome">

  <div class="gridbox whiteBg">
    <h3 class="subtitles_one">
      What we do
    </h3>
    <ul class="bulletpoints">
<li>	Digital Transformation</li>
<li>	Cyber Security</li>
<li>	BIG Data & Analytics</li>
<li>	Cloud Hosting & AMS</li>
<li>	Cloud Migration Strategy /Execution</li>
<li>	Engineering Services</li>
<li>	Systems Engineering & Technical Assistance (SETA)</li>
<li>	Software Engineering Services</li>
<li>	Product Development Life Cycle & TechData</li>

    </ul>
    </div>
    <div class="gridbox whiteBg">
      <h3 class="subtitles_one">
        Deployable Solutions
      </h3>
      <ul class="bulletpoints">
<li>MRO ERP</li>
<li>SAP Inventory Management (S4/EWM)</li>
<li>SurveyShield (Web Services)</li>
<li>Integrated Process Solution (IPS)</li>
<!-- <li>VeloX Software Suite</li>
<li>IQ DMS</li>
<li>IQ Product Work Center</li>
<li>IQ Launch</li>
<li>IQ Discussion</li>
<li>Payment Terms Governance</li>
<li>BOM / Material Master Extension & Allocation Tool (BMAX)</li> -->
      </ul>
      </div>
      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Govt. Contract Vehicles
        </h3>
        <ul class="bulletpoints">
          <li>	GSA – MAS + CLOUD / CYBER SIN / GSA-PSS / Oasis</li>
<li>	US Army ERP Services – AES</li>
<li>	ITES2S/3S</li>
<li>	Encore III / RPTI & R23G</li>
<li>	Responsive Strategic Sourcing For Services (RS3)</li>
<li>	Navy ERP Navy SeaPort-E</li>
<li>	SSES Nexgen and CIO/SP3</li>
<li>	Army Futures Command (AFC IT Services IDIQ)</li>
<li>	SAP Partnership</li>

        </ul>
        </div>
</div>
</div>
</section>
