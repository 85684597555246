import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {Ng2TelInputModule} from "ng2-tel-input";

import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {  ToastrModule} from "ngx-toastr";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CapabilitiesComponent } from './thumbgrids/capabilities/capabilities.component';
import { SapserviceComponent } from './thumbgrids/sapservice/sapservice.component';
import { WarsupportComponent } from './thumbgrids/warsupport/warsupport.component';
import { ModernizationComponent } from './capability/modernization/modernization.component';
import { IntelligenceComponent } from './capability/intelligence/intelligence.component';
import { CapabilityComponent } from './capability/capability/capability.component';
import { DigitalTransformationComponent } from './capability/digital-transformation/digital-transformation.component';
import { AnalyticsComponent } from './capability/analytics/analytics.component';
import { CybersecurityComponent } from './capability/cybersecurity/cybersecurity.component';
import { MachineLearningComponent } from './capability/machine-learning/machine-learning.component';
import { DataMigrationComponent } from './capability/data-migration/data-migration.component';
import { OperationsComponent } from './capability/operations/operations.component';
import { ManagedITComponent } from './capability/managed-it/managed-it.component';
import { SoftwareFactoryComponent } from './capability/software-factory/software-factory.component';
import { EnterpriseKnowledgeComponent } from './capability/enterprise-knowledge/enterprise-knowledge.component';
import { MigrationStrategyComponent } from './capability/migration-strategy/migration-strategy.component';
import { EnterpriseArchitectureComponent } from './capability/enterprise-architecture/enterprise-architecture.component';
import { CloudServicesComponent } from './capability/cloud-services/cloud-services.component';
import { EtlComponent } from './capability/etl/etl.component';
import { SystemsEngineeringComponent } from './capability/systems-engineering/systems-engineering.component';
import { ProductDevelopmentComponent } from './capability/product-development/product-development.component';
import { SolutionsComponent } from './products/solutions/solutions.component';
import { MroErpComponent } from './products/mro-erp/mro-erp.component';
import { SapInventoryComponent } from './products/sap-inventory/sap-inventory.component';
import { SurveyshieldComponent } from './products/surveyshield/surveyshield.component';
import { OfficegapsComponent } from './products/officegaps/officegaps.component';
import { ProductLifeCycleComponent } from './capability/product-life-cycle/product-life-cycle.component';
import { SupportServicesComponent } from './supportService/support-services/support-services.component';
import { C5ISRComponent } from './supportService/c5-isr/c5-isr.component';
import { HardwareIntegrationComponent } from './supportService/hardware-integration/hardware-integration.component';
import { EngineeringAnalysisComponent } from './supportService/engineering-analysis/engineering-analysis.component';
import { PrototypingComponent } from './supportService/prototyping/prototyping.component';
import { DefenseArmamentComponent } from './supportService/defense-armament/defense-armament.component';
import { PTCWindchillComponent } from './supportService/ptc-windchill/ptc-windchill.component';
import { SapComponent } from './sapService/sap/sap.component';
import { SapHanaComponent } from './sapService/sap-hana/sap-hana.component';
import { CloudMigrationComponent } from './sapService/cloud-migration/cloud-migration.component';
import { MobilityComponent } from './sapService/mobility/mobility.component';
import { MigrationMaintenanceComponent } from './sapService/migration-maintenance/migration-maintenance.component';
import { SuccessfactorsComponent } from './sapService/successfactors/successfactors.component';
import { VisionMissionComponent } from './aboutUs/vision-mission/vision-mission.component';
import { CorporateComponent } from './aboutUs/corporate/corporate.component';
import { QuickGlanceComponent } from './aboutUs/quick-glance/quick-glance.component';
import { CareersComponent } from './aboutUs/careers/careers.component';
import { PartnershipsComponent } from './aboutUs/partnerships/partnerships.component';
import { ContractsComponent } from './contracts/contracts.component';
import { AboutComponent } from './aboutUs/about/about.component';
import { NewsComponent } from './aboutUs/news/news.component';
import { ResponsibilityComponent } from './aboutUs/responsibility/responsibility.component';
import { ContactComponent } from './aboutUs/contact/contact.component';
import { HanasupportComponent } from './sapService/hanasupport/hanasupport.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ProductComponent } from './thumbgrids/product/product.component';
import { ThumbnailsComponent } from './thumbgrids/thumbnails/thumbnails.component';
import { EnterpriseComponent } from './thumbgrids/enterprise/enterprise.component';
import { CloudsComponent } from './thumbgrids/clouds/clouds.component';
import { EngineeringComponent } from './thumbgrids/engineering/engineering.component';
import { AnalyticsaiComponent } from './thumbgrids/analyticsai/analyticsai.component';
import { WhoweareComponent } from './thumbgrids/whoweare/whoweare.component';
import { KeydifferentComponent } from './keydifferent/keydifferent.component';
import { HttpClientModule } from '@angular/common/http';
import { DisablethingsDirective } from './disablethings.directive';
import { LoginComponent } from './authentication/login/login.component';
import { SnowFlakeComponent } from './snow-flake/snow-flake.component';
import { AuthComponent } from './authentication/auth/auth.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { CommonpagesComponent } from './commonpages/commonpages.component';
import { AbtgridComponent } from './thumbgrids/abtgrid/abtgrid.component';
import { CapgridComponent } from './thumbgrids/capgrid/capgrid.component';
import { SapgridComponent } from './thumbgrids/sapgrid/sapgrid.component';
import { ProdgridComponent } from './thumbgrids/prodgrid/prodgrid.component';
import { WargridComponent } from './thumbgrids/wargrid/wargrid.component';
import { IntegratedProcessComponent } from './products/integrated-process/integrated-process.component';
import { VeloxComponent } from './products/velox/velox.component';
import { IqdmsComponent } from './products/iqdms/iqdms.component';
import { IqpwcComponent } from './products/iqpwc/iqpwc.component';
import { IqlaunchComponent } from './products/iqlaunch/iqlaunch.component';
import { IqdiscussionComponent } from './products/iqdiscussion/iqdiscussion.component';
import { PaymenttgComponent } from './products/paymenttg/paymenttg.component';
import { BmaxComponent } from './products/bmax/bmax.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import {filter} from 'rxjs/operators';
import { DecorationlightComponent } from './decorationlight/decorationlight.component';
import { GsalayoutComponent } from './gsalayout/gsalayout.component';
import { GsaheaderComponent } from './gsalayout/gsaheader/gsaheader.component';
import { GsafooterComponent } from './gsalayout/gsafooter/gsafooter.component';
import { GsahomeComponent } from './gsalayout/gsahome/gsahome.component';
import { GsaserviceComponent } from './gsalayout/gsaservice/gsaservice.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import { GsaservicedetailComponent } from './gsalayout/gsaservicedetail/gsaservicedetail.component';
import { GsacontactComponent } from './gsalayout/gsacontact/gsacontact.component';
import { GsadeatilsComponent } from './gsalayout/gsadeatils/gsadeatils.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CapabilitiesComponent,
    SapserviceComponent,
    WarsupportComponent,
    ModernizationComponent,
    IntelligenceComponent,
    CapabilityComponent,
    DigitalTransformationComponent,
    AnalyticsComponent,
    CybersecurityComponent,
    MachineLearningComponent,
    DataMigrationComponent,
    OperationsComponent,
    ManagedITComponent,
    SoftwareFactoryComponent,
    EnterpriseKnowledgeComponent,
    MigrationStrategyComponent,
    EnterpriseArchitectureComponent,
    CloudServicesComponent,
    EtlComponent,
    SystemsEngineeringComponent,
    ProductDevelopmentComponent,
    SolutionsComponent,
    MroErpComponent,
    SapInventoryComponent,
    SurveyshieldComponent,
    OfficegapsComponent,
    ProductLifeCycleComponent,
    SupportServicesComponent,
    C5ISRComponent,
    HardwareIntegrationComponent,
    EngineeringAnalysisComponent,
    PrototypingComponent,
    DefenseArmamentComponent,
    PTCWindchillComponent,
    SapComponent,
    SapHanaComponent,
    CloudMigrationComponent,
    MobilityComponent,
    MigrationMaintenanceComponent,
    SuccessfactorsComponent,
    VisionMissionComponent,
    CorporateComponent,
    QuickGlanceComponent,
    CareersComponent,
    PartnershipsComponent,
    ContractsComponent,
    AboutComponent,
    NewsComponent,
    ResponsibilityComponent,
    ContactComponent,
    HanasupportComponent,
    PrivacypolicyComponent,
    DisclaimerComponent,
    ProductComponent,
    ThumbnailsComponent,
    EnterpriseComponent,
    CloudsComponent,
    EngineeringComponent,
    AnalyticsaiComponent,
    WhoweareComponent,
    KeydifferentComponent,
    DisablethingsDirective,
    LoginComponent,
    SnowFlakeComponent,
    AuthComponent,
    LayoutsComponent,
    CommonpagesComponent,
    AbtgridComponent,
    CapgridComponent,
    SapgridComponent,
    ProdgridComponent,
    WargridComponent,
    IntegratedProcessComponent,
    VeloxComponent,
    IqdmsComponent,
    IqpwcComponent,
    IqlaunchComponent,
    IqdiscussionComponent,
    PaymenttgComponent,
    BmaxComponent,
    DownloadsComponent,
    DecorationlightComponent,
    GsalayoutComponent,
    GsaheaderComponent,
    GsafooterComponent,
    GsahomeComponent,
    GsaserviceComponent,
    GsaservicedetailComponent,
    GsacontactComponent,
    GsadeatilsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({positionClass:'toast-center-center',preventDuplicates:true, timeOut: 2000}),
    Ng2TelInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(router: Router, viewportScroller: ViewportScroller) {

    viewportScroller.setOffset([0, 160]);

    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {

      if (e.anchor) {

        // anchor navigation

        setTimeout(() => {

          viewportScroller.scrollToAnchor(e.anchor);

        })

      } else if (e.position) {

        // backward navigation

        viewportScroller.scrollToPosition(e.position);

      } else {

        // forward navigation

        viewportScroller.scrollToPosition([0, 0]);

      }

    });
  }
}
