import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gsaheader',
  templateUrl: './gsaheader.component.html',
  styleUrls: ['./gsaheader.component.scss']
})
export class GsaheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
