import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mro-erp',
  templateUrl: './mro-erp.component.html',
  styleUrls: ['./mro-erp.component.scss']
})
export class MroErpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
