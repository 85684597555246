<!-- <div class="whitepage">
<div class="centredContent">
  <div class="logo">
    <a [routerLink]="['']" >
    <div class="mainlogo">
    <img src="../../assets/../../assets/img/JTSi-Logo.svg" alt="">
    <div class="logotxt">
      <ul>
       <li>People </li>
       <li>Process </li>
        <li>Technology</li>
      </ul>
    <p>US Govt. Prime Contractor</p>
  </div>
  </div>
  </a>
  </div>
      <div class="login-content">
<h2 class="login-title">Sign In</h2>
<form action="">
        <div class="login-body">
<div class="loginMethod">
  <div class="optiontitle">
    <input class="form-check-input" type="radio" checked name="optionLogin" id="optionTwo" value="" aria-label="...">
    <label for="optionTwo">With Face ID</label>
  </div>
  <div class="optiontitle">
    <input class="form-check-input" type="radio" name="optionLogin" id="optionOne" value="" aria-label="...">
    <label for="optionOne">With Password</label>
  </div>
</div>
          <div class="withface">

            <div class="logindetails">
              <div class="loginputs username">
                <label for="">Username</label>
                <input class="form-control" type="text" placeholder="Username">
              </div>

              <div class="photcapture">
                <div class="capturehead">
                  <h4>Capture Image</h4>
                  <button>
                  <svg>
                    <use xlink:href="../../assets/../../assets/img/sprite.svg#powerbtn"></use>
                  </svg>
                  </button>
                </div>
                <div class="capturedcontent form-control">

                </div>
              </div>



              <button class="logbtn" type="submit">Sign In</button>

            </div>

        </div>

          <div class="withpassword">

            <div class="logindetails">
              <div class="loginputs username">
                <label for="">Username</label>
                <input class="form-control" type="text" placeholder="Username">
              </div>
              <div class="loginputs passwords">
                <label for="">Password</label>
                <input class="form-control" type="password" placeholder="*******">
              </div>
              <img src="../../../assets/../../assets/img/notarobot.jpg" class="img-fluid mb-4" alt="">
              <button class="logbtn" type="submit">Sign In</button>
              <p class="forgot"><a href="#">Forgot Password?</a></p>
            </div>

        </div>



      </div>
    </form>
      <h4 class="signuplink">Don't have an account? <a href="#">Sign Up Now</a></h4>
      <p class="returnHome">
        <a [routerLink]="['']">Cancel and Return to Website</a>
       </p>
      </div>

    </div>
    <div class="whitefoot">
      <p>
        &copy; <a [routerLink]="['']"> 2021 JTSi USA</a> All Rights Reserved.
      </p>

      <p>
        <a [routerLink]="['/privacy-policy']"> Privacy Policy </a>
        <a [routerLink]="['/Disclaimer']"> Disclaimer</a>
      </p>
    </div>
</div> -->


<div class="ogloginpage">
<h3 class="coming">
  Coming <strong>Soon</strong>!
</h3>
  <div class="loginwrap">
      <div class="oglogo">
        <a [routerLink]="['']" ><img src="../../assets/img/JTSi-Logo.svg" alt=""></a>
      </div>
      <form class="newloginfields" action="">
          <div class="loginoptions">

            <div class="radioGroup">
              <input class="radioBtn" type="radio" id="withpassword" name="loginoption" value="1" checked>
              <label for="withpassword">
              <span></span>With Password
            </label>
            </div>

            <div class="radioGroup">
              <input class="radioBtn" type="radio" id="faceID" name="loginoption" value="1">
              <label for="faceID">
              <span></span>With Face ID
            </label>
            </div>

          </div>
      <div class="logincolumn">

          <!-- <div class="formside">

          <div class="formfields">
              <svg>
                  <use xlink:href="../../assets/img/sprite.svg#newuser"></use>
              </svg>
              <input type="text" placeholder="Username">
          </div>
          <div class="logincapture">

          <div class="faceCapture">
              <button class="flip">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#flipcamera"></use>
                  </svg>
              </button>
          </div>
      </div>
          <button class="newlogbtn">Sign In</button>

      </div> -->

      <div class="formside">

      <div class="formfields">
          <svg>
              <use xlink:href="../../assets/img/sprite.svg#newuser"></use>
          </svg>
          <input type="text" placeholder="Username">
      </div>
      <div class="formfields">
          <svg>
              <use xlink:href="../../assets/img/sprite.svg#newpassword"></use>
          </svg>
          <input type="password" placeholder="Password">
      </div>
      <div class="formfields">
          <img class="img-fluid" src="../../assets/img/notarobot.jpg" alt="">
      </div>
      <button class="newlogbtn">Sign In</button>


  <p>
      <a href="#">Forgot Your Password?</a>
  </p>

  </div>

      </div>
  </form>
      <!-- <div class="newloginfooter">
      <p class="signupnow">
          Don't have an account?
          <a href="#">Sign Up Now</a>
      </p>
      <div class="deviderline">
          <span>Or</span>
      </div>
      <p>Continue with social media</p>
      <div class="socialmedias">
          <li>
              <a href="#">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#newgamil"></use>
                  </svg>
              </a>
          </li>
          <li>
              <a href="#">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#newlinkd"></use>
                  </svg>
              </a>
          </li>
          <li>
              <a href="#">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#newfb"></use>
                  </svg>
              </a>
          </li>
          <li>
              <a href="#">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#newtwitter"></use>
                  </svg>
              </a>
          </li>
      </div>
  </div> -->
</div>
</div>
