<div class="hero contact">
  <h1>
    Contact Us
  </h1>
  </div>
  <section class="sections">
    <div class="containers">
      <div class="equalclmns">
        <div class="details">
          <div class="info">
          <h2>
            Start a Conversation
          </h2>
          <p>
            Our mission is to help you make the right choice for the right outcomes. That starts with an honest conversation about your challenges. We look forward to talking.
          </p>
          <h4>
            <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
          </h4>
          <p>
            <a href="tel:+1703949-9100">+1(703)949-9100</a>
          </p>
        </div>
          <ul class="social">
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../../assets/gsa/img/sprite.svg#email"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../../assets/gsa/img/sprite.svg#linkedin"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../../assets/gsa/img/sprite.svg#fb2"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../../assets/gsa/img/sprite.svg#twitter"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../../assets/gsa/img/sprite.svg#youtube"></use>
                </svg>
              </a>
            </li>
          </ul>

          <h3>
            Our Office
          </h3>
          <h4>
            <svg>
              <use xlink:href="../../../assets/gsa/img/sprite.svg#usaflag"></use>
            </svg>
            United States
          </h4>
          <address class="address">
            <p>
              Washington DC Metro
              <br>
              11490 Commerce Park Dr.,
              <br>
              4th Floor, Verginia 20191
            </p>
          </address>
          <address class="address">
            <p>
              370 Richard Mine Road,
              <br>
            Suite#100, Wharton - NJ - 0785-1836
            </p>
          </address>
        </div>
        <div class="forms">
          <form>
            <div class="formfields">
              <label for="">Full name<span>*</span></label>
              <input class="forminputs" type="text" name="" id="">
            </div>
          <div class="formfields">
            <label for="">Email<span>*</span></label>
            <input class="forminputs" type="email" name="" id="">
          </div>
          <div class="formfields">
            <label for="">Phone number <span>*</span> </label>
            <input class="forminputs" type="number" name="" id="">
          </div>
          <div class="formfields">
            <label for="">Website</label>
            <input  class="forminputs" type="url" name="" id="">
          </div>
          <div class="formfields">
            <label for="">Your Message</label>
            <textarea class="forminputs" name="" id="" cols="20" rows="5"></textarea>
          </div>
          <button class="cartbtn" type="submit">Submit</button>
            </form>
        </div>
      </div>
    </div>
  </section>
