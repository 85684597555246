<div class="innerHeader leverx_velox">
  <div class="innerHeadings">
    <h1>
      VeloX Software Suite
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Move and transform data smarter, faster, and easier
      </h2>
      <h4>Velox Software Suite enables Data Migration and System Integration throughout the entire organization. The suite consists of two applications: Migration Studio (VXm) — for user-controlled data migrations; Integration Server (VXi) — for automated data processing and integration.</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#abBolcks"></use>
            </svg>
           </span>
           Extract Data
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#dbBolcks"></use>
           </svg>
          </span>
          Transform & Map
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#measureYou"></use>
            </svg>
           </span>
           Run Manually
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#exeFlow"></use>
            </svg>
           </span>
           Run as Service
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/velox_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/velox_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/velox_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/velox_04.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            How VeloX Simplifies Data Management
          </h2>
          <h4>Here is how VeloX Suite helps to manage data.</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Improves decision making </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/velox_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Streamlines operations </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/velox_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Increases productivity</h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/velox_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>Brings data integrity and data quality </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/velox_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Use Cases
      </h2>
      <h4>Here are some examples of using VeloX</h4>
    </div>
    <div class="borderd_four_col">
<div class="borderd_col blue">
  <img src="../../../assets/img/velox_31.png" class="img-fluid" alt="...">
<h4>Data Migration</h4>
<ul class="ticklist">
  <li>Application Migration </li>
<li>Database Migration </li>
<li>Cloud Migration</li>
<li>Storage Migration</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/velox_32.png" class="img-fluid" alt="...">
  <h4>Data Propagation</h4>
  <ul class="ticklist">
    <li>Extract from multiple sources and propagate to multiple destinations</li>
<li>Event- and rules-driven</li>
<li>Synchronous and asynchronous exchange</li>
<li>EAI and EDR technologies </li>
    </ul>
  </div>
  <div class="borderd_col">
    <img src="../../../assets/img/velox_33.png" class="img-fluid" alt="...">
    <h4>Data Virtualization</h4>
    <ul class="ticklist">
      <li>Near real-time unified view of data without moving between sources</li>
      <li>Service-oriented architecture</li>
      <li>Various abstraction and transformation techniques</li>
      <li>EII technologies </li>
      </ul>
    </div>
    <div class="borderd_col">
      <img src="../../../assets/img/velox_34.png" class="img-fluid" alt="...">
      <h4>Data Consolidation</h4>
      <ul class="ticklist">
        <li>Physically bring data together from a multitude of sources </li>
<li>Reduce a number of data storage locations</li>
<li>Transform based on business rules</li>
<li>ETL technologies </li>
        </ul>
      </div>
    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>VeloX offers the following key features and capabilities</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_02"></use>
                </svg>
               </span>
               Usability
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_05"></use>
                </svg>
               </span>
               Latest technologies
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2"  aria-label="Slide 3">
             <span>
              <svg>
                <use xlink:href="../../assets/img/sprite.svg#prokey_01"></use>
              </svg>
             </span>
             Performance
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="3" aria-label="Slide 4">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#prokey_03"></use>
                </svg>
               </span>
               Flexibility
            </button>


          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Usability</h3>
              <ul class="ticklist">
                <li>Zero-coding approach </li>
                <li>Drag-and-drop interface</li>
                <li>Responsive UI</li>
                <li>Guided configuration</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3>Latest technologies</h3>
              <ul class="ticklist">
                <li>S/4HANA compatible</li>
                <li>Cloud support</li>
                <li>Any SAP and non-SAP databases </li>
                <li>Latest security standards</li>
                </ul>
            </div>
            <div class="carousel-item">
              <h3>Performance</h3>
              <ul class="ticklist">
                <li>Superfast data load with smart multithreading and balancing</li>
<li>Low CPU and RAM utilization</li>
                </ul>
            </div>
            <div class="carousel-item">
              <h3>Flexibility</h3>
              <ul class="ticklist">
                <li>Embedded Python programming language for advanced users</li>
<li>Plugins-based framework</li>
<li>Highly scalable</li>
                </ul>
            </div>

          </div>

        </div>
        </div>
      </div>
        </section>
