import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gsaservicedetail',
  templateUrl: './gsaservicedetail.component.html',
  styleUrls: ['./gsaservicedetail.component.scss']
})
export class GsaservicedetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
