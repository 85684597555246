<section class="commonPadding grayBg" id="whoweare">
  <div class="texttitle_two">
      <h2>
        About Us
      </h2>
  </div>
<div class="containers homeGrid">

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/vision_thumb.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Vision | Mission | Core Value
    </h4>
<a [routerLink]="['/vision-mission-core-value']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/overview_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Corporate Overview
    </h4>
<a [routerLink]="['/corporate-overview']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/glance_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      A Quick Glance @JTSi
    </h4>
<a [routerLink]="['/quick-glance-at-jtsi']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/news_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      News
    </h4>
<a [routerLink]="['/news']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/responsibilty_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Corporate Social Responsibility
    </h4>
<a [routerLink]="['/csr']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/career_thumb.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Careers
    </h4>
<a [routerLink]="['/careers']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/Partnerships_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Partnerships /Relationships
    </h4>
<a [routerLink]="['/partnerships-relationships']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/contact_01.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Contact Us
    </h4>
<a [routerLink]="['/contact-us']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

</div>
</section>
