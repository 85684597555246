import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integrated-process',
  templateUrl: './integrated-process.component.html',
  styleUrls: ['./integrated-process.component.scss']
})
export class IntegratedProcessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
