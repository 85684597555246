import { Injectable } from '@angular/core';
import { Talktous } from './talktous.model';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment.prod';
import { CommonfunctionsService } from '../utils/commonfunctions.service';

@Injectable({
  providedIn: 'root'
})
export class TalktousService {

  constructor(private http:HttpClient,
    public commonfunctions:CommonfunctionsService,) { }
  formData:Talktous=new Talktous();

  postTalktoUsDetail(){
        return this.http.post(`${environment.apiServer}ContactDetail`,this.formData)
  }

  getURLToken(appUrl:string, brochurePath:string, fullname:string, email:string){
    const query = `${environment.apiServer}BrochureDetails/GetTokenURL?appUrl=${appUrl}&brochurePath=${brochurePath}&fullname=${fullname}&email=${email}`;
    return this.http.get(query);
  }
  sendBRochure(appUrl:string, fullname:string, email:string, type:string){
    var mailBoby="<html><body><a href='"+appUrl+"'>Click to download</a></body></html>";
    var bcc='';
    if(type=='PriceList'){
      bcc='contracts@jtsusa.com'
    }
    console.log("info@jtsiindia.com","Mail From JTSi Website",email,mailBoby,'No','','','','')
    return this.commonfunctions.sendEmail("info@jtsiindia.com","Mail From JTSi Website",email,mailBoby,'No','',bcc,'','')
  }
  downloadBrochure(email:string, Token:string ){
    return this.http.post<any>(`${environment.apiServer}BrochureDetails/downloadBrochure`, null, {
      params: {
        email: email,
        token: Token
      }
    });
  }
  sendFeedback(feedback:string) {
    var mailBoby="<html><body> Error Reported. Please find below feeddback from User </br></br>"+feedback+"</body></html>";
    console.log("info@jtsiindia.com","Mail From JTSi Website","info@jtsiindia.com",mailBoby,'No','','','','')
    return this.commonfunctions.sendEmail("info@jtsiindia.com","Mail From JTSi Website","info@jtsiindia.com",mailBoby,'No','','','','')
  }
}

