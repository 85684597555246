import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';

import { CommonfunctionsService } from 'src/app/utils/commonfunctions.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AsYouType } from "libphonenumber-js"
import { TalktousService } from '../shared/talktous.service';
import { FeedbackService } from '../shared/feedback.service';
declare var require: any
const FileSaver = require("file-saver");

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  phoneValid = true;
  @ViewChild('closeFeedback') closeFeedback:ElementRef;
  @ViewChild('closeBrochure') closeBrochure:ElementRef;
  @ViewChild('closeBrochureMC') closeBrochureMC:ElementRef;
  @ViewChild('closeBrochureDG') closeBrochureDG:ElementRef;
  @ViewChild('closeBrochureSAP') closeBrochureSAP:ElementRef;
  @ViewChild('closeBrochurePrice') closeBrochurePrice:ElementRef;
  @ViewChild('closeBrochureDemo') closeBrochureDemo:ElementRef;
  @ViewChild('closeletstalk') closeletstalk:ElementRef;

  constructor(
    public ctservice: TalktousService,
    public commonfunctions: CommonfunctionsService,
    private toaster: ToastrService,
    public fbservice: FeedbackService,

  ) { }

  ngOnInit(): void {
    document.addEventListener("scroll", handleScroll);
    // get a reference to our predefined button
    var scrollToTopBtn = document.querySelector(".totopbtn");

    function handleScroll() {
      var scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var GOLDEN_RATIO = 0.5;

      if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
        //show button
        if (!scrollToTopBtn.classList.contains("viewbtn"))
          scrollToTopBtn.classList.add("viewbtn")
      } else {
        //hide button
        if (scrollToTopBtn.classList.contains("viewbtn"))
          scrollToTopBtn.classList.remove("viewbtn")
      }
    }

    scrollToTopBtn.addEventListener("click", scrollToTop);

    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

  }

  onSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }
    /*  if(!this.commonfunctions.validateWebsite(frm.value.website) && frm.value.website!=''&& frm.value.website!=null){
        this.toaster.error("Website is not valid");
        return;
      }*/
    frm.controls['phoneNumber'].setValue("+" + (frm.value.countryCode == "" ? "1" : frm.value.countryCode) + frm.value.phoneNumber);
    frm.controls['frompage'].setValue("TalktoUs");
    console.log(frm.value);
    this.ctservice.postTalktoUsDetail().subscribe(
      res => {
        this.resetForm(frm);
        this.toaster.success("Submitted. Thank you", "");
        this.closeletstalk.nativeElement.click();
      },
      err => {
        this.toaster.error("Failed to submit response", "");
        console.log(err);
      }
    )
  }
  resetForm(frm: NgForm) {
    frm.controls['firstname'].reset();
    frm.controls['firstname'].setErrors(null);
    frm.controls['email'].reset();
    frm.controls['email'].setErrors(null);
    frm.controls['phoneNumber'].reset();
    frm.controls['phoneNumber'].setErrors(null);
    frm.controls['message'].reset();
    frm.controls['extn'].reset();
  }
  resetPhoneField(frm: NgForm) {
    var countryList = document.getElementsByClassName("iti__country iti__standard iti__highlight iti__active");
    if (countryList != null && countryList.item(0) != null) {
      countryList.item(0).removeAttribute("aria-selected");
      countryList.item(0).setAttribute("class", "iti__country iti__standard");
    }

    var countryListPRef = document.getElementsByClassName("iti__country iti__preferred iti__highlight iti__active");
    if (countryListPRef != null && countryList.item(0) != null) {
      countryListPRef.item(0).removeAttribute("aria-selected");
      countryListPRef.item(0).setAttribute("class", "iti__country iti__preferred");
    }


    var countryListUS = document.getElementById("iti-item-us");
    if (countryListUS != null) {
      countryListUS.className = 'iti__country iti__preferred iti__highlight iti__active';
      countryListUS.setAttribute("aria-selected", "true");
    }

    var countryListSelected = document.getElementsByClassName("iti__selected-flag");
    if (countryListSelected != null) {
      countryListSelected.item(0).setAttribute("title", "United States: +1");
      countryListSelected.item(0).setAttribute("aria-activedescendant", "iti-item-us");
      countryListSelected.item(0).firstElementChild.className = 'iti__flag iti__us';
    }

    frm.controls['countryCode'].setValue("1");
    frm.controls['countryISO'].setValue("US");
  }
  hasError(event: any, frm: NgForm) {
    this.phoneValid = true;
    if (!event && frm.value.phoneNumber != "") {
      this.phoneValid = false;
    }
  }
  onContryChange(event: any, frm: NgForm) {
    frm.controls['phoneNumber'].setValue("");
    frm.controls['countryCode'].setValue(event.dialCode);
    frm.controls['countryISO'].setValue(event.iso2);
  }
  formatNumber(event: any, frm: NgForm) {
    var ciso = frm.value.countryISO.toString();
    ciso = ciso == "" ? "us" : ciso;
    frm.controls['phoneNumber'].setValue(new AsYouType(ciso.toUpperCase()).input(frm.value.phoneNumber));
    //console.log(new AsYouType(ciso.toUpperCase()).input(frm.value.phoneNumber));

  }

  onBrochureSubmit(frm: NgForm, brochType: string) {
    if (frm.invalid) {
      return;
    }
    if (!this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      return;
    }
    if (!this.phoneValid) {
      this.toaster.error("Phone Number is not valid");
      return;
    }

    frm.controls['phoneNumber'].setValue("+" + (frm.value.countryCode == "" ? "1" : frm.value.countryCode) + frm.value.phoneNumber);
    frm.controls['frompage'].setValue(brochType);
    console.log(frm.value);
    this.ctservice.postTalktoUsDetail().subscribe(
      res => {
        console.log(res['ctD_BrochURL']);
        if (res['ctD_FromPage'] != "Demo") {
          this.ctservice.sendBRochure(res['ctD_BrochURL'], res['ctD_Name'], res['ctD_Email'], res['ctD_FromPage']).subscribe(
            resp => {
              console.log(resp);
              this.hideModal(res['ctD_FromPage']);
            },
            err => {
              this.toaster.error("Failed to submit response", "");
              console.log(err);
            }
          );
          if(res['ctD_FromPage'] == 'PriceList'){

            this.toaster.success("Thank you for your interest.  JTSi GSA Pricelist is being sent per requested.", "");
          }
          else{
            this.toaster.success("Submitted. Thank you.", "");
          }

        }
        else{
          if(res['ctD_FromPage'] == 'PriceList'){

            this.toaster.success("Thank you for your interest.  JTSi GSA Pricelist is being sent per requested.", "");
          }
          else{
            this.toaster.success("Submitted. Thank you.", "");
          }
          this.hideModal(res['ctD_FromPage']);
        }
        this.resetBrochureForm(frm);

      },
      err => {
        this.toaster.error("Failed to submit response", "");
        console.log(err);
      }
    );
  }
  resetBrochureForm(frm: NgForm) {

    frm.controls['firstname'].reset();
    frm.controls['firstname'].setErrors(null);
    frm.controls['email'].reset();
    frm.controls['email'].setErrors(null);
    frm.controls['phoneNumber'].reset();
    frm.controls['phoneNumber'].setErrors(null);
    frm.controls['frompage'].reset();
    frm.controls['extn'].reset();

  }
  onFeedbackSubmit(frm: NgForm) {
    if (frm.invalid) {
      return;
    }
    this.fbservice.postFeedbackDetail().subscribe(
      res => {
        console.log(res);
        this.ctservice.sendFeedback(res['fB_Feedback']).subscribe(
          resp => {
            this.toaster.success("Submitted. Thank you", "");
            this.hideModal("feedback");
          },
          err => {
            this.toaster.error("Failed to submit feedback", "");
            console.log(err);
          }
        );
        frm.resetForm();
      },
      err => {
        this.toaster.error("Failed to submit feedback", "");
        console.log(err);
      }
    )
  }
  validateEmail(event: any, frm: NgForm, txt:any){
    if (frm.value.email!='' && !this.commonfunctions.validateEmail(frm.value.email)) {
      this.toaster.error("Email is not valid");
      document.getElementById(txt).focus();
      return;
    }

  }

  hideModal(frompopup: string) {

    if (frompopup == "Brochure")
      this.closeBrochure.nativeElement.click();
    else if (frompopup == "Microsoft")
      this.closeBrochureMC.nativeElement.click();
    else if (frompopup == "Digital")
      this.closeBrochureDG.nativeElement.click();
    else if (frompopup == "SAP")
      this.closeBrochureSAP.nativeElement.click();
    else if (frompopup == "PriceList")
      this.closeBrochurePrice.nativeElement.click();
    else if (frompopup == "feedback")
      this.closeFeedback.nativeElement.click();
      else if (frompopup == "Demo")
      this.closeBrochureDemo.nativeElement.click();
  }
}
