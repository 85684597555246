<div class="innerHeader mro">
  <div class="innerHeadings">
    <h1>
     MRO ERP
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/mro_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  MRO for Aviation
                </h2>
        </div>
<p>
  We offer one complete business management solution combining Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Supply Chain Management (SCM) and all other business application modules. We provide high quality software that is flexible enough to fit into any organization and working environment. JTSi eMRO is a comprehensive, fully integrated, cloud-based software solution that successfully manages the maintenance, engineering and logistics requirements of modern airlines and MRO providers. The solution concentrates on the Aviation and MRO industries that rely on complex, high value or sensitive assets. The solution integrates all aspects of operations with a complete suite of modules, including Quoting, Contract, Sales, Purchase, Inventory, Shop control, Pricing, MRO, Quality & Audit, and Asset management & Financials. The eMRO solution helps in turning a labor-intensive task measured in weeks or months into an automated procedure, often completed in hours or days.
</p>
        </div>
  </div>
</div>
</section>
  <section class="grayBg">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Main MRO Modules
                </h2>
        </div>
<ul class="bulletpoints colTwo">
  <li>	Administration </li>
<li>	Cost Estimation Inventory </li>
<li>	Shipping & Receiving </li>
<li>	Quality Assurance </li>
<li>	Resource Manager </li>
<li>	Accounting </li>
<li>	Workshop </li>
<li>	Repair Order </li>
<li>	Purchase </li>
<li>	Sales & Marketing </li>
<li>	Facility & Calibration </li>
<li>	Consignment & RM </li>
<li>	Customer Service</li>
</ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/mro_02.jpg" alt="">
      </div>
  </div>


  </div>
  </section>


  <section class="commonPadding">
    <div class="containers">
<div class="boxtitle_two">
  <h2>
    Key Highlights
  </h2>
</div>
  <ul class="bulletpoints colTwo">

<li>	Enables customers to efficiently track detailed business transactions</li>
<li>	Easy to track work in progress activities of the organization</li>
<li>	Handle the consignment stock & commission by integrating with sales</li>
<li>	Handle the detailed inspection of job and stock line item receive details</li>
<li>	Manage part sales and purchases using an integrated solution</li>
<li>	Large volume data transaction records with high data Security</li>
<li>	Auto Work Order generation flexibility</li>
<li>	Flexibility to create Repair Order based on Component/Piece parts</li>
<li>	Integration with third party vendors and service provider portals</li>
<li>	Detailed task wise work planning. (Main/Sub Work Order)</li>
<li>	Level based Bar Code Scanning functionality (BTN Scanning)</li>
<li>	Fully integrated with the Shipping Management module</li>
<li>	Notification for due dates, re-orders & unauthorized operations</li>
<li>	Use of PDA, Tablets, Smart phones for accessing the application</li>
<li>	Chat facility for better communication</li>
<li>	Import and export feature using PDF/Excel</li>
<li>	Dynamic report generation in the dashboard</li>
<li>	Generate, schedule and send reports via email</li>
<li>	Database Compatibility- SQL Server and Oracle</li>
<li>	Detailed Planning and Forecasting Modules</li>
<li>	Easy to track and prioritize AOG mode Works</li>
<li>	Automatic Material Request generation & Issue against approved MR</li>
<li>	Feature to Report Work Findings and Corrective actions in dashboard</li>
<li>	User rights based E-Signature approval functionality additions</li>
<li>	Flexibility to create Engineering Order for SB/AD/ R&D compilation</li>
<li>	Approval based transactions and data verification</li>
<li>	Complete audit trail capabilities</li>
<li>	Flexibility to access, refer, or print Overhaul Manual (OHM)</li>
<li>	Standard Help Manual and online Help Feature in the product</li>
<li>	Notifications using pop-ups, e-mail and online dashboard based</li>
<li>	XML based integration with other products to send/receive data</li>
<li>	Access, refer and print engineering instructions from application</li>

  </ul>

  </div>
  </section>
