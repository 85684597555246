import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TalktousService } from '../shared/talktous.service';
import { ToastrService } from 'ngx-toastr';

declare var require: any
const FileSaver = require("file-saver");

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  emailAdd: string = '';
  token: string = '';
  brochType: string = '';
  constructor(
    public ctservice: TalktousService,
    private route: ActivatedRoute,
    private toaster: ToastrService) { }

  ngOnInit(): void {

    this.emailAdd = this.route.snapshot.queryParamMap.get("emailaddress");
    this.token = this.route.snapshot.queryParamMap.get("token");
    this.brochType = this.route.snapshot.queryParamMap.get("brochType");
    console.log("email:", this.emailAdd);
    console.log("token:", this.token);
    console.log("brochType:", this.brochType);

    this.ctservice.downloadBrochure(this.emailAdd, this.token).subscribe(
      resp => {
        console.log('res4', resp)
        if (resp['error'] != '' || resp['error'] != null) {
          console.log("Response:", resp);
          if (resp['error'] != '' && resp['error'] != null) {
            
            var loadingif = document.getElementsByClassName("loadingif");
            loadingif.item(0).setAttribute("class","loadingif hide");
            var successDiv = document.getElementsByClassName("downloadSuccess");
            successDiv.item(0).setAttribute("class","downloadSuccess hidedownload");
            var expDiv = document.getElementsByClassName("downloadExpired");
            expDiv.item(0).setAttribute("class","downloadExpired");
            
          }
          else if (resp['message'] != '') {
              console.log("MEssage:", resp['message']);
              this.downloadPDF(this.brochType);
              var loadingif = document.getElementsByClassName("loadingif");
              loadingif.item(0).setAttribute("class","loadingif hide");
              var expDiv = document.getElementsByClassName("downloadExpired");
              expDiv.item(0).setAttribute("class","downloadExpired hidedownload");
              var successDiv = document.getElementsByClassName("downloadSuccess");
              successDiv.item(0).setAttribute("class","downloadSuccess ");
          }
        }
      },
      err => {
        //this.toaster.error("Failed to initiate download", "");
        
        var loadingif = document.getElementsByClassName("loadingif");
        loadingif.item(0).setAttribute("class","loadingif hide");
        var successDiv = document.getElementsByClassName("downloadSuccess");
        successDiv.item(0).setAttribute("class","downloadSuccess hidedownload");
        var expDiv = document.getElementsByClassName("downloadExpired");
        expDiv.item(0).setAttribute("class","downloadExpired");
        console.log(err);
      }
    );
  }

  downloadPDF(brochType: string) {
    if (this.brochType == "Brochure")
      FileSaver.saveAs("../assets/docs/Past%20Performance.pdf", "Past Performance");
    else if (this.brochType == "Microsoft")
      FileSaver.saveAs("../assets/docs/EMPOWERING-CROSS-AGENCY-COLLABORATION-BY-MICROSOFT.pdf", "Microsoft");
    else if (this.brochType == "Digital")
      FileSaver.saveAs("../assets/docs/Download%20file%20for%20Digital%20transformation.docx", "Digital");
    else if (this.brochType == "SAP")
      FileSaver.saveAs("../assets/docs/Create-Engaging-Employee-Centered-Experiences-with-Human-Experience-Management.pdf", "SAP");
      else if (this.brochType == "PriceList")
      FileSaver.saveAs("../assets/docs/0W8T6A.3RZ651_GS-35F-204CA_JTSIMASTEXTFILE2.PDF", "GSA PriceList");
  }

}
