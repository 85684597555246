<div class="innerHeader EnterprisePage">
  <div class="innerHeadings">
    <h1>
      Enterprise IT
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Enterprise_IT_sub_banner.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Enterprise IT
                </h2>
        </div>
       <p>
        JTSi helps organizations manage IT assets more cost-effectively. We provide clients with access to certified and experienced teams of tech experts to suit your organization’s needs. By implementing innovative solutions, we enable organizations to grow without compromising their data security. We make sure that your business meets information security standards with our robust security measures. Support services include troubleshooting and maintenance as well.
      </p><p>
        Partnering with JTSi boosts productivity and growth, thereby delivering tangible value. JTSi's enterprise IT services minimize disruptions, contributing to operational efficiency since system downtime reduces productivity. Additionally, you can implement new technologies faster and more efficiently with the help of experienced IT professionals.
       </p>
        </div>
  </div>
</div>
</section>
<section class="commonPadding grayBg">

<div class="containers homeGrid">

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../../assets/img/capabilities/Digital-Transformation.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Digital Transformation
      </h4>
      <a [routerLink]="['/capabilities/digital-transformation']" class="btn btn-md btn-primary" >Detailed View</a>
    </div>
  </div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Cybersecurity.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Cyber Security & IA
    </h4>
    <a [routerLink]="['/capabilities/cyber-security-and-ia']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Operations.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Operations & Maintenance (O&M)
    </h4>
    <a [routerLink]="['/capabilities/operations-and-maintenance']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Enterprise_Knowledge.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Enterprise Knowledge
Repository (EKR)
    </h4>
    <a [routerLink]="['/capabilities/ekr']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Application_Migration.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Data & Application
Migration
    </h4>
    <a [routerLink]="['/capabilities/data-and-application-migration']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Application_Modernization.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Application Modernization
    </h4>
    <a [routerLink]="['/capabilities/application-modernization']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Managed_IT_Services.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Managed IT Services
    </h4>
    <a [routerLink]="['/capabilities/managed-it-services']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>
<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Software_Factory.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Software Factory & COE
    </h4>
    <a [routerLink]="['/capabilities/software-factory-and-coe']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

</div>
</section>
