<div class="innerHeader AnalyticsPage">
  <div class="innerHeadings">
    <h1>
      Analytics & AI
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Analytics-&-AI_sub_banner.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Analytics & AI
                </h2>
        </div>
       <p>
        Today, analytics and AI are the great equalizer, where smaller organizations can create big presence and big companies can create intimate connections.  The opportunity for data, analytics and AI at scale is limitless in a world on unknowns. Data and AI provides unlimited opportunity and major competitive advantage. Our capabilities include robotic process automation to business analytics, to predictive, data visualizations, data warehousing and more help bring unprecedented insights to life and maximize the value of data and business impact.
       </p>
        </div>
  </div>
</div>
</section>
<section class="commonPadding grayBg">

<div class="containers homeGrid">

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Predictive_Analytics.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Predictive Analytics
    </h4>

    <a [routerLink]="['/capabilities/predictive-analytics']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Business_Intelligence.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Business
Intelligence/Data
Visualization
    </h4>

    <a [routerLink]="['/capabilities/business-intelligence-data-visualization']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Machine_Learning.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Machine Learning/Deep
Learning
    </h4>

    <a [routerLink]="['/capabilities/machine-learning-deep-learning']" class="btn btn-md btn-primary" >Detailed View</a>
  </div>
</div>


</div>
</section>
