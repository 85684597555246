<div class="innerHeader EngineeringPage">
  <div class="innerHeadings">
    <h1>
      Systems Engineering & Management
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Systems-Engineering-&-Management-Strategies_sub_banner.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Systems Engineering & Management
                </h2>
        </div>
       <p>
        Systems engineering and management (SEM) is an essential ingredient in high-tech industries. There is a growing need for engineering and management training in complex systems that have many interdependent parts and significant organizational and/or societal impact.
       </p>
        </div>
  </div>
</div>
</section>
<section class="commonPadding grayBg">

<div class="containers homeGrid">

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Systems_Engineering .jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Systems Engineering
    </h4>

    <a [routerLink]="['/capabilities/systems-engineering']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Product_Development.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Product Development
    </h4>

    <a [routerLink]="['/capabilities/product-development']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>

<div class="homeGrid-box">
  <div class="pictures">
    <img src="../../assets/img/capabilities/Product_Life_Cycle.jpg" alt="">
  </div>
  <div class="overlays">
    <h4>
      Product Life Cycle
Managment

    </h4>

    <a [routerLink]="['/capabilities/product-life-cycle-management']" class="btn btn-md btn-primary">Detailed View</a>
  </div>
</div>



</div>
</section>
