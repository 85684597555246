<div class="downloadWrap">
<div class="loadingif">
<img src="../../assets/img/loading_timer.gif" alt="">
</div>
<div class="downloadSuccess hidedownload">
  <article>
    <h1>
      Thank you for downloading our <span> brochure</span>
    </h1>
  </article>
  <picture>
<img src="../../assets/img/brochure_success_theme.png" alt="">
  </picture>
</div>

<div class="downloadExpired hidedownload">
  <article>
    <h1>
      This link is <span> expired</span>.
      <br>
      Please submit again for a
      <br>
       <span>new link</span>.
    </h1>
  </article>
  <picture>
<img src="../../assets/img/brochure_expired_theme.png" alt="">
  </picture>
</div>

</div>


<div class="downloadFoot">
  &copy; <a [routerLink]="['']"> 2021 JTSi USA</a> All Rights Reserved.
</div>

