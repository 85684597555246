import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appDisablethings]'
})
export class DisablethingsDirective {

  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event) {
  //   event.preventDefault();
  // }

  constructor() { }

}
