<div class="innerHeader hardware">
  <div class="innerHeadings">
    <h1>
     Hardware Integration & Embedded Software
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Hardware_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JTSi embedded systems
                </h2>
        </div>
        <p>
          JTSi is constantly evolving and expanding its offerings in the embedded systems domain and takes pride in partnering with the Warfighter across segments. JTSi has expertise in embedded systems software used to control the functioning of a set of hardware devices without compromising on the purpose or the efficiency. The software can be adjusted and calibrated per need, and the device can also be connected remotely or with other devices.
        </p>
        </div>
  </div>
</div>
</section>

  <section class="grayBg commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          JTSi Embedded <br> product lifecycle services
        </h2>
      </div>

      <h3 class="subtitles_one">
        New product development
      </h3>

      <p>
        Feasibility study, embedded software architecture, modeling, embedded systems design, embedded systems software engineering, embedded systems software programming, embedded software development systems, release management, and feature driven development.
      </p>
      <hr>

      <h3 class="subtitles_one">
        New product adaptation
      </h3>

      <p>
        We provide embedded system software services including redevelopment and reengineering of embedded software and systems, adaptation of latest technology, custom embedded system development, performance and reliability improvement, and porting to multiple platforms.
      </p>
      <hr>

      <h3 class="subtitles_one">
        Product sustenance & support
      </h3>

      <p>
        Among our services are lifecycle enhancements, defect tracking and fixing, feature enhancements, regression testing, and maintenance releases.
      </p>
      <hr>

      <h3 class="subtitles_one">
        Testing and verification
      </h3>

      <p>
        The testing services we provide include test plan design, embedded system design, test automation, automated scripting, and compliance and regression testing.
      </p>

</div>
  </section>

