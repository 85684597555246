<div class="innerHeader leverx_launch">
  <div class="innerHeadings">
    <h1>
      IQ Launch
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Improve and Accelerate Launch Management
      </h2>
      <h4>IQ Launch presents real-time data relevant to all roles involved in each stage of product innovation. All users can instantly access important readiness indicators related to their business role</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#bookmsub"></use>
            </svg>
           </span>
           Configurable Launch Readiness Indicators
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#sd_search"></use>
           </svg>
          </span>
          Track Single Product or Group Together
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#access_doc"></use>
            </svg>
           </span>
           Different Views or Scenarios for Different Roles
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#inu"></use>
            </svg>
           </span>
           Gain Insight into Your Live Data
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/launch_01.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/launch_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/launch_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/launch_04.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How IQ Launch Saves Hundreds of Project Hours
          </h2>
          <h4>IQ Launch delivers critical data to accomplish successful product launches. Reduce time needed to manage product introductions, while avoiding costly mistakes</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="4" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Provides one source of truth in a single cockpit viewed consistently by all user roles.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Make correct decisions faster to increase the speed of product updates and introductions.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Eliminates the need for roles to export data from SAP for project collaboration.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Improve product launch data accuracy to achieve overall cost targets.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Automated alerts eliminate the need to monitor projects manually.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_25.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Business Areas That Would Benefit from IQ Launch
      </h2>
      <h4>Below is a sample list of the business areas that would benefit from using IQ Launch.</h4>
    </div>
    <div class="borderd_four_col lesstwo">
<div class="borderd_col blue">
  <img src="../../../assets/img/launch_31.png" class="img-fluid" alt="...">
<h4>Management and Manufacturing</h4>
<ul class="ticklist">
  <li>Project Teams</li>
  <li>Product Management</li>
  <li>Manufacturing Operations</li>
  <li>Supply Chain</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/launch_32.png" class="img-fluid" alt="...">
  <h4>Organized Processes</h4>
  <ul class="ticklist">
    <li>Engineering </li>
    <li>Scheduling</li>
    <li>Finance</li>
    <li>Logistics </li>
    </ul>
  </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>IQ Launch offers the following key features and capabilities to support the product readiness process</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#monitor"></use>
                </svg>
               </span>
               Indicators to monitor the process
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#adherence"></use>
                </svg>
               </span>
               Adherence to your business workflow
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#ext_usable"></use>
                </svg>
               </span>
               Extended usability
            </button>

          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Indicators to monitor the process</h3>
              <ul class="ticklist">
                <li>Configurable indicators that can be tied to a specific business scenario</li>
                <li>Track status of indicators over time to see when they turned green, yellow, or red</li>
                <li>Configure indicators to match your stage gate process</li>
                <li>Indicators rollup to the top product level to indicate the overall status</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3>Adherence to your business workflow</h3>
              <ul class="ticklist">
                <li>Provides simple green, yellow, and red status indicators that are defined based on your business rules </li>
<li>Define the unlimited number of scenarios consisting of indicators tied to business roles</li>
<li>Trigger workflows based on indicators to notify the participants of the situations that are critical</li>
<li>Indicators can be created to pull data from external supplier sources for risk monitoring</li>
                </ul>
            </div>

            <div class="carousel-item">
              <h3>Extended usability</h3>
              <ul class="ticklist">
                <li>Simplified dashboards to view stage gate readiness </li>
<li>Export summaries to MS Excel as needed</li>
<li>Links to IQ Discussion to support collaboration</li>
<li>Organize product launches into meaningful groups for coordination</li>
                </ul>
            </div>
          </div>

        </div>
        </div>
      </div>
        </section>
