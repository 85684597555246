<div class="innerHeader Strategy">
  <div class="innerHeadings">
    <h1>
      Migration Strategy Development
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Strategy_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Digitally transform your business with Cloud Migration and Modernization
                </h2>
        </div>
        <ul class="bulletpoints">
        <li>	Migrate to a Private or Public cloud any App or Workloads.</li>
<li>	Modernize Apps and Data.</li>
<li>	Complete Security built into roots.</li>

      </ul>
        </div>
  </div>
</div>
</section>
  <section class="grayBg commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          JTSI’s Cloud Migrations experts <br> will help you achieve.
        </h2>
      </div>
<div class="four-cols lessOne">
<div class="colBox redbox">
<h4>
  More than 25% reduction of IT operational costs
</h4>
</div>
<div class="colBox redbox">
  <h4>
    Uncork above 18% of new revenue
  </h4>
  </div>
  <div class="colBox redbox">
    <h4>
      Improve critical end user experience metrics: availability, responsiveness, and usability
    </h4>
    </div>
</div>
</div>
  </section>

  <section class="commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          What cloud migration strategy
<br>
should enterprises adopt?
        </h2>
      </div>
      <div class="hilightstxt mb3">

  Gartner, a highly influential information technology research company, describes 5 options for organizations migrating to the cloud. These cloud migration strategies are commonly known as the "5 R's":

      </div>
<div class="borderd-grids threesome">
<div class="gridbox whiteBg">
<h3 class="subtitles_one">
  Rehost
</h3>
<p>
  Rehosting can be thought of as "the same thing, but on cloud servers". Companies that choose this strategy will select an IaaS (Infrastructure-as-a-Service) provider and recreate their application architecture on that infrastructure.
</p>
</div>
<div class="gridbox whiteBg">
  <h3 class="subtitles_one">
    Refactor
  </h3>
  <p>
    Companies that choose to refactor will reuse already existing code and frameworks, but run their applications on a PaaS (Platform-as-a-Service) provider's platform – instead of on IaaS, as in rehosting.
  </p>
  </div>
  <div class="gridbox whiteBg">
    <h3 class="subtitles_one">
      Revise
    </h3>
<p>
  This strategy involves partially rewriting or expanding the code base, then deploying it by either rehosting or refactoring (see above).
</p>
    </div>
    <div class="gridbox whiteBg">
      <h3 class="subtitles_one">
        Rebuild
      </h3>
      <p>
        To "rebuild" means rewriting and re-architecting the application from the ground up on a PaaS provider's platform. This can be a labor intensive process, but it also enables developers to take advantage of modern features from PaaS vendors.
      </p>
      </div>
      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Replace
        </h3>
        <p>
          Businesses can also opt to discard their old applications altogether and switch to already-built SaaS (Software-as-a-Service) applications from third-party vendors.
        </p>
        </div>
        <div class="gridbox whiteBg">

          </div>
</div>
</div>
  </section>


