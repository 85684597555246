import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor(
    // private titleService: Title,
    // private meta :Meta
  ) {

    // this.titleService.setTitle('test title 02');
    // this.meta.addTag({name:'test meta name', content:'test meta content 02'});
    // this.meta.addTag({name:'keywords', content:'hello hello3, again hello2'});
    // this.meta.addTag({name:'geo.region', content:'USA'});
    // this.meta.addTag({name:'geo.placename', content:'Washington Dc'});

   }

  ngOnInit(): void {
  }

}
