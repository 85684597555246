import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-migration-strategy',
  templateUrl: './migration-strategy.component.html',
  styleUrls: ['./migration-strategy.component.scss']
})
export class MigrationStrategyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
