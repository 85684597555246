import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-managed-it',
  templateUrl: './managed-it.component.html',
  styleUrls: ['./managed-it.component.scss']
})
export class ManagedITComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
