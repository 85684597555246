<div class="innerHeader careers">
  <div class="innerHeadings">
    <h1>
      Careers
    </h1>
  </div>
  </div>
  <div class="careerslink">
<a target="_blank" href="https://www2.jobdiva.com/portal/?a=uajdnw3iprsc9741ws20gvyd7dc8w702eewvyxhljmkdhpfk5rxortad1aoh9s3v#/">View Our Current Job Openings
  <small>Click Here &#8594;</small> </a>
  </div>
  <section class="commonPadding">
  <div class="containers">
    <div class="boxtitle_two">
      <h2>
        Make It A Career
      </h2>
    </div>
  <div class="topsection Tpadd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/careers_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Wellness Benefits
                </h2>
        </div>
        <p>
          We are dedicated to providing the best wellness programs in the industry, since wellness benefits are often more important than financial benefits for most people. To ensure our staff is satisfied with the benefits we offer, we have designed a comprehensive wellness program that covers much more than just general well-being.
        </p>
        </div>
  </div>
  <div class="four-cols">
    <div class="colBox redbox">
    <h4 class="txtblack">
      In and Out of Network Medical Plan Options
    </h4>
    </div>
    <div class="colBox redbox">
      <h4 class="txtblack">
        Prescription Plan
      </h4>
      </div>
      <div class="colBox redbox">
        <h4 class="txtblack">
          PPO Dental Plan
        </h4>
        </div>
        <div class="colBox redbox">
          <h4 class="txtblack">
            Orthodontics Plan
          </h4>
          </div>
          <div class="colBox redbox">
            <h4 class="txtblack">
              Competitive Paid Time Off Program
            </h4>
            </div>
            <div class="colBox redbox">
              <h4 class="txtblack">
                All Federal Holidays
              </h4>
              </div>


                  <div class="colBox redbox">
                    <h4 class="txtblack">
                      Paid Bereavement Days
                    </h4>
                    </div>
                    <div class="colBox redbox">
                      <h4 class="txtblack">
                        Military Leave
                      </h4>
                      </div>
                      <div class="colBox redbox">
                        <h4 class="txtblack">
                          Paid Jury Duty
                        </h4>
                        </div>

                      <div class="colBox redbox">
                        <h4 class="txtblack">
                          Long Term Disability
                        </h4>
                        </div>
                        <div class="colBox redbox">
                          <h4 class="txtblack">
                            Short Term Disability
                          </h4>
                          </div>
    </div>
</div>
</section>


  <section class="commonPadding grayBg">
    <div class="containers">
      <div class="topsection reverse Tpadd0">

        <div class="texts">
            <div class="heading redBg">
                    <h2>
                      Financial Benefits
                    </h2>
            </div>
            <p>
              We place a high value on our employees' financial well-being. It is important to us to make a positive impact on their lives. We offer attractive salary packages, and in addition to it, we offer other financial benefits that make up a comprehensive compensation package.
            </p>
            </div>
            <div class="picture">
              <img class="img-fluid" src="../../../assets/img/careers_02.jpg" alt="">
          </div>
      </div>

      <div class="four-cols">

        <div class="colBox redbox">
          <h4 class="txtblack">
            Roth and Traditional 401K Plans
          </h4>
          </div>

              <div class="colBox redbox">
                <h4 class="txtblack">
                  Annual Pay Raises
                </h4>
                </div>


                      <div class="colBox redbox">
                        <h4 class="txtblack">
                          Group Term Life Insurance
                        </h4>
                        </div>
                        <div class="colBox redbox">
                          <h4 class="txtblack">
                            Training and Development Plan
                          </h4>
                          </div>
                          <div class="colBox redbox">
                            <h4 class="txtblack">
                              Employer Contributed Health Savings Account
                            </h4>
                            </div>

        </div>

</div>
  </section>

  <section class="commonPadding">
    <div class="containers">
      <div class="topsection Tpadd0">

            <div class="picture">
              <img class="img-fluid" src="../../../assets/img/careers_03.jpg" alt="">
          </div>

        <div class="texts">
          <div class="heading redBg">
                  <h2>
                    Extra Perks
                  </h2>
          </div>
         <p>
          Our wellness and financial benefits are complemented with extra perks. Essentially, these things make life and work at JTSi that little bit easier and rewarding. A number of perks are available, from additional health benefits to recreational items to community service programs.
         </p>
          </div>
      </div>

      <div class="four-cols">
        <div class="colBox redbox">
        <h4 class="txtblack">
          Tuition Reimbursement
        </h4>
        </div>
        <div class="colBox redbox">
          <h4 class="txtblack">
            Employee Assistance Program
          </h4>
          </div>
          <div class="colBox redbox">
            <h4 class="txtblack">
              Limited Purpose FSA
            </h4>
            </div>
            <div class="colBox redbox">
              <h4 class="txtblack">
                Dependent Care FSA
              </h4>
              </div>

                <div class="colBox redbox">
                  <h4 class="txtblack">
                    Cell Phone Discount
                  </h4>
                  </div>

                    <div class="colBox redbox">
                      <h4 class="txtblack">
                        Holiday Parties
                      </h4>
                      </div>
                      <div class="colBox redbox">
                        <h4 class="txtblack">
                          Summer Parties
                        </h4>
                        </div>

                          <div class="colBox redbox">
                            <h4 class="txtblack">
                              Annual Banquets
                            </h4>
                            </div>

        </div>

</div>
  </section>

  <section class="commonPadding grayBg">
    <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/news_08.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JTSi Sponsored Annual SAP NS2 Solutions Summit
                </h2>
        </div>
<p>
  SAP NS2 held their Annual SAP NS2 Solutions Summit - Human Critical: Empowering People to Drive U.S. National Security.  JTSi was a sponsor for this event. The Agenda included a promotion of the SAP Analytics/BW/HANA training program run by SAP NS2 Serves to help Veterans transition successfully and productively from the Military back into the Civilian workforce. JTSi has demonstrated a strong commitment to and support for this program.  JTSi attended the Open House for the 22 upcoming graduates from the NS2 Serves Class, to meet and interview them for possible positions with JTSi’s Customers and Programs.  These are very motivated, dedicated and disciplined people with a passion for the subjects they have learnt in the Class, and a strong desire to work hard and use the full range of their experiences to excel in any role.
</p>
        </div>

  </div>

  </div>
  </section>
