
<router-outlet></router-outlet>


<ng-template ngFor let-config [ngForOf]="snowFlakes">
      
    <app-snow-flake
          [depth]="config.depth"
          [speed]="config.speed"
          [style.left.vw]="config.left"
    ></app-snow-flake>

</ng-template>