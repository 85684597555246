import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {HomeComponent} from './home/home.component';

import {ModernizationComponent} from './capability/modernization/modernization.component';
import {CapabilityComponent} from './capability/capability/capability.component';
import {IntelligenceComponent} from './capability/intelligence/intelligence.component';
import {DigitalTransformationComponent} from './capability/digital-transformation/digital-transformation.component';
import { AnalyticsComponent } from './capability/analytics/analytics.component';
import { CybersecurityComponent } from './capability/cybersecurity/cybersecurity.component';
import { MachineLearningComponent } from './capability/machine-learning/machine-learning.component';
import { DataMigrationComponent } from './capability/data-migration/data-migration.component';
import { OperationsComponent } from './capability/operations/operations.component';
import { ManagedITComponent } from './capability/managed-it/managed-it.component';
import { SoftwareFactoryComponent } from './capability/software-factory/software-factory.component';
import { EnterpriseKnowledgeComponent } from './capability/enterprise-knowledge/enterprise-knowledge.component';
import { MigrationStrategyComponent } from './capability/migration-strategy/migration-strategy.component';
import { EnterpriseArchitectureComponent } from './capability/enterprise-architecture/enterprise-architecture.component';
import { CloudServicesComponent } from './capability/cloud-services/cloud-services.component';
import { EtlComponent } from './capability/etl/etl.component';
import { SystemsEngineeringComponent } from './capability/systems-engineering/systems-engineering.component';
import { ProductDevelopmentComponent } from './capability/product-development/product-development.component';
import { ProductLifeCycleComponent } from './capability/product-life-cycle/product-life-cycle.component';

import { SolutionsComponent } from './products/solutions/solutions.component';
import { MroErpComponent } from './products/mro-erp/mro-erp.component';
import { SapInventoryComponent } from './products/sap-inventory/sap-inventory.component';
import { SurveyshieldComponent } from './products/surveyshield/surveyshield.component';
import { OfficegapsComponent } from './products/officegaps/officegaps.component';
import { IntegratedProcessComponent } from './products/integrated-process/integrated-process.component';
import { VeloxComponent } from './products/velox/velox.component';
import { IqdmsComponent } from './products/iqdms/iqdms.component';
import { IqpwcComponent } from './products/iqpwc/iqpwc.component';
import { IqlaunchComponent } from './products/iqlaunch/iqlaunch.component';
import { IqdiscussionComponent } from './products/iqdiscussion/iqdiscussion.component';
import { PaymenttgComponent } from './products/paymenttg/paymenttg.component';
import { BmaxComponent } from './products/bmax/bmax.component';

import { SupportServicesComponent } from './supportService/support-services/support-services.component';
import { C5ISRComponent } from './supportService/c5-isr/c5-isr.component';
import { HardwareIntegrationComponent } from './supportService/hardware-integration/hardware-integration.component';
import { EngineeringAnalysisComponent } from './supportService/engineering-analysis/engineering-analysis.component';
import { PrototypingComponent } from './supportService/prototyping/prototyping.component';
import { DefenseArmamentComponent } from './supportService/defense-armament/defense-armament.component';
import { PTCWindchillComponent } from './supportService/ptc-windchill/ptc-windchill.component';

import { SapComponent } from './sapService/sap/sap.component';
import { SapHanaComponent } from './sapService/sap-hana/sap-hana.component';
import { CloudMigrationComponent } from './sapService/cloud-migration/cloud-migration.component';
import { MobilityComponent } from './sapService/mobility/mobility.component';
import { MigrationMaintenanceComponent } from './sapService/migration-maintenance/migration-maintenance.component';
import { SuccessfactorsComponent } from './sapService/successfactors/successfactors.component';
import { HanasupportComponent } from './sapService/hanasupport/hanasupport.component';

import { AboutComponent } from './aboutUs/about/about.component';
import { VisionMissionComponent } from './aboutUs/vision-mission/vision-mission.component';
import { CorporateComponent } from './aboutUs/corporate/corporate.component';
import { QuickGlanceComponent } from './aboutUs/quick-glance/quick-glance.component';
import { CareersComponent } from './aboutUs/careers/careers.component';
import { PartnershipsComponent } from './aboutUs/partnerships/partnerships.component';
import { NewsComponent } from './aboutUs/news/news.component';
import { ResponsibilityComponent } from './aboutUs/responsibility/responsibility.component';
import { ContactComponent } from './aboutUs/contact/contact.component';

import { ContractsComponent } from './contracts/contracts.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

import { ThumbnailsComponent } from './thumbgrids/thumbnails/thumbnails.component';
import { EnterpriseComponent } from './thumbgrids/enterprise/enterprise.component';
import { CloudsComponent } from './thumbgrids/clouds/clouds.component';
import { EngineeringComponent } from './thumbgrids/engineering/engineering.component';
import { AnalyticsaiComponent } from './thumbgrids/analyticsai/analyticsai.component';
import { ProdgridComponent } from './thumbgrids/prodgrid/prodgrid.component';
import { WargridComponent } from './thumbgrids/wargrid/wargrid.component';
import { CapgridComponent } from './thumbgrids/capgrid/capgrid.component';
import { SapgridComponent } from './thumbgrids/sapgrid/sapgrid.component';
import { AbtgridComponent } from './thumbgrids/abtgrid/abtgrid.component';

import { KeydifferentComponent } from './keydifferent/keydifferent.component';

import { LayoutsComponent } from './layouts/layouts.component';
import { CommonpagesComponent } from './commonpages/commonpages.component';

import { LoginComponent } from './authentication/login/login.component';
import { AuthComponent } from './authentication/auth/auth.component';
import { DownloadsComponent } from './downloads/downloads.component';

import { GsalayoutComponent } from './gsalayout/gsalayout.component';
import { GsahomeComponent } from './gsalayout/gsahome/gsahome.component';
import { GsaserviceComponent } from './gsalayout/gsaservice/gsaservice.component';
import { GsaservicedetailComponent } from './gsalayout/gsaservicedetail/gsaservicedetail.component';
import { GsacontactComponent } from './gsalayout/gsacontact/gsacontact.component';
import { GsadeatilsComponent } from './gsalayout/gsadeatils/gsadeatils.component';

const routes: Routes = [

  {path: '',
  component: LayoutsComponent,
  children:[
    {path: '', component: HomeComponent},
  ]
},
  {path: '',
  component: LayoutsComponent,
  children:[
    {path: '',   redirectTo: '', pathMatch: 'full' },
    {path: '', component: CommonpagesComponent},
    {path: 'contracts', component: ContractsComponent},
    {path: 'privacy-policy', component: PrivacypolicyComponent},
    {path: 'disclaimer', component: DisclaimerComponent},
    {path: 'about-us', component: AbtgridComponent},
    {path: 'key-differentiators', component: KeydifferentComponent},
    {path: 'vision', component: VisionMissionComponent},
    {path: 'corporate-overview', component: CorporateComponent},
    {path: 'quick-glance-at-jtsi', component: QuickGlanceComponent},
    {path: 'careers', component: CareersComponent},
    {path: 'partnerships-relationships', component: PartnershipsComponent},
    {path: 'news', component: NewsComponent},
    {path: 'csr', component: ResponsibilityComponent},
    {path: 'contact-us', component: ContactComponent}
  ]
},
  // {path: 'about-us',
  // component: LayoutsComponent,
  // children:[
  //   {path: '',   redirectTo: 'about-us', pathMatch: 'full' },
  //   {path: '', component: AbtgridComponent},
  //   {path: 'vision', component: VisionMissionComponent},
  //   {path: 'corporate-overview', component: CorporateComponent},
  //   {path: 'quick-glance-at-jtsi', component: QuickGlanceComponent},
  //   {path: 'careers', component: CareersComponent},
  //   {path: 'partnerships-relationships', component: PartnershipsComponent},
  //   {path: 'news-life-at-jtsi', component: NewsComponent},
  //   {path: 'corporate-social-responsibility', component: ResponsibilityComponent},
  //   {path: 'contact-us', component: ContactComponent}
  // ]},

  {path: 'capabilities',
  component: LayoutsComponent,
  children:[
    { path: '',   redirectTo: 'capabilities', pathMatch: 'full' },
    {path: '', component: CapgridComponent},
    {path: 'business-intelligence-data-visualization', component: IntelligenceComponent},
    {path: 'digital-transformation', component: DigitalTransformationComponent},
    {path: 'application-modernization', component: ModernizationComponent},
    {path: 'predictive-analytics', component: AnalyticsComponent},
    {path: 'cyber-security-and-ia', component: CybersecurityComponent},
    {path: 'machine-learning-deep-learning', component: MachineLearningComponent},
    {path: 'data-and-application-migration', component: DataMigrationComponent},
    {path: 'operations-and-maintenance', component: OperationsComponent},
    {path: 'managed-it-services', component: ManagedITComponent},
    {path: 'software-factory-and-coe', component: SoftwareFactoryComponent},
    {path: 'ekr', component: EnterpriseKnowledgeComponent},
    {path: 'migration-strategy-development', component: MigrationStrategyComponent},
    {path: 'enterprise-architecture', component: EnterpriseArchitectureComponent},
    {path: 'private-public-hybrid-cloud-services', component: CloudServicesComponent},
    {path: 'data-migration-etl', component: EtlComponent},
    {path: 'systems-engineering', component: SystemsEngineeringComponent},
    {path: 'product-development', component: ProductDevelopmentComponent},
    {path: 'product-life-cycle-management', component: ProductLifeCycleComponent},
    {path: 'enterprise-it', component: EnterpriseComponent},
    {path: 'cloud-adoption-and-optimization', component: CloudsComponent},
    {path: 'systems-engineering-and-management', component: EngineeringComponent},
    {path: 'analytics-ai', component: AnalyticsaiComponent}
  ]},

  {path: 'products-solutions',
  component: LayoutsComponent,
  children:[
    { path: '',   redirectTo: 'products-solutions', pathMatch: 'full' },
    {path: '', component: ProdgridComponent},
    {path: 'mro-erp', component: MroErpComponent},
    {path: 'sap-inventory-management-s4-ewm', component: SapInventoryComponent},
    {path: 'surveyshield', component: SurveyshieldComponent},
    // {path: 'officegaps', component: OfficegapsComponent},
    {path: 'integrated-process-solution', component: IntegratedProcessComponent}
    // {path: 'VeloX-Software', component: VeloxComponent},
    // {path: 'IQ-DMS', component: IqdmsComponent},
    // {path: 'IQ-Product-Work-Center', component: IqpwcComponent},
    // {path: 'IQ-Launch', component: IqlaunchComponent},
    // {path: 'IQ-Discussion', component: IqdiscussionComponent},
    // {path: 'payment-terms-governance', component: PaymenttgComponent},
    // {path: 'bom-material-allocation-extension', component: BmaxComponent}
  ]},

  {path: 'war',
  component: LayoutsComponent,
  children:[
    { path: '',   redirectTo: 'support-services', pathMatch: 'full' },
    {path: '', component: WargridComponent},
    {path: 'c5isr-solution', component: C5ISRComponent},
    {path: 'hardware-integration-and-embedded-software', component: HardwareIntegrationComponent},
    //{path: 'engineering-analysis', component: EngineeringAnalysisComponent},
    {path: 'prototyping-and-3d-modelling', component: PrototypingComponent},
    {path: 'defense-armament-engineering-services', component: DefenseArmamentComponent},
    {path: 'ptc-windchill-and-ips-integration', component: PTCWindchillComponent}
  ]},

  {path: 'sap',
  component: LayoutsComponent,
  children:[
    { path: '',   redirectTo: 'sap', pathMatch: 'full' },
    {path: '', component: SapgridComponent},
    {path: 'sap-hana-migration', component: SapHanaComponent},
    {path: 'cloud-migration-and-csp', component: CloudMigrationComponent},
    {path: 'mobility-and-iot', component: MobilityComponent},
    {path: 'application-migration-and-maintenance', component: MigrationMaintenanceComponent},
    {path: 'successfactors-support', component: SuccessfactorsComponent},
    {path: 's4hana-migration-and-support', component: HanasupportComponent}
  ]},

  // {path: 'thumbnails',
  // component: LayoutsComponent,
  // children:[
  //   { path: '',   redirectTo: 'thumbnails', pathMatch: 'full' },
  //   {path: '', component: ThumbnailsComponent},
  //   // {path: 'enterprise-it', component: EnterpriseComponent},
  //   // {path: 'cloud-adoption', component: CloudsComponent},
  //   // {path: 'systems-engineering', component: EngineeringComponent},
  //   // {path: 'Analytics-AI', component: AnalyticsaiComponent},
  //   //{path: 'AboutUs', component: AbtgridComponent},
  //   //{path: 'Capabiltites-pages', component: CapgridComponent},
  //   //{path: 'War-Support-pages', component: WargridComponent},
  //   //{path: 'Sap-Service-pages', component: SapgridComponent},
  //   //{path: 'Products-Solutions', component: ProdgridComponent}
  // ]},

  {path: '',
  component: AuthComponent,
  children:[
    {path: '',   redirectTo: '', pathMatch: 'full' },
    {path: 'login', component: LoginComponent}
  ]},
  {path: '',
  component: DownloadsComponent,
  children:[
    {path: '',   redirectTo: '', pathMatch: 'full' },
    {path: 'downloads', component: DownloadsComponent}
  ]},
  {path: 'gsa518210c',
  component: GsalayoutComponent,
  children:[
    {path: '',   redirectTo: 'gsa518210c', pathMatch: 'full' },
    {path: '', component: GsahomeComponent},
    {path: 'rates', component: GsaserviceComponent},
    {path: 'contact', component: GsacontactComponent},
    {path: 'gsaservicedetails', component: GsaservicedetailComponent}

    // children:[
    //   {path: 'rates', component: GsaserviceComponent},
    //   {path: 'serviceedetails', component: GsaservicedetailComponent},
    //   {path: 'contact', component: GsacontactComponent}
    // ]},
  ]},
  // { path: '',   redirectTo: '/login', pathMatch: 'full' },
  // { path: '**', component: LoginpageComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    // scrollPositionRestoration:'enabled',
    // anchorScrolling: "enabled",
    // scrollOffset: [0, 160]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
