import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defense-armament',
  templateUrl: './defense-armament.component.html',
  styleUrls: ['./defense-armament.component.scss']
})
export class DefenseArmamentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
