<div class="innerHeader responsibilty">
  <div class="innerHeadings">
    <h1>
      Corporate Social Responsibility (CSR)
    </h1>
  </div>
  </div>

  <section class="commonPadding ">
    <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/holy_name.jpg" alt="">
        <div class="thumbdetails">
  <h3 class="subtitles_two">
    JTSi supports and contributes towards The Holy Name Medical Center Foundation
  </h3>
  <p>
    JTSi proudly supports Holy Name Foundation in its mission to provide technologically advanced, compassionate, and personalized care to a diverse population in New Jersey that encompasses clinical research, education, prevention, diagnosis, treatment, rehabilitation and wellness maintenance.
  </p>

        </div>
      </div>
      </div>
    <div class="grayBg">
    <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/feedingamerica.jpg" alt="">
        <div class="thumbdetails">
  <h3 class="subtitles_two">
    Working Together to End Hunger
  </h3>
  <p>
    JTSi is a regular contributor.  The Feeding America network is the nation’s largest domestic hunger-relief organization, working to connect people with food and end hunger. Donors, staff, and volunteers all play an important role in our efforts to end hunger in the United States.
  </p>

        </div>
      </div>
      </div>
    </div>

      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/responsibilty_03.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi is committed to making a positive impact on the world by taking care of our people and communities in which we serve
    </h3>
  <p>
    Social responsibility is an important component of our business because it allows us to better work, connect, innovate, and impact the world around us. We believe it is our duty to contribute to making the world a safer and more sustainable place.
  </p>
          </div>
        </div>
        </div>

        <div class="grayBg">
      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/responsibilty_02.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi is proud to contribute towards The Defense Logistics Agency Foundation
    </h3>
    <p>
      Johnson Technology System, Inc is committed to generosity and helping those that need it most. JTSi consistently delivers on its promises and exceeds expectations. The Defense Logistics Agency (DLA) Foundation has held several fundraisers and awarded their first round of scholarships in 2014. The Foundation’s primary focus is to award scholarships to those most deserving. They have awarded 170 scholarships since their inception. Donations like ours from JTSi has allowed them to continue providing service to the community through scholarships and other critical programs. JTSi agrees with DLA’s motto of “day to do good” and encourages everyone to their small part.
    </p>
          </div>
        </div>
        </div>
      </div>

      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/hero_hope.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi is supporter and contributor of the Brad Kaminsky Foundation
    </h3>
    <p>
      JTSi is supporter and contributor of the Brad Kaminsky Foundation. The Brad Kaminsky Foundation is
Dedicated to Raising Awareness and Funds for Childhood & Adult Brain Tumor and Cancer Research.
    </p>

          </div>
        </div>
        </div>


    <div class="grayBg">
      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/womand.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi has been a strong contributor/supporter of Picatinny chapter of Women in Defense (WID).
    </h3>

          </div>
        </div>
        </div>
      </div>

    <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/monw.jpg" alt="">
        <div class="thumbdetails">
          <h3 class="subtitles_two">
            JTSi Is Proud To Be A Part Of Meals on Wheels America.
  </h3>
  <p>
    JTSi supports Meals on Wheels America in its mission to empower local community programs to improve the health and quality of life of the seniors they serve so that no one is left hungry or isolated.
   </p>
        </div>
      </div>
      </div>
      <div class="grayBg">
  <div class="containers">
    <div class="smallthubs_01">
      <img class="img-fluid" src="../../../assets/img/CSR-Badge.jpg" alt="">
      <div class="thumbdetails">
        <h3 class="subtitles_two">
  JTSi is a part of Helping hand for George's family.
</h3>
<p>
  JTSi has joined hands in providing support to George’s family through donations towards St. Thomas Church Phoenix AZ.
 </p>
      </div>
    </div>
    </div>
  </div>
  <div class="containers">
    <div class="smallthubs_01">
      <img class="img-fluid" src="../../../assets/img/responsibilty_04.jpg" alt="">
      <div class="thumbdetails">
        <h3 class="subtitles_two">
  JTSi is proud to be part of Get Movin' - FUNdraising Experts.
</h3>
<p>
  JTSi has contributed to St. Marks Catholic School, 9972 Vale Rd. through Get Movin’ Fundraising - an organization dedicated to helping you raise and keep more with your fundraiser.
</p>
      </div>
    </div>
  </div>
  <div class="grayBg">
    <div class="containers">
    <div class="smallthubs_01">
      <img class="img-fluid" src="../../../assets/img/responsibilty_05.jpg" alt="">
      <div class="thumbdetails">
<h3 class="subtitles_two">
  JTSi is proud to be a part of Project Help Inc.
</h3>
<p>
  JTSi proudly supports and contributes towards Project Help Inc. Project Help is a support system for veterans and their families who need help during and after their transition from military to civilian life.
 </p>
      </div>
    </div>
    </div>

  </div>

  <div class="containers">
    <div class="smallthubs_01">
      <img class="img-fluid" src="../../../assets/img/responsibilty_06.jpg" alt="">
      <div class="thumbdetails">
        <h3 class="subtitles_two">
          JTSi was a contributor to Warrior
</h3>
<p>
  <strong>JTSi was a contributor to Warrior</strong>’ Inaugural Golf Outing held on Monday, October 7, 2019 at Picatinny Golf Club. The event was a success with 80 golfers and netted almost $8,000 to help our veterans!
 </p>
      </div>
    </div>
    </div>

    <div class="grayBg">
      <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/responsibilty_07.jpg" alt="">
        <div class="thumbdetails">
  <h3 class="subtitles_two">
    JTSi supports the Wounded Warrior Project
  </h3>
  <p>
  <strong> In response to our contribution, WPP replied </strong> – “Your generous support of WWP will change the lives of over 1 million injured warriors, caregivers, and family members. You will help bring independence back to our nation’s most severely wounded veterans. Watch the video to see the impact of your support in action. <a href="https://www.woundedwarriorproject.org/dr/thanks" target="_blank" rel="noopener noreferrer">www.woundedwarriorproject.org</a>
  </p>
        </div>
      </div>
      </div>

    </div>

    <div class="containers">
      <div class="smallthubs_01">
        <img class="img-fluid" src="../../../assets/img/responsibilty_08.jpg" alt="">
        <div class="thumbdetails">
          <h3 class="subtitles_two">
            JTSi is a regular supporter of  St. Jude Children’s Research Hospital
  </h3>
  <p>
    <strong>JTSi is a regular supporter of  St. Jude Children’s Research Hospital</strong>. St. Jude is leading the way the world understands, treats and defeats childhood cancer and other life-threatening diseases.
  </p>
        </div>
      </div>
      </div>

      <div class="grayBg">
        <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/responsibilty_09.jpg" alt="">
          <div class="thumbdetails">
    <h3 class="subtitles_two">
      JTSi is supporter of St. Paul VI Catholic High School
    </h3>

          </div>
        </div>
        </div>

      </div>

      <div class="containers">
        <div class="smallthubs_01">
          <img class="img-fluid" src="../../../assets/img/responsibilty_10.jpg" alt="">
          <div class="thumbdetails">
            <h3 class="subtitles_two">
              JTSi supports the many ministries of St. Mark Catholic Church.
    </h3>

          </div>
        </div>
        </div>

        <div class="grayBg">
          <div class="containers">
          <div class="smallthubs_01">
            <img class="img-fluid" src="../../../assets/img/responsibilty_11.jpg" alt="">
            <div class="thumbdetails">
      <h3 class="subtitles_two">
        JTSi contributed to Virginia Commonwealth University’s Student Emergency Fund
      </h3>
      <p>
       <strong> JTSi contributed to Virginia Commonwealth University’s Student Emergency Fund</strong>. This support will provide critical resources to currently enrolled students who are experiencing unexpected, short-term financial hardship, so that students can focus on pursuing their education rather than worrying about finances.
      </p>

            </div>
          </div>
          </div>

        </div>

        <div class="containers">
          <div class="smallthubs_01">
            <img class="img-fluid" src="../../../assets/img/responsibilty_12.jpg" alt="">
            <div class="thumbdetails">
              <h3 class="subtitles_two">
                JTSi is part of the fund raiser to help Toni with the extensive cost of her care since her stroke.
      </h3>

            </div>
          </div>
          </div>

          <div class="grayBg">
            <div class="containers">
                   <div class="smallthubs_01">
                     <img class="img-fluid" src="../../../assets/img/child_foundation-.jpg" alt="">
                     <div class="thumbdetails">
                       <h3 class="subtitles_two">
                         JTSi is a long-standing active supporter of Mother & Child Foundation
               </h3>
            <p>
             Mother and Child Foundation (MCF), established in 2000, is a charitable institution accommodating abandoned and neglected children, youths, and destitute mothers irrespective of any social divisions/discriminators.
            </p>
                     </div>
                   </div>
                   </div>
               </div>


          <div class="containers">
            <div class="smallthubs_01">
              <img class="img-fluid" src="../../../assets/img/divyarakhshalayam.jpg" alt="">
              <div class="thumbdetails">
                <h3 class="subtitles_two">
                  JTSi at Divyarakhshalayam – WORLD DAY OF THE POOR - 2021
        </h3>
        <p>
          JTSi sponsored the event and also contributed JTSi branded shirts and pants to over 1000 socially challenged residents that include teenage mothers, Children and abandoned parents.  Thanks for your relentless support and the support of all our customers who makes such CSR initiatives possible for  JTSi.  The program was presided by Rev. George Alencherry, Major Archbishop (Father and Head) of the Syro-Malabar Church and also a cardinal of the Catholic Church in India.
        </p>

              </div>
            </div>
            </div>

            <div class="grayBg">
            <div class="containers">
            <div class="smallthubs_01">
              <img class="img-fluid" src="../../../assets/img/fisherhouse.jpg" alt="">
              <div class="thumbdetails">
        <h3 class="subtitles_two">
          Fisher House Foundation, Inc
        </h3>
        <p>
         <strong> A Little Kindness Can Change Everything </strong>. Fisher House Foundation builds comfort homes where military & veteran families can stay free of charge, while a loved one is in the hospital. JTSi is proud to be part of the Fisher House Foundation.
        </p>

              </div>
            </div>
            </div>
            </div>


  </section>
