<div class="innerHeader Operations">
  <div class="innerHeadings">
    <h1>
    Operations & Maintenance (O&M)
    </h1>
  </div>
  </div>

  <div class="containers">
  <div class="topsection BpaddSmall">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Operations_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Operations & Maintenance
                </h2>
        </div>
        <p>
          JTSi assists DOD and other federal agencies to keep their IT systems running smoothly by managing change, monitoring performance, and supporting users. Our highly trained IT professionals can easily shift between cutting-edge technology and legacy systems. In support of IT operations and maintenance, we offer the following services for our clients.
        </p>
        </div>
  </div>

  <ul class="bulletpoints colThree">
    <li>	Help Desk support </li>
<li>	Tier 1, 2, 3, & 4 support </li>
<li>	Network and systems administration </li>
<li>	Database administration</li>
<li>	Application and hardware support </li>
<li>	Configuration management </li>
<li>	Software maintenance and upgrades</li>
<li>	IT infrastructure optimization</li>
<li>	WAN/LAN operation support </li>
<li>	Data backups</li>

  </ul>
</div>
  <section class="commonMargin grayBg">
    <div class="containers">

      <div class="topsection reverse padd0">
        <div class="texts">
            <div class="heading redBg">
                    <h2>
                      JTSi’s IT O&M division delivers the following benefits to our clients
                    </h2>
            </div>
            <ul class="bulletpoints">
          <li>Reliable, on-time and cost-efficient help desk support. </li>
    <li>	Immediate accessibility to new software functionality through rapid technology insertion. </li>
    <li>	Resolution to complex issues with minimal to no disruption of operations, service delivery, or functionality.</li>

        </ul>
            </div>
            <div class="picture">
              <img class="img-fluid" src="../../../assets/img/Operations_02.jpg" alt="">
          </div>
      </div>

      </div>
      </section>




