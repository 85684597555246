<header class="navbar">
    <div class="logo">
      <a [routerLink]="['']" >
      <div class="mainlogo">
      <img src="../../assets/img/JTSi-Logo.svg" alt="">
      <div class="logotxt">
        <ul>
         <li>People </li>
         <li>Process </li>
          <li>Technology</li>
        </ul>
      <p>US Govt. Prime Contractor</p>
    </div>
    </div>
    </a>
    </div>
    <div class="headercontent">
    <div class="topHeader">

    <div class="contactnumbers">
      <!-- <button class="betav btn btn-secondary" data-bs-toggle="modal" data-bs-target="#lookAround">Beta Version

        <span class="tiptxt">Click to report error</span>

      </button> -->

      <ul>
        <li>

            <svg>
              <use xlink:href="../../assets/img/sprite.svg#usflag"></use>
            </svg>
           <a href="tel:+17039499100"> +1 (703)949-9100</a>

        </li>
      </ul>
      <a [routerLink]="['/login']" class="userLogin">
        Login
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#userlog"></use>
        </svg>
      </a>
    </div>
    <div class="awardpoints">
      <!-- <img class="testribbon" src="../../assets/img/mourning_strip.jpg" alt="" data-bs-toggle="modal" data-bs-target="#eventModal"> -->

      
<ul>
<li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	DCAA Approved</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	CRADA </a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyOne">	ISO 9001:2015*</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	SAP Partner</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	ITAR Compliant</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyTwo">	FSO</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyFive">	ISO 27001:2013*</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyThree">	GSA MAS</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyThree">	GSA MAS 518210c</a> </li>
<li><a [routerLink]="['/key-differentiators']" fragment="keyFive">ISO/IEC 20000-1:2018 </a></li>
      </ul>

      <div class="partlogos">
        <a [routerLink]="['/key-differentiators']" fragment="KeyFour">
          <img src="../../assets/img/footlogos/logo1.jpg" alt="">
        </a>
        <a [routerLink]="['/key-differentiators']" fragment="keyThree">
          <img src="../../assets/img/footlogos/logo2.jpg" alt="">
        </a>
        <a [routerLink]="['/key-differentiators']" fragment="keyFive">
          <img src="../../assets/img/footlogos/logo4.jpg" alt="">
          </a>
      </div>
    </div>
  </div>
    <nav class="topNav">
      <ul class="topNav-menu" id="navmenu">
        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/about-us']">About Us</a>
          <div class="dropDownBox">
            <ul>
              <li><a [routerLink]="['/vision']">Vision | Mission | Core Value</a></li>
            <li><a [routerLink]="['/corporate-overview']">Corporate Overview</a></li>
            <li><a [routerLink]="['/quick-glance-at-jtsi']">A Quick Glance@JTSi</a></li>
            <li><a [routerLink]="['/news']">News</a></li>
            <li><a [routerLink]="['/csr']">Corporate Social Responsibility </a></li>
            <!-- <li><a [routerLink]="['/Officegaps']">Leadership Desk</a></li>
            <li><a [routerLink]="['/Officegaps']">Our Team</a></li> -->
            <li><a [routerLink]="['/careers']">Careers</a></li>
            <li><a [routerLink]="['/partnerships-relationships']">Partnerships/Relationships</a></li>
            <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
          </ul>
          </div>
          </li>

        <li class="dropDowns largeBox" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/capabilities']" >Capabilities</a>
          <div class="dropDownBox largeColumn">
            <div class="division">
            <h4><a [routerLink]="['/capabilities/enterprise-it']" >Enterprise IT</a></h4>
              <ul>
              <li><a [routerLink]="['/capabilities/digital-transformation']">Digital Transformation</a></li>
              <li><a [routerLink]="['/capabilities/cyber-security-and-ia']">Cyber Security & IA</a></li>
              <li><a [routerLink]="['/capabilities/operations-and-maintenance']">Operations & Maintenance (O&M)</a></li>
              <li><a [routerLink]="['/capabilities/ekr']">Enterprise Knowledge Repository (EKR)</a></li>
              <li><a [routerLink]="['/capabilities/data-and-application-migration']">Data & Application  Migration </a></li>
              <li><a [routerLink]="['/capabilities/application-modernization']">Application Modernization</a></li>
              <li><a [routerLink]="['/capabilities/managed-it-services']">Managed IT Services</a></li>
              <li><a [routerLink]="['/capabilities/software-factory-and-coe']">Software Factory & COE</a></li>
              </ul>
            </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/cloud-adoption-and-optimization']" >Cloud Adoption & Optimization</a></h4>
              <ul>
                <li><a [routerLink]="['/sap/cloud-migration-and-csp']">Cloud Migration & CSP </a></li>
                <li><a [routerLink]="['/capabilities/migration-strategy-development']">Migration Strategy Development</a></li>
                <li><a [routerLink]="['/capabilities/enterprise-architecture']">Enterprise Architecture (EA)</a></li>
                <li><a [routerLink]="['/capabilities/private-public-hybrid-cloud-services']">Private/Public/Hybrid Cloud Services</a></li>
                <li><a [routerLink]="['/capabilities/data-migration-etl']">Data Migration / ETL</a></li>
            </ul>
            </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/analytics-ai']" >Analytics & AI</a></h4>
              <ul>
               <li><a [routerLink]="['/capabilities/predictive-analytics']">Predictive Analytics</a></li>
               <li><a [routerLink]="['/capabilities/business-intelligence-data-visualization']">Business Intelligence/Data Visualization</a></li>
               <li><a [routerLink]="['/capabilities/machine-learning-deep-learning']">Machine Learning/Deep Learning</a></li>
            </ul>
          </div>

            <div class="division">
              <h4><a [routerLink]="['/capabilities/systems-engineering-and-management']" >Systems Engineering & Management </a></h4>
              <ul>
                <li><a [routerLink]="['/capabilities/systems-engineering']">Systems Engineering</a></li>
                <li><a [routerLink]="['/capabilities/product-development']">Product Development</a></li>
                <li><a [routerLink]="['/capabilities/product-life-cycle-management']">Product Life Cycle Management (PLM)</a></li>
            </ul>
          </div>

          </div>
        </li>

        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/products-solutions']" >Products/Solutions</a>
          <div class="dropDownBox">
            <ul>
            <li><a [routerLink]="['/products-solutions/mro-erp']">MRO ERP</a></li>
            <li><a [routerLink]="['/products-solutions/sap-inventory-management-s4-ewm']">SAP Inventory Management (S4/EWM)</a></li>
            <li><a [routerLink]="['/products-solutions/surveyshield']">SurveyShield (Web Services)</a></li>
            <!-- <li><a [routerLink]="['/products-solutions/officegaps']">OfficeGaps (SaaS)</a></li> -->
            <li><a [routerLink]="['/products-solutions/integrated-process-solution']">Integrated Process Solution (IPS)</a></li>
            <!-- <li><a [routerLink]="['/products-solutions/VeloX-Software']">VeloX Software Suite</a></li>
            <li><a [routerLink]="['/products-solutions/IQ-DMS']">IQ DMS</a></li>
            <li><a [routerLink]="['/products-solutions/IQ-Product-Work-Center']">IQ Product Work Center</a></li>
            <li><a [routerLink]="['/products-solutions/IQ-Launch']">IQ Launch</a></li>
            <li><a [routerLink]="['/products-solutions/IQ-Discussion']">IQ Discussion</a></li>
            <li><a [routerLink]="['/products-solutions/payment-terms-governance']">Payment Terms Governance</a></li>
            <li><a [routerLink]="['/products-solutions/bom-material-allocation-extension']">BOM / Material Master Extension & Allocation Tool (BMAX)</a></li> -->
          </ul>
          </div>
          </li>

        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/war']" >War fighter Support Services</a>
          <div class="dropDownBox">
            <ul>
            <li><a [routerLink]="['/war/c5isr-solution']">C5ISR</a></li>
            <li><a [routerLink]="['/war/hardware-integration-and-embedded-software']">Hardware Integration & Embedded Software</a></li>
            <!-- <li><a [routerLink]="['/war/engineering-analysis']">Engineering and Analysis</a></li> -->
            <li><a [routerLink]="['/war/prototyping-and-3d-modelling']">Prototyping & 3D Modelling</a></li>
            <li><a [routerLink]="['/war/defense-armament-engineering-services']">Defense Armament Engineering Services</a></li>
            <li><a [routerLink]="['/war/ptc-windchill-and-ips-integration']">PTC - Windchill & IPS Integration</a></li>
          </ul>
          </div>
        </li>
        <li class="dropDowns" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/sap']" >SAP Services</a>
          <div class="dropDownBox">
            <ul>
              <!-- <li><a [routerLink]="['/products-solutions/sap-Inventory']">SAP Inventory Management (S4/EWM)</a></li> -->
            <li><a [routerLink]="['/sap/cloud-migration-and-csp']">Cloud Migration & CSP </a></li>
            <li><a [routerLink]="['/capabilities/enterprise-architecture']">Enterprise Architecture (EA)</a></li>
            <li><a [routerLink]="['/sap/mobility-and-iot']">Mobility & IOT</a></li>
            <li><a [routerLink]="['/sap/application-migration-and-maintenance']">Application Migration & Maintenance</a></li>
            <li><a [routerLink]="['/sap/successfactors-support']">Successfactors Support</a></li>
            <li><a [routerLink]="['/sap/s4hana-migration-and-support']">S4/Hana Migration & Support</a></li>
          </ul>
          </div>
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/contracts']" >Contracts</a>
        </li>
        <div class="closeMenu" id="closeMenu">
          <svg>
            <use xlink:href="../../assets/img/sprite.svg#closeIcon"></use>
          </svg>
        </div>
      </ul>
<div class="endnav">
  <!-- <img class="mtestribbon" src="../../assets/img/Mourning_header.jpg" alt="" data-bs-toggle="modal" data-bs-target="#eventModal"> -->
  <button class="menuBtn">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#threeLines"></use>
    </svg>
  </button>
    <button class="corporate">
      <svg>
        <use xlink:href="../../assets/img/sprite.svg#threeLines"></use>
      </svg>
    </button>
  </div>

</nav>
</div>

    </header>

    <div class="slidebar" id="slidebar">
      <h3>
        Corporate
        <span class="closeBar" id="closeBar"> &#10005; </span>
      </h3>
      <ul>
        <li><a [routerLink]="['/corporate-overview']">Corporate Overview </a></li>
        <li><a [routerLink]="['/capabilities/enterprise-it']">Enterprise IT</a></li>
        <li><a [routerLink]="['/capabilities/analytics-ai']">Analytics & AI</a></li>
        <li><a [routerLink]="['/products-solutions/mro-erp']">MRO ERP</a></li>
        <li><a [routerLink]="['/sap']">SAP Services</a></li>
        <li><a [routerLink]="['/news']">News</a></li>
        <li><a [routerLink]="['/careers']">Careers</a></li>
      </ul>
    </div>

<!-- <app-decorationlight></app-decorationlight> -->

<!-- <div class="modal fade" id="eventModal" tabindex="-1" aria-labelledby="eventModalLabel" 
aria-hidden="true" style="background-color: #0000004d;">
  <div class="modal-dialog modal-dialog-centered modal-lg">
  <div class="modal-content darryl">
    <div class="modal-header">

    <button type="button" class="modalclose"  data-bs-dismiss="modal" aria-label="Close" >
      <svg>
        <use xlink:href="../../assets/img/sprite.svg#modalclose"></use>
      </svg>
    </button>
    </div>
    <div class="onloadbody">

    <article>
      <h2>
       <strong>JTSi </strong> expresses our deepest condolences over the passing of <strong> Darryl Vegh</strong>
      </h2>
      <h3>
        In loving memory
      </h3>
      <h4>
        1952-2023
      </h4>
    </article>
    <picture>
      <figure>
        <img src="../../assets/img/vegh.png" alt="">
      </figure>
      <figcaption>
        <h3>
          Darryl Vegh
        </h3>
        <p>
          Senior ERP Technical Architect
        </p>
      </figcaption>
    </picture>
    </div>

  </div>
  </div>
</div>  -->
