<div class="innerHeader overview">
  <div class="innerHeadings">
    <h1>
      Corporate Overview
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/overview_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  The Company
                </h2>
        </div>
        <p>
          Established in 2003, JTSi is an IT & Engineering Services provider with years of demonstrated experience in the Information Technology and Engineering services field. JTSi has a proven track record for successfully delivering mission critical Professional Services to the Public & Private Sector industries. We deliver quality services at equitable rates and focus on constant improvement in all areas of our operation, with a strong commitment to meeting our customer’s needs.
</p><p>
          We view ourselves more as a business partner than merely a services provider. At JTSi “customer is always first” and partnering is our path to customer satisfaction.
        </p>
        <p> <strong>People –> Process –> Technology -> We do what we say</strong> </p>
        </div>
  </div>

  <ul class="bulletpoints mt-4">
    <li>Supporting the US Department of Defense Customers since 2003.</li>
<li>Highly Successful Cooperative R&D Agreement with the United States Army.</li>
<li>Cleared facilities with multiple Facility Security Officers onsite.</li>
<li>Highly experienced workforce with Security Clearance PMI, ITIL & Black belt certified Managers.</li>
<li>Management Team include reputable leaders from the Government and the Industry.</li>
<li>E2E Process based Cloud Organization / Clients include: Government & Commercial.</li>
<li>Achieved 97% at D&B/Open-Rating Survey.</li>
<li>Maintains good standing certificates with DCAA, State/Federal Government Agencies, SBA, D&B, SAMs and several other authorities.</li>
<li>Solid CPAR Records & ITAR registered.</li>
<li>ISO-20000-1 certified, CMMI-L3 Certified, CMMC initiated, partnerships with University Innovation Centers.</li>
<!-- <li>Read about our <a class="textlink" href="../../assets/docs/success-stories.pdf" target="_blank" rel="noopener noreferrer">success stories</a></li> -->
  </ul>

</div>
</section>
  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  What our Customers Say About us?
                </h2>
        </div>
<p>
  In October 2018, Open Ratings completed a Past Performance Evaluation involving qualitative and quantitative feedback from clients of Johnson Technology Systems, Inc. Supplier Performance Ratings give an overall performance rating on assessment of predicated performance. Ratings are on a scale of 0 to 100, with 100 representing the highest level of customer satisfaction.
</p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/vision_02.jpg" alt="">
      </div>
  </div>
  </div>
  </section>

  <section class="commonPadding">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          Detailed Performance Ratings
        </h2>
      </div>

<div class="borderd-grids threesome">
<div class="gridbox whiteBg">
<h3 class="subtitles_one">
  Reliability
</h3>
<p>
  How reliably do you think this company follows through on its commitments?
</p>
<h2 class="red_color">96%</h2>
</div>
<div class="gridbox whiteBg">
  <h3 class="subtitles_one">
    Cost
  </h3>
<p>
  How closely did your final total costs correspond to your expectations at the beginning of the transaction?
</p>
<h2 class="red_color">94%</h2>
  </div>
  <div class="gridbox whiteBg">
    <h3 class="subtitles_one">
      Quality
    </h3>
<p>
  How satisfied do you feel about the quality of the product/service provided by this company?
</p>
<h2 class="red_color">96%</h2>
    </div>
    <div class="gridbox whiteBg">
      <h3 class="subtitles_one">
        Business Relations
      </h3>
      <p>
        How easy do you think this company is to do business with?
      </p>
      <h2 class="red_color">97%</h2>
      </div>
      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Personnel
        </h3>
        <p>
          How satisfied do you feel about the attitude, courtesy, and professionalism of this company’s staff?
        </p>
        <h2 class="red_color">96%</h2>
        </div>
        <div class="gridbox whiteBg">
          <h3 class="subtitles_one">
            Customer Support
          </h3>
          <p>
            How satisfied do you feel about the attitude, courtesy, and professionalism of this company’s staff?
          </p>
          <h2 class="red_color">97%</h2>
          </div>
</div>
</div>
  </section>


  <section class="commonPadding grayBg">
    <div class="containers">
<div class="borderd-grids threesome">

    <div class="gridbox whiteBg">
      <h3 class="subtitles_one">
        What we do
      </h3>
      <ul class="bulletpoints">
<li>	Digital Transformation</li>
<li>	Cyber Security</li>
<li>	BIG Data & Analytics</li>
<li>	Cloud Hosting & AMS</li>
<li>	Cloud Migration Strategy /Execution</li>
<li>	Engineering Services</li>
<li>	Systems Engineering & Technical Assistance (SETA)</li>
<li>	Software Engineering Services</li>
<li>	Product Development Life Cycle & TechData</li>

      </ul>
      </div>
      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Deployable Solutions
        </h3>
        <ul class="bulletpoints">
<li>MRO ERP</li>
<li>SAP Inventory Management (S4/EWM)</li>
<li>SurveyShield (Web Services)</li>
<li>Integrated Process Solution (IPS)</li>
<!-- <li>VeloX Software Suite</li>
<li>IQ DMS</li>
<li>IQ Product Work Center</li>
<li>IQ Launch</li>
<li>IQ Discussion</li>
<li>Payment Terms Governance</li>
<li>BOM / Material Master Extension & Allocation Tool (BMAX)</li> -->
        </ul>
        </div>
        <div class="gridbox whiteBg">
          <h3 class="subtitles_one">
            Govt. Contract Vehicles
          </h3>
          <ul class="bulletpoints">
            <li>	GSA – MAS + CLOUD / CYBER SIN / GSA-PSS / Oasis</li>
<li>	US Army ERP Services – AES</li>
<li>	ITES2S/3S</li>
<li>	Encore III / RPTI & R23G</li>
<li>	Responsive Strategic Sourcing For Services (RS3)</li>
<li>	Navy ERP Navy SeaPort-E</li>
<li>	SSES Nexgen and CIO/SP3</li>
<li>	Army Futures Command (AFC IT Services IDIQ)</li>
<li>	SAP Partnership</li>

          </ul>
          </div>
</div>
</div>
  </section>

  <section class=" commonPadding">
    <div class="texttitle_two">
      <h2>Key Differentiators</h2>
  </div>
    <app-thumbnails></app-thumbnails>
    </section>
