import { Component, OnInit } from '@angular/core';

export interface GsaserviceElement {
  name: string;
  image: string;
  title: string;
  summary: string;
  price: number;
  buy:string;
}

const ELEMENT_DATA: GsaserviceElement[] = [
  {image: 'prod.jpg', name: 'SIN 518210C Cloud Senior DevSecOps Consultant', title: 'Cloud Senior DevSecOps Consultant on GSA Multiple Award Schedule (MAS) 47QTCA23D000J Small Disadvantaged Business SDVOSB IDIQ ', summary:'is is knowledgeable of all available cloud and on premises technologies and can move rapidly from ideation to continuous iteration enabling the rapid delivery of capabilities.', price: 132.64, buy:'Add to Cart'},
  {image: 'prod.jpg', name: 'SIN 518210C Cloud DevSecOps Engineer I', title: 'Cloud Senior DevSecOps Consultant on GSA Multiple Award Schedule (MAS) 47QTCA23D000J Small Disadvantaged Business SDVOSB IDIQ ', summary:'is is knowledgeable of all available cloud and on premises technologies and can move rapidly from ideation to continuous iteration enabling the rapid delivery of capabilities.', price: 132.64, buy:'Add to Cart'},
  {image: 'prod.jpg', name: 'LR003 Security Operator III', title: 'Cloud Senior DevSecOps Consultant on GSA Multiple Award Schedule (MAS) 47QTCA23D000J Small Disadvantaged Business SDVOSB IDIQ ', summary:'is is knowledgeable of all available cloud and on premises technologies and can move rapidly from ideation to continuous iteration enabling the rapid delivery of capabilities.', price: 132.64, buy:'Add to Cart'},
  {image: 'prod.jpg', name: 'SIN 518210C Cloud Senior DevSecOps Consultant', title: 'Cloud Senior DevSecOps Consultant on GSA Multiple Award Schedule (MAS) 47QTCA23D000J Small Disadvantaged Business SDVOSB IDIQ ', summary:'is is knowledgeable of all available cloud and on premises technologies and can move rapidly from ideation to continuous iteration enabling the rapid delivery of capabilities.', price: 132.64, buy:'Add to Cart'},
];

@Component({
  selector: 'app-gsaservice',
  templateUrl: './gsaservice.component.html',
  styleUrls: ['./gsaservice.component.scss']
})
export class GsaserviceComponent implements OnInit {

  displayedColumns: string[] = ['proimage', 'name', 'summary', 'price', 'quantity', 'buy'];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit(): void {
  }

}
