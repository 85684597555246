import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

const buttons = document.querySelectorAll(".corporate");

buttons.forEach(button =>
  button.addEventListener("click", _ => {
    document.getElementById("slidebar").classList.toggle("open");
  })
);

const closeber = document.querySelectorAll(".closeBar");

closeber.forEach(span =>
  span.addEventListener("click", _ => {
    document.getElementById("slidebar").classList.remove("open");
  })
);


const menuBtn = document.querySelectorAll(".menuBtn");

menuBtn.forEach(button =>
  button.addEventListener("click", _ => {
    document.getElementById("navmenu").classList.toggle("showMenu");
  })
);

const closeMenu = document.querySelectorAll(".closeMenu");

closeMenu.forEach(span =>
  span.addEventListener("click", _ => {
    document.getElementById("navmenu").classList.remove("showMenu");
  })
);


  }

  

}
