import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successfactors',
  templateUrl: './successfactors.component.html',
  styleUrls: ['./successfactors.component.scss']
})
export class SuccessfactorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
