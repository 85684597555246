
<div class="innerHeader contracts">
  <div class="innerHeadings">
    <h1>
      Contract Vehicles
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/contract_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JTSi awarded GSA/MAS SIN 518210c Cloud & Services
                </h2>
        </div>
        <p>
          JTSi is a GSA MAS Schedule contract holder. This is an Indefinite
Delivery Indefinite Quantity (IDIQ) Multiple Award Schedule (MAS)
contract. It is an IT procurement vehicle that offers a comprehensive
array of state-of-the-art IT products, services, and solutions. This Award
signifies that the GSA has determined that JTSi’s pricing is fair and
reasonable and JTSi is in compliance with all applicable laws and
regulations.
        </p>
        <p>
          <a class="inlinelink-2" data-bs-toggle="modal" data-bs-target="#cloudBrochurePriceList" >JTSi GSA Pricelist </a>
        </p>
        <p>
          For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
        </p>
        <p>
        <!-- <a class="btn btn-lg btn-info" [routerLink]="['/gsa518210c']" >Click here to know more</a> -->
      </p>
        </div>
  </div>
</div>
</section>

<section class="commonPadding Tpadd0">
  <div class="containers">
    <div class="boxtitle_two">
      <h2>
        Why GSA MAS?
      </h2>
    </div>
    <div class="leadCenter">
      <p>
      GSA uses MAS to deliver a full range of IT solutions including hardware, software, maintenance, network services, cybersecurity, professional IT services, and more. Key benefits include:
    </p>
  </div>
  <div class="borderd-grids threesome">

      <div class="gridbox whiteBg">
        <h3 class="subtitles_one">
          Ease of Use
        </h3>
        <p>
          A simplified process for online ordering and usage of blanket purchase agreements (BPAs).
        </p>
        </div>
        <div class="gridbox whiteBg">
          <h3 class="subtitles_one">
            Built-in Value
          </h3>
          <p>
            An extensive national network of resources, including complimentary e-Tools, a range of training opportunities, and responsive regional GSA representatives.
          </p>
          </div>
          <div class="gridbox whiteBg">
            <h3 class="subtitles_one">
              Safe-harbor
            </h3>
            <p>
              Assists with ensuring compliance with Federal Acquisition Regulations (FAR).
            </p>
            </div>

              <div class="gridbox whiteBg">
                <h3 class="subtitles_one">
                  Variety
                </h3>
                <p>
                  Access to a large number of industry suppliers which provide millions of products and services.
                </p>
                </div>
                <div class="gridbox whiteBg">
                  <h3 class="subtitles_one">
                    Teaming
                  </h3>
                  <p>
                    Contractor Team Arrangements (CTAs) facilitate collaboration between industry partners and provide a comprehensive solution to meet specific customer needs.
                  </p>
                  </div>
                  <div class="gridbox whiteBg">

                  </div>
    </div>

</div>
  </section>
  <section class="grayBg">
    <div class="containers">
  <div class="topsection padd0">

        <div class="picture">
          <img class="img-fluid" src="../../assets/img/microsoft_logo.jpg" alt="">
      </div>
      <div class="texts">
        <div class="heading redBg">
                <h2>
                  Microsoft Partner
                </h2>
        </div>
        <p>
          JTSi is your preferred partner for implementing Microsoft technologies. As a Microsoft partner, we are authorized to provide software, services, products, and technologies. JTSi offers a range of Microsoft solutions that enable businesses to drive innovation, collaboration, and agility. Supporting our clients, we help put them on the path to success.
        </p>
        <p>
          Through its partnership with Microsoft, JTSi is authorized to resell Microsoft products across federal agencies, meeting federal compliance requirements, providing every government agency with the capability to engage with citizens, empower employees, optimize operations, and reinvent government services and business models through using flexible, integrated and trusted Microsoft solutions. This also include Microsoft Azure Cloud hosting requirements.
        </p>
        <p>
          For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
        </p>
        </div>
  </div>

  </div>
  </section>
  <section class="commonPadding">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JTSi is awarded C5 OTA
                </h2>
        </div>
        <p>
          C5 is a consortium composed of leading companies and institutions in the C4ISR and cyber technology sectors.
          </p><p>
The Office of the Secretary of Defense requested that the Army establish a new OTA to address C4ISR and cyber-technology requirements. In response, C5 was formed. Based upon the simplicity and transparency of its business model, C5 was awarded an initial three-year, OTA with the Army in July 2014, and a 10-year, no-ceiling, follow-on OTA in April 2017. In April 2018, the Marine Corps Systems Command signed a 5-year no-ceiling agreement with C5 for the use of OTA.

        </p>
        <p>
          For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
        </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../assets/img/c5_contract.jpg" alt="">
      </div>
  </div>

  </div>
  </section>

  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 ">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/contract_02.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Navy Seaport NXG
                </h2>
        </div>
<p>
  The NAVY-NxG contract has one 5 year base with an additional 5 year option. It is Navy's electronic platform for acquiring support services in 2 broad functional areas and 23 sub areas which include, but are not limited to Admin Support, Engineering, Financial Management, IT Services, Logistics, Program Management, PR, and Training.
</p>
<p>
  For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
</p>
        </div>

  </div>

  </div>
  </section>
<section class="commonPadding Bpadd0">
  <div class="containers">
<div class="topsection padd0 reverse">
  <div class="texts">
      <div class="heading redBg">
              <h2>
                ARDEC IT Services
              </h2>
      </div>
      <p>
        JTSi as a Prime contractor is currently providing full range of IT services on this IDIQ contract serving the Department of the Army, Army Materiel Command, Research, Development and Engineering Command (RDECOM), Armaments Research, Development and Engineering Center (ARDEC), Picatinny, NJ; since December 2018.
      </p>
      <p>
        For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
      </p>
      </div>
      <div class="picture">
        <img class="img-fluid" src="../../assets/img/Devcom.jpg" alt="">
    </div>
</div>

</div>
</section>


<section class="grayBg">
  <div class="containers">
<div class="topsection padd0">
  <div class="picture">
      <img class="img-fluid" src="../../assets/img/Army_RS3.jpg" alt="">
  </div>
  <div class="texts">
      <div class="heading redBg">
              <h2>
                RS3
              </h2>
      </div>
      <p>
        JTSi is part of Army’s $34.7B RS3 contract — a Command, Control, Communications, Computers, Intelligence, Surveillance and Reconnaissance (C4ISR) services vehicle. RS3 is a five-year, multiple award indefinite delivery, indefinite quantity (IDIQ) performance based contract. A five-year optional ordering period will follow the initial base ordering period, which ends in May 2022.
Services provided under the RS3 contract include:

      </p>
      <ul class="bulletpoints colTwo">
        <li>	Acquisition planning</li>
<li>	HR support</li>
<li>	risk Management Support</li>
<li>	Various types of training</li>
<li>	Augmented/Virtual Reality</li>
<li>	Cloud</li>
<li>	Data analytics</li>
<li>	Cybersecurity</li>
<li>	Supply chain management</li>
<li>	Various other logistical functions</li>
<li>	Artificial intelligence</li>
<li>	Various other research functions</li>
<li>	Prototyping</li>

      </ul>
      <p>
        For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
      </p>
      </div>
</div>
</div>
</section>

<section class="commonPadding Bpadd0 ">
  <div class="containers">
<div class="topsection padd0">
  <div class="picture">
      <img class="img-fluid" src="../../assets/img/SAP_PartnerEdge.jpg" alt="">
  </div>
  <div class="texts">
      <div class="heading redBg">
              <h2>
                SAP PartnerEdge
              </h2>
      </div>
     <p>
      JTSi is a SAP PartnerEdge firm. With this partnership JTSi is able to tap into SAP’s Training, Tools, Information and Resources, enabling our customers to become best-run businesses.
</p><p>
      The SAP PartnerEdge – Build authorises JTSi to Build, extend, integrate, and market solutions with SAP.

     </p>
     <p>
      For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
    </p>
      </div>
</div>
</div>
</section>

<section class=" grayBg bottomFive">
  <div class="containers">
<div class="topsection padd0 reverse reverse-smallpic">
  <div class="texts">
    <div class="heading redBg">
      <h2>
        CIO SP3
      </h2>
</div>
<p class="mb3">
  JTSi is part of this 10-year Indefinite Delivery/Indefinite Quantity (IDIQ) contract with a Contract Ceiling: $20 billion per Contract Holder.
CIO SP3 offers a wide range of IT services spanning across ten task areas:
</p>
      <ul class="bulletpoints ">
        <li><strong> 	Task Area 1 : </strong> IT Services for Biomedical Research, Health Sciences, and Healthcare</li>
<li><strong>	Task Area 2 : </strong> Chief Information Officer (CIO) Support</li>
<li><strong>	Task Area 3 : </strong> Imaging</li>
<li><strong>	Task Area 4 : </strong> Outsourcing</li>
<li><strong>	Task Area 5 : </strong> IT Operations and Maintenance</li>
<li><strong>	Task Area 6 : </strong> Integration Services</li>

      </ul>

      </div>
      <div class="picture">
        <img class="img-fluid" src="../../assets/img/CIO.jpg" alt="">
    </div>
</div>
<ul class="bulletpoints colTwo">
  <li><strong>	Task Area 7 : </strong> Critical Infrastructure Protection and Information Assurance</li>
  <li><strong>	Task Area 8 : </strong> Digital Government</li>
  <li><strong>	Task Area 9 : </strong> Enterprise Resource Planning</li>
  <li><strong>	Task Area 10 : </strong> Software Development</li>
</ul>
<p>
  For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
</p>
</div>
</section>

  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/contract_03.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Encore III SB
                </h2>
        </div>
<p>
  The DISA Encore III SB contract is an indefinite delivery/indefinite
quantity (ID/IQ) contract providing technical solutions for the
Department of Defense (DoD) in support of its migration to an
integrated and interoperable Global Information Grid (GIG), as well as
other federal agencies having similar Information Technologies migration
and integration needs. The Period of Performance includes a five year
base period and five one year options and allows for government
agencies to issues tasks as SB set asides to meet SB contracting goals
</p>
<p>
  For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
</p>
        </div>

  </div>
  </div>
  </section>


  <section class="grayBg bpadd6">
    <div class="containers">
  <div class="topsection Tpadd0 BpaddSmall reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  ITES-3S
                </h2>
        </div>
        <p>
          ITES-3S is a $12 billion Indefinite Delivery/Indefinite Quantity (IDIQ) contract vehicle structured for the U.S. Army, Department of Defense (DoD) and all other federal agencies to acquire reasonably priced, enterprise-level commercial IT services and solutions.
        </p>
<p>
  The scope of work allows for unclassified through Top Secret SCI work with worldwide performance and delivery (both on & off site) permitted. It is designed to cover a wide range of information technology work. The scope includes the below types of activities:
</p>
<ul class="bulletpoints colTwo">
  <li>Business process reengineering</li>
  <li>Information systems security</li>
  <li>Information assurance</li>
  <li>Information technology service</li>
  <li>Software development</li>
  <li>Education and training</li>
  <li>Program/Project management</li>
  <li>Systems operation and maintenance</li>
  <li>Enterprise design, integration and consolidation</li>
  <li>Network support</li>

  </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/contract_04.jpg" alt="">
      </div>
  </div>
  <p>
    Each government customer establishes their own ordering account and the customer and their organic contracting support control the entire acquisition process. In addition, there is no government usage fee for this vehicle. Awards take only as long as the organic contracting office takes to complete the necessary proposal form within the ITES 3S system. Contracting officers can select the specific vendors to receive the RFP as well.
  </p>
  <p>
    For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
  </p>
  </div>
  </section>
  <section class="commonPadding">
    <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/contract_05.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  DoD Ordnance Technology Consortium
                </h2>
        </div>
        <p>
          JTSi is a member of DoD Ordnance Technology Consortium (DOTC). DOTC in partnership with the NAC, to enhance our Warfighter’s lethality, survivability and combat effectiveness by facilitating the industrial and academic research, development and technology demonstrations needed to advance and expand our military technological superiority. DOTC is sponsored by the Office of the Secretary of Defense (OSD) and is utilized by all Services and Defense Agencies to design, develop and demonstrate prototypes for armaments. DOTC offers government customers a better way of doing business through streamlined acquisition and collaboration across a broad spectrum of industrial and academic partners.
        </p>
        <p>
          For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
        </p>
        </div>
  </div>
  </div>
  </section>


  <section class="grayBg">
    <div class="containers">
  <div class="topsection reverse padd0">

    <div class="texts">
        <div class="heading redBg">
                <h2>
                  GSA Alliant GWAC
                </h2>
        </div>
        <p>
          JTSi has partnered with CACI on the GSA Alliant GWAC, a $64B contract vehicle.
        </p>
        <p>
          For any queries please contact the PM on <a class="inlinelink" href="mailto:contract@jtsusa.com">contract@jtsusa.com</a>
        </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/alliant.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
