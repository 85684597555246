<div class="innerHeader Analytics">
  <div class="innerHeadings">
    <h1>
      Predictive Analytics
    </h1>
  </div>
  </div>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Analytics_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Underpin every decision with a new
level of intelligence
                </h2>
        </div>
        <p>
          Business success demands strategic, operational, and tactical
decision-making. But first, users must access analytics complete with
business intelligence, prediction, and planning to act confidently in the
moment.
</p><p>
JTSi provides end-to-end data management tools and analytics to
support smart data access, preparation, and quality governance while
aligning processes on standard business logic and semantics and
supporting predictive modeling. The solution also scales to your
changing business needs in running on the enterprise-grade.

        </p>
        </div>
  </div>

  <section class="commonPadding grayBg">
    <div class="containers">
      <div class="leadCenter">
        <p>
          The SAP Analytics Cloud solution combines BI, augmented and predictive analytics, and planning
capabilities into one cloud environment. As the analytics layer of SAP’s Business Technology
Platform, it supports advanced analytics enterprise-wide.

        </p>
      </div>
      <ul class="bulletpoints">
        <li>	Meet the analytics needs of all types of people and decisions with an end-to-end analytics solution.</li>
<li>Explore data across the organisation and deliver insights at the point of decision with intuitive self service analytics with business intelligence features.</li>
<li>Link and create financial and operational plans seamlessly in one solution to drive better decisions with integrated plans.</li>
<li>Go the last mile of data-driven decision-making with machine-generated analytics and insights with our augmented analytics capabilities.</li>

      </ul>
      </div>
      </section>


  <div class="topsection containers reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Uncover new relationship in your data
                </h2>
        </div>
    <p>
      Take advantage of smart algorithum to <strong>uncover new relationship in your data,</strong>
       draw insights previously hidden, and fuel intelligent predictions.
    </p>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/Analytics_02.jpg" alt="">
      </div>
  </div>


