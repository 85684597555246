<div class="innerHeader CloudPage">
  <div class="innerHeadings">
    <h1>
      Cloud Adoption & Optimization
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/Cloud-Adoption-&-Optimization_sub_banner.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Cloud Adoption & Optimization
                </h2>
        </div>
       <p>
        Our expertise lies in helping our clients navigate the entire cloud adoption journey. With our team of software engineers and cloud experts, we serve as an extension of your team to deliver a fast, simple, and efficient adoption plan that's relentlessly tailored to your needs. The JTSi Cloud migration process ensures you are in control. We use best practice to navigate any migration challenges and align our approach with your business goals. With automated processes and proven expertise, we accelerate migrations, delivering solutions that are engineered to increase business value.
       </p>
        </div>
  </div>
</div>
</section>
<section class="commonPadding grayBg">

<div class="containers homeGrid">

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../../assets/img/capabilities/Sap_Cloud_Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Cloud Migration & CSP

      </h4>

      <a [routerLink]="['/sap/cloud-migration-and-csp']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>


  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Migration_Strategy.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Migration Strategy
  Development
      </h4>
      <a [routerLink]="['/capabilities/migration-strategy-development']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Enterprise_Architecture.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Enterprise Architecture
        (EA)
      </h4>
      <a [routerLink]="['/capabilities/enterprise-architecture']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/hybrid_cloud.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Private/Public/Hybrid
  Cloud Services
      </h4>
      <a [routerLink]="['/capabilities/private-public-hybrid-cloud-services']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>

  <div class="homeGrid-box">
    <div class="pictures">
      <img src="../../assets/img/capabilities/Data-Migration.jpg" alt="">
    </div>
    <div class="overlays">
      <h4>
        Data Migration / ETL
      </h4>

      <a [routerLink]="['/capabilities/data-and-application-migration']" class="btn btn-md btn-primary">Detailed View</a>
    </div>
  </div>


</div>
</section>
