import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss']
})
export class DataMigrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
