<div class="innerHeader leverx_discussion">
  <div class="innerHeadings">
    <h1>
      IQ Discussion
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Bring Teams Together, Collaborate Securely
      </h2>
      <h4>IQ Discussion is the collaboration hub that brings SAP users together with the right information and tools to collaborate securely in the SAP environment</h4>
    </div>
    <div class="prod_sldes">
    <div id="productSlides-1" class="carousel slide productSlideShow" data-bs-ride="carousel">
      <div class="carousel-indicators">

        <button type="button" data-bs-target="#productSlides-1" aria-current="true" data-bs-slide-to="0" class="active" aria-label="Slide 1">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#access_doc"></use>
            </svg>
           </span>
           Threaded Discussions
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="1"   aria-label="Slide 2">
          <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#sd_search"></use>
           </svg>
          </span>
          Tagging and Notifications
         </button>
         <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="2" aria-label="Slide 3">
          <span>
            <svg>

              <use xlink:href="../../assets/img/sprite.svg#inu"></use>
            </svg>
           </span>
           File Collaboration
        </button>
        <button type="button" data-bs-target="#productSlides-1" data-bs-slide-to="3" aria-label="Slide 4">
          <span>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#bookmsub"></use>
            </svg>
           </span>
           Integrated Across SAP Modules
        </button>

      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/iqdis_02.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/iqdis_03.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/iqdis_04.jpg" class="img-fluid" alt="...">
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/iqdis_01.jpg" class="img-fluid" alt="...">
        </div>
      </div>

    </div>
    </div>
  </div>
    </section>
    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Here's How IQ Discussion Supports Better Collaboration
          </h2>
          <h4>Communication, transparency, and productivity are important for modern teams. Achieve these goals with IQ Discussion</h4>
        </div>

        <div id="productSlides-2" class="carousel carousel-dark slide splitSlideShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="3" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#productSlides-2" data-bs-slide-to="4" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Collaborate on key content with confidence that your ideas and data are secure, as the collaboration occurs directly within the walls of SAP.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/ips_21.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">

                  <h2>
                    Work on documents as a team, provide feedback in context, and rapidly iterate until your work is complete.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/dms_22.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Users can be tagged in discussions, thus providing a quick mechanism to get them engaged.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/iqdis_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Provide a central place to keep work organized, accessible, and easy to find.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/iqdis_24.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>
            <div class="carousel-item">
              <div class="itemcontent">
                <div class="txt">
                  <h2>
                    Reduce the dependency on emails, creating a single source of truth.
                  </h2>
                </div>
                <div class="pic">
              <img src="../../../assets/img/launch_23.png" class="img-fluid" alt="...">
            </div>
            </div>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#productSlides-2" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#productSlides-2" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        </div>
</section>

<section class="commonPadding">
  <div class="containers">
    <div class="sec_title_01">
      <h2>
        Business Areas That Would Benefit from IQ Discussion
      </h2>
      <h4>Below is a sample list of the business areas that would benefit from using IQ Discussion</h4>
    </div>
    <div class="borderd_four_col lessone">
<div class="borderd_col blue">
  <img src="../../../assets/img/iqdis_31.png" class="img-fluid" alt="...">
<h4>Management</h4>
<ul class="ticklist">
  <li>Project Teams</li>
  <li>Sustainability</li>
  <li>Safety</li>
  </ul>
</div>
<div class="borderd_col">
  <img src="../../../assets/img/launch_31.png" class="img-fluid" alt="...">
  <h4>Production and Marketing</h4>
  <ul class="ticklist">
    <li>Product Management</li>
    <li>Manufacturing Operations</li>
    <li>Logistics</li>
    <li>Marketing and Promotions</li>
    </ul>
  </div>

  <div class="borderd_col">
    <img src="../../../assets/img/launch_32.png" class="img-fluid" alt="...">
    <h4>Administrative Areas</h4>
    <ul class="ticklist">
      <li>R&amp;D Groups </li>
      <li>HR</li>
      <li>Legal</li>
      </ul>
    </div>

    </div>
    </div>
    </section>

    <section class="commonPadding grayBg">
      <div class="containers">
        <div class="sec_title_01">
          <h2>
            Key Features and Capabilities
          </h2>
          <h4>IQ Launch offers the following key features and capabilities to support the product readiness process</h4>
        </div>
        <div class="key_sldes">
        <div id="productSlides-3" class="carousel slide productKeyShow" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#monitor"></use>
                </svg>
               </span>
               Usability
            </button>
            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="1" aria-label="Slide 2">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#adherence"></use>
                </svg>
               </span>
               Easy discussion management
            </button>

            <button type="button" data-bs-target="#productSlides-3" data-bs-slide-to="2" aria-label="Slide 3">
              <span>
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#ext_usable"></use>
                </svg>
               </span>
               Additional options
            </button>

          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">

              <h3>Usability</h3>
              <ul class="ticklist">
                <li>Fiori-based simple user interface</li>
                <li>Supports all devices, including mobile and tablet</li>
                <li>Members can join and leave discussions as needed</li>
                <li>Easy management of user images or avatars</li>
                </ul>

            </div>
            <div class="carousel-item">
              <h3>Easy discussion management</h3>
              <ul class="ticklist">
                <li>Create threaded discussions against any SAP object</li>
<li>Create discussion topics that can be focused by the team, project, client, or whatever is relevant to your organization</li>
<li>Collaborate on files as part of discussions</li>
<li>Tag users to get them engaged in the discussion</li>
                </ul>
            </div>

            <div class="carousel-item">
              <h3>Additional options</h3>
              <ul class="ticklist">
                <li>Include links to the SAP objects (e.g., Material Master, BOM, Document, etc.) to a thread </li>
<li>Integrated with standard SAP authorization concepts</li>
<li>Favorite comments and topics to easily get back to them</li>
<li>Receive email notifications when tagged in comments</li>
                </ul>
            </div>
          </div>

        </div>
        </div>
      </div>
        </section>
