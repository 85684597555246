import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capgrid',
  templateUrl: './capgrid.component.html',
  styleUrls: ['./capgrid.component.scss']
})
export class CapgridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
