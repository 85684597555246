<div class="innerHeader saphana">
  <div class="innerHeadings">
    <h1>
      S4/Hana Migration & Support
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection Tpadd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/hana_support_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  What is SAP S/4HANA?
                </h2>
        </div>
        <p>
          With years of successful SAP implementations and deep SAP expertise, we offer SAP S/4HANA Implementation services that enable businesses to stay on top of industry demands, trends, and developments.
          </p><p>
As experienced SAP consultants, we provide guidance on the appropriate solution across the technology landscape, whether it is a routine functionality, a business intelligence solution, or developing an innovative solution to meet specific business objectives.
</p><p>
Based on our analysis of your needs, we customize our services to meet your business goals. Transparency is at the core of our approach from strategy through go-live. Our SAP consultants continue to support the IT needs of companies as they evolve.
</p><p>
JTSi brings the right blend of industry and SAP expertise, with proprietary tools, methodologies, and support services to help make a smooth transition to SAP S/4HANA.
        </p>
        </div>
  </div>
</div>
</section>
  <section class="grayBg commonPadding">
  <div class="containers">
    <div class="boxtitle_two">
      <h2>Benefits</h2>
          </div>

    <div class="four-cols lessOne">

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana001"></use>
            </svg>
          </div>
            <h4>
              Rich Business Experience
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana002"></use>
            </svg>
          </div>
            <h4>
              Future Ready
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana003"></use>
            </svg>
          </div>
            <h4>
              Always Connected
            </h4>

      </div>
      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana004"></use>
            </svg>
          </div>
            <h4>
              Agile and flexible
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana005"></use>
            </svg>
          </div>
            <h4>
              Anytime Anywhere
            </h4>

      </div>

      <div class="colBox">
        <div class="colIcon">
          <svg>
              <use xlink:href="../../../assets/img/sprite.svg#hana006"></use>
            </svg>
          </div>
            <h4>
              Greater Scale, Lower Risk
            </h4>

      </div>

  </div>
  </div>
      </section>

      <section class="splitcolumns">

<div class="splitpic business"></div>
<div class="spitcontent bgblue">
<h2>
  SAP S/4HANA Intelligent ERP system for today’s business
</h2>
<p>
  Discover a truly modern ERP system with embedded AI and machine learning – available on premise, in a public or private cloud, or in a hybrid environment.
</p>
<a href="#" class="btn">Request Demo</a>
</div>
      </section>

      <section class="splitcolumns">

        <div class="spitcontent bgblue">
        <h2>
          WE HAVE BEEN PART OF <br> THE WORLD LARGEST <br> ERP IMPLEMENTATION – US ARMY
        </h2>

        </div>

        <div class="splitpic implement"></div>
              </section>


<section class="commonPadding">
  <div class="containers">


    <div class="four-cols lessOne">

      <div class="bluebox">
      <img src="../../../assets/img/hana_support_04.jpg" alt="">
      <div class="txt">
            <h4>
              Build Intelligent Enterprise With SAP S/4HANA Cloud
            </h4>
<a href="#" class="btn">More Info</a>
          </div>
      </div>

      <div class="bluebox">
        <img src="../../../assets/img/hana_support_05.jpg" alt="">
        <div class="txt">
            <h4>
              SAP S4HANA Customer Journey Approach
            </h4>
            <a href="#" class="btn">Download</a>
</div>
      </div>

      <div class="bluebox">
        <img src="../../../assets/img/hana_support_06.jpg" alt="">

        <div class="txt">
            <h4>
              Start Your digital journey with SAP S/4HANA

            </h4>
            <a href="#" class="btn">Demo</a>
</div>
      </div>

  </div>
  </div>
</section>
