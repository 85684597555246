<div class="innerHeader contact">
  <div class="innerHeadings">
    <h1>
      Contact Us
    </h1>
  </div>
</div>
<section class="commonPadding">
  <div class="containers">
    <div class="contactUs">

      <div class="details">
        <div class="info">
          <h2>
            Start a Conversation
          </h2>
          <p>
            Our mission is to help you make the right choice for the right outcomes. That starts with an honest conversation about your challenges. We look forward to talking.
          </p>
          <ul class="social">
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#email"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#linkedin"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#fb2"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#twitter"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#youtube"></use>
                </svg>
              </a>
            </li>
          </ul>

        </div>
        <div class="info">
          <h2>
            Our Office
          </h2>
          <h4>
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#usflag"></use>
            </svg>
            United States
          </h4>

          <address class="address">
            <h5> Washington DC Metro</h5>
            <ul>
              <li>
                11490 Commerce Park Dr.,
              </li>
              <li>
                4th Floor, Virginia 20191
              </li>
              <li>
                Tel: <a href="tel:+17039499100">+1 (703)949-9100</a> | Fax +1 (703)860-5874
              </li>
              <li>
                E-mail: <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
              </li>
            </ul>
          </address>
          <address class="address">
            <h5> New Jersey</h5>
            <ul>
              <li>
                370 Richard Mine Road, Suite#100,
                </li>
                <li>
                  Wharton – NJ – 07885
                </li>
              <li>
                Tel: <a href="tel:+19733288000">+1 (973)328-8000</a> | Fax +1 (703)860-5874
              </li>
              <li>
                E-mail: <a href="mailto:info@jtsusa.com">info@jtsusa.com</a>
              </li>
            </ul>
          </address>
          </div>
          <div class="info">
          <h2>
            For More Information
          </h2>
          <ul class="moreinfos">
            <li>Business Development: <a href="mailto:bd@jtsusa.com">bd@jtsusa.com</a></li>
            <li>Support: <a href="mailto:support@jtsusa.com">support@jtsusa.com</a> </li>
              <li>Career: <a href="mailto:jobs@jtsusa.com">jobs@jtsusa.com</a> </li>
              </ul>
            </div>
        <!-- <div class="offices">

          <p><a target="_blank"
              href="https://www.mapquest.com/directions/to/us/va/reston/20191-1506/11490-commerce-park-dr-38.946004,-77.342668?CID=lfddlink">Get
              Direction &#x203A;</a></p>
        </div>

        <div class="offices">
          <div class="placename">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#usflag"></use>
            </svg>
            United States
          </div>



          <p>
            <a target="_blank"
              href="https://www.mapquest.com/directions/to/us/nj/dover/07801-5735/3159-schrader-rd-40.946523,-74.546007?CID=lfddlink">Get
              Direction &#x203A;</a>
          </p>
        </div> -->

      </div>

        <div class="contactUs-forms">
          <div class="formclmn">
          <form class="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit(f)">
          <div class="mb15">
            <label for="fullname" class="form-label">Full Name <span>*</span></label>
            <input type="hidden" name="lead_ID" value="service.formData.ctD_ID">
            <input type="hidden" name="frompage" value="ctservice.formData.ctD_FromPage" value="ContactUs">
            <input type="text" name="firstname" #ctD_Name="ngModel" [(ngModel)]="service.formData.ctD_Name"
              class="form-control" [ngClass]="{'is-invalid': f.submitted &&  ctD_Name.invalid}" required maxlength="50">
          </div>
          <div class="mb15">
            <label for="emails" class="form-label">Email address<span>*</span></label>
            <input type="email" id="contactEmail" name="email" #ctD_Email="ngModel" [(ngModel)]="service.formData.ctD_Email"
            (blur)="validateEmail($event,f)"
              class="form-control" [ngClass]="{'is-invalid': f.submitted &&  ctD_Email.invalid}" required
              maxlength="100">
          </div>


          <div class="phNumbers">
          <div class="mb15">
            <label for="phonenumber" class="form-label">Phone</label>
            <input type="text" ng2TelInput name="phoneNumber" #ctD_Phone="ngModel"
              [(ngModel)]="service.formData.ctD_Phone"
              class="form-control"
              [ngClass]="{'is-invalid': f.submitted &&  ctD_Phone.invalid}"
              required maxlength="20"
              (hasError)="hasError($event,f)"
              (keyup)="formatNumber($event,f)"
              [ng2TelInputOptions]="{autoPlaceholder:'false'}"
              (countryChange)="onContryChange($event,f)">

              <input type="hidden" name="countryCode" class="form-control" #CountryCode="ngModel"
              [(ngModel)]="service.formData.countryCode" value="+1">
            <input type="hidden" name="countryISO" class="form-control" #CountryISO="ngModel"
              [(ngModel)]="service.formData.CountryISO" value="us">
          </div>
          <div class="mb15">
            <label for="Extn" class="form-label">Extn</label>
            <input type="text" name="extn" #ctD_Ext="ngModel" [(ngModel)]="service.formData.ctD_Ext" class="form-control"  maxlength="5">
          </div>
        </div>
            <div class="mb15">
            <label for="website" class="form-label">Website</label>
            <input type="text" name="website" class="form-control" maxlength="">
          </div>


          <div class="mb15">
            <!-- <label for="messages" class="form-label">Message  </label> -->

            <textarea class="msgarea" cols="30" rows="6" name="message" #ctD_Message="ngModel"
              [(ngModel)]="service.formData.ctD_Message" maxlength="3000"
              placeholder="Type Your Message Here"></textarea>
          </div>
          <button class="sendbtn" type="submit">Submit Here</button>
        </form>
      </div>
        <div class="mapwrap">
            <div class="mapouter"><div class="gmap_canvas"><iframe id="gmap_canvas" src="https://maps.google.com/maps?q=11490 Commerce Park Dr., 4th Floor, Rston, Virginia 20191&t=&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
          </div>
        </div>

    </div>


  </div>
</section>
