import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-cloud-migration',
  templateUrl: './cloud-migration.component.html',
  styleUrls: ['./cloud-migration.component.scss']
})
export class CloudMigrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openModal(event:any,frm:NgForm,brochType:any){
    frm.controls['frompage'].setValue(brochType); 
  }
}
