<div class="innerHeader plm">
  <div class="innerHeadings">
    <h1>
      Product Life Cycle Management (PLM)
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/plm_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Innovate for success with
product lifecycle management
                </h2>
        </div>
        <p>
          PLM software connects people, processes, and data across the entire
product lifecycle to a central repository of information. This ensures
that everyone from the designer to end-customer is on the same page.
        </p>
        </div>
  </div>
</div>
</section>
  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Market Leaders use PLM
                </h2>
        </div>

       <ul class="bulletpoints">
        <li>	Market Leaders drive up revenue while REDUCING COSTS, result is HIGHER PROFIT!</li>
        <li>	Market Leader are more efficient</li>
        <li>	Firms with PLM spend 8% less time on nonproductive data management tasks</li>
        <li>	Average 16% reduced product development cost</li>
        <li>	Market Leaders are more innovative</li>
        <li>	PLM helps product developers spend more time focusing on innovation</li>

       </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/plm_02.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection Tpadd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/plm_03.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Advantages of using PLM
                </h2>
        </div>
        <ul class="bulletpoints">
  <li>	Bring products to production faster</li>
<li>	Achieve Seamless collaboration between R&D and Supply chain</li>
<li>	Central repository/company wide documentation management solution</li>
<li>	More than Double efficiency in processing change orders</li>
<li>	Rapid deployment/complete visibility and management of multiple locations/sites</li>

</ul>

        </div>

  </div>
  <div class="borderedpic">
  <img class="img-fluid" src="../../../assets/img/plm_04.jpg" alt="">
  <p>
    Contrary to the general belief, PLM doesn't cost a lot to implement. With the advent of PLM Cloud, JTSi offers PLM for startups and SMB’s
too.
  </p>
</div>
  </div>
  </section>


  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  PLM in the cloud
                </h2>
        </div>

      <p>
        Cloud-based PLM offers many advantages over traditional on-premise
product lifecycle management, including
      </p>
      <ul class="bulletpoints">
        <li>	Lower total cost of ownership</li>
        <li>	No hardware to purchase or servers to maintain</li>
        <li>	No upgrades or associated delays due to redeployment</li>
        <li>	Faster implementation</li>
        <li>	Instantly on</li>
        <li>	Secure access anytime, anywhere</li>

      </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/plm_05.jpg" alt="">
      </div>
  </div>
  </div>
  </section>


