<div class="innerHeader ekr">
  <div class="innerHeadings">
    <h1>
      Enterprise Knowledge
     <span>Repository (EKR)</span>
    </h1>
  </div>
  </div>

  <div class="topsection containers">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/ekr_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  EKR ( Enterprise Knowledge
                  Repository)
                </h2>
        </div>
        <p>
          JTSi has over 10 years of EKR experience. JTSi implemented the Army’s
EKR capabilities at Picatinny under the guidance of the U.S. Army Office
of Business Transformation (OBT) for the purpose of managing Army’s
enterprise architecture and the services investment review compliance
to the Office of the Secretary of Defense (OSD) and the congress. We
supported the ERP task force for establishing the Army Investment
Review Board (IRB) IT governance process. The EKR was a critical
component of the process to capture and analyze Army organization IT investments and align to an Army Business Enterprise Architecture
(ABEA). JTSi has successfully deployed ARIS for the Department of Navy (DON) in AWS Cloud. We leveraged EKR to harvest requirements
documents and develop architecture solutions products.
        </p>
        </div>
  </div>

  <section class="commonPadding grayBg">
    <div class="containers">

     <img class="img-fluid" src="../../../assets/img/ekr_02.jpg" alt="">
      </div>
      </section>

      <section class="commonPadding">
  <div class="containers">
<p>
  We bring a high level of experience and success supporting DoD programs, including OBT, where we designed an end to end process for the
acquire to retire conventional ammunition life cycle. Using the SAP best practices business repository, we constructed a reference model
that applied standard industry processes and extended the models using the ARIS tool set to refine the processes.
</p>
<p>
  JTSi was the lead technical developer for EKR project at CCDC-AC overlaying Process/System/Technical Architectures, supporting CPI
(Lean/6 Sigma), using Agile techniques for continuous improvement and supporting EKR using COTS tools (e.g., ARIS, SAP NetWeaver, SAP
KM, Reischman Toolbus) to manage Army Defense Business Systems (DBS) in Business Mission Area (BMA). JTSi maintained JM&L Portfolio
in EKR using DoDAF/Fit for Purpose (FfP) viewpoints with ARIS & demonstrated ARIS integration with SAP Solution Manager enabling call
to SAP from the process modeler. EKR provides system alignment, reference architecture such as the ABEA, BEA, impact and trade off
analysis, and sequence model. DoDAF artifacts such as the OV-5a and SV-8 are configured in the meta model. JTSi established
architectural integrity in EKR via management processes and control (access and function privileges) maintaining security and supporting
change management.

</p>
<p>
  JTSi continues to improve our agile development capabilities and refine our expertise for future needs. We have worked with SAP NS2 to
  develop and mature ACTIVATE, the next generation SAP S4 agile implementation methodology. Our understanding of this methodology will
  facilitate the loading and analysis of Army ERPs in EKR today as well as facilitate the Army and OBT moving to the cloud tomorrow.
</p>
<p>
  The EKR is a capability used by the US Army to build and use a body of knowledge about its business operations, including its processes
  and IT support. It encompasses automated tools, data, standards and governance processes required to effectively manage the Army’s IT
  portfolio and to provide lifecycle architecture support for individual investments. It supports and facilitates: compliance, data rights, reuse,
  model based architecture, audit readiness and cybersecurity.

</p>
<p>
  The EKR creates a federated knowledge library that returns intellectual property to the Army for centralized knowledge sharing and reuse
across the Army Enterprise. By registering various Army architectures, standardizing on DoDAF viewpoints and using common core object
definitions, the EKR improves the architecture development process, promotes reuse, eliminates duplication and provides support for
strong data governance, Lean Six Sigma and data center consolidation programs.

</p>
<p>
  The EKR capabilities are used to support the development, evolution and continuous improvement of the Army’s Business System
Architecture, using architecture data derived from DoD’s Business Enterprise Architecture as well as Army Command, Domain and system
architectures. Planned SAP Solution Manager capabilities would enable the Army to gather configuration data needed to harmonize end to
end processes and data across the Army’s ERPs and certain legacy systems.


</p>
<p>
  JTSi personnel have played key roles in the conception, design, implementation, training, operation and continuous improvement of the
EKR. We have also developed a significant number of models. We designed, integrated and put the EKR into production. We developed and
implemented data collection capabilities, supported establishment of a governance framework and have conducted numerous
familiarization and training sessions to Senior Executives, architects and business managers.
</p>
<p>
  The EKR employs SoftwareAG’s ARIS Platform and Business Architect tools which are complemented by SAP Business Objects and SAP
Knowledge Management. SAP Solution Manager capabilities are planned for future implementation.
</p>
  </div>
  </section>

  <section class="bpadd6">
    <div class="containers">
      <div class="boxtitle_two">
        <h2>
          Key Endeavours include
        </h2>
      </div>
<ul class="bulletpoints colTwo">
  <li>	Implemented functional and technical architectures based on DoDAF 2.0 framework</li>
  <li>	Developed key documents and enablers such as CONOPS, Methods and Conventions, library schema</li>
  <li>	Established interfaces with a diverse set of authoritative data sources, to populate repositor</li>
  <li>	Delivered numerous educational and training sessions to senior executives, architects, and business managers</li>

</ul>
    </div>
    </section>


