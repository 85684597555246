<div class="innerHeader maintenance">
  <div class="innerHeadings">
    <h1>
      Application Migration & Maintenance
    </h1>
  </div>
  </div>
  <section class="commonPadding">
  <div class="containers">

  <div class="topsection padd0">
    <div class="picture">
        <img class="img-fluid" src="../../../assets/img/maintenance_01.jpg" alt="">
    </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  JTSi Business Enterprise Architecture
AMS Model
                </h2>
        </div>
        <p>
          Application Management Services (AMS) are provided by several companies as an outsourced solution for companies who need support for SAP enterprise applications. On-demand SAP support can range from full application outsourcing to managed cloud services. Among these are enhancements to applications (change requests (minor or major), upgrades, roll-outs) and optimization (advisory services, assessments, roadmaps).
          </p><p>
JTSi’s SAP AMS Services are delivered by highly qualified consultants who leverage their SAP expertise and business knowledge to deliver maximum value to your organization. Due to our extensive SAP knowledge and teams of SAP experts, we reduce time to value. JTSi as an AMS provider becomes an extension of your organization, giving you the ability to be more strategic with your SAP solution.
</p><p>
JTSi, as a good AMS partner, helps your team and organization deliver high-value SAP solutions by acting as a virtual extension of your IT team.
        </p>
        </div>
  </div>
</div>
</section>
<div class="containers">
  <div class="boxtitle_two mb6">
    <h2>
      Application Migration & Management
    </h2>
  </div>
</div>
  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  New SAP Solutions
                </h2>
        </div>

      <ul class="bulletpoints">
        <li>	SAP Methods and Tools</li>
<li>	Change Management</li>
<li>	Business Process Re-Engineering</li>
<li>	E-learning Management </li>
<li>	Testing Data Migration Deployment</li>
<li>	Integrated Testing </li>
<li>	Functional Configuration Audits </li>
<li>	Physical Configuration Audits</li>

      </ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/maintenance_02.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/maintenance_03.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Solution Upgrades
                </h2>
        </div>
        <ul class="bulletpoints">
  <li>	SAP Methods and Tools </li>
<li>	E-learning Management </li>
<li>	Testing </li>
<li>	Deployment </li>
<li>	Documentation / Knowledge Management </li>
</ul>
        </div>

  </div>
  </div>
  </section>


  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Change Request Management
                </h2>
        </div>
        <ul class="bulletpoints">
  <li>	ITL Standards </li>
<li>	CMMI Level 3 Processes</li>
<li>	Operate Configuration Control Board </li>
<li>	Application Configuration </li>
<li>	Documentation </li>
<li>	Knowledge Management </li>

</ul>

        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/maintenance_04.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/maintenance_05.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Delivery of Cloud Services
                </h2>
        </div>
        <ul class="bulletpoints">
          <li>	SAP FEDRAMP Management </li>
<li>	System Monitoring </li>
<li>	Issue Management </li>
<li>	Network Services</li>
<li>	Maintenance Optimizer </li>

        </ul>

        </div>

  </div>
  </div>
  </section>

  <section class="grayBg ">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Solution Monitoring
                </h2>
        </div>
        <ul class="bulletpoints">
  <li>	Safe ITAR Remote access </li>
<li>	Application Monitoring</li>
<li>	Business Processing Monitoring </li>
<li>	Batch Management </li>
<li>	System Administration </li>
<li>	Role Provisioning </li>
<li>	Service level Agreement reporting </li>
<li>	SAP Early Watch Alert </li>

</ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/maintenance_06.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
  <section class="commonPadding">
  <div class="containers">
  <div class="topsection padd0">
    <div class="picture">
      <img class="img-fluid" src="../../../assets/img/maintenance_07.jpg" alt="">
  </div>
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Help Desk
                </h2>
        </div>

        <ul class="bulletpoints">
          <li>	Best Practice Messaging </li>
<li>	Ticket Management </li>
<li>	Training & End User Support</li>
<li>	Knowledge Management</li>

          </ul>
        </div>

  </div>
  </div>
  </section>


  <section class="grayBg mb3">
    <div class="containers">
  <div class="topsection padd0 reverse">
    <div class="texts">
        <div class="heading redBg">
                <h2>
                  Root Cause Analysis
                </h2>
        </div>
        <ul class="bulletpoints">
          <li>	Performance Measurement </li>
          <li>	Logs and Dumps</li>
          <li>	Traces</li>
          <li>	Technical Configuration   </li>
</ul>
        </div>
        <div class="picture">
          <img class="img-fluid" src="../../../assets/img/maintenance_08.jpg" alt="">
      </div>
  </div>
  </div>
  </section>
